import { formatNumber } from '@app/utils/helpers';
import type { BatchDetailView } from '@sdk/api';
import { AntCard, AntGrid, AntSpace, AntTable, AntTypography } from '@ui/components';
import { fontSize, fontWeight } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import { getBatchValues, getChicksDay1, getChicksDay7 } from '../../utils';

type DataSourceType = {
  key: string;
  value?: string;
  percentage?: string;
};

export const CloseDetails = ({ batch }: { batch?: BatchDetailView }) => {
  const { t } = useTranslation();
  const { Text, Title } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const closedBatchInfo = {
    [t('batch.number_of_eggs_placed')]: batch?.numberPlaced,
    [t('batch.non_hatched_eggs')]: getBatchValues('numberNonHatched', batch),
    [t('batch.selection_day_1')]: getBatchValues('selectionDay1', batch),
    [t('batch.number_of_chicks_day_1')]: getChicksDay1(batch),
    [t('batch.selection_day_7')]: getBatchValues('selectionDay7', batch),
    [t('batch.mortality_day_7')]: getBatchValues('mortalityDay7', batch),
    [t('batch.number_of_chicks_day_7')]: getChicksDay7(batch),
    [t('batch.conclusion')]: batch?.conclusion,
  };

  const dataSource: DataSourceType[] = Object.entries(closedBatchInfo).map(([key, value]) => ({
    key,
    value: Array.isArray(value)
      ? formatNumber(value[0])
      : typeof value === 'number'
        ? formatNumber(value)
        : value,
    percentage: Array.isArray(value) ? `(${value[1].toFixed(2)}%)` : undefined,
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: xs ? 'unset' : '25%' },
    {
      render: (_, record) => (
        <AntSpace>
          <Text style={{ fontWeight: fontWeight.semibold }}>{record.value}</Text>
          <Text>{record.percentage}</Text>
        </AntSpace>
      ),
    },
  ];

  return (
    <AntCard bordered={false} style={{ width: '100%' }}>
      <Title level={2} style={{ fontSize: fontSize.xl }}>
        {t('batch.close_details')}
      </Title>

      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.key}
        pagination={false}
        showHeader={false}
      />
    </AntCard>
  );
};
