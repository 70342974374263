import { CheckOutlined } from '@ant-design/icons';
import { Tabs, type TabsProps } from '@app/components/Tabs';
import { BatchTabs } from '@app/constants/enums';
import { DateFormat, formatDate, saveBlob } from '@app/utils/helpers';
import { useDrawer, usePermissions } from '@app/utils/hooks';
import { type BatchDetailView, BatchViewStatusEnum } from '@sdk/api';
import {
  AntFlex,
  AntGrid,
  AntLayout,
  AntSpace,
  AntTypography,
  BackButton,
  Button,
  IconButton,
} from '@ui/components';
import { fontSize } from '@ui/constants';
import { MoreVert } from '@ui/icons';
import { Dropdown } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';
import { CloseBatchDrawer } from './Drawers/CloseBatchDrawer';

const HeaderItem = ({ value, label }: { value?: string; label: string }) => {
  const { Text } = AntTypography;
  return (
    <AntSpace direction="vertical" size={0} style={{ lineHeight: 1 }}>
      <Text type="secondary" style={{ fontSize: fontSize.sm }}>
        {label}
      </Text>
      <Text>{value}</Text>
    </AntSpace>
  );
};

type HeaderButtonsProps = {
  onClose: () => void;
  isClosed: boolean;
  canClose: boolean;
  downloadReport: () => void;
  isReportLoading: boolean;
  downloadDeliveryDoc: () => void;
  isDeliveryDocLoading: boolean;
};

const HeaderButtons = ({
  onClose,
  isClosed,
  canClose,
  downloadReport,
  isReportLoading,
  downloadDeliveryDoc,
  isDeliveryDocLoading,
}: HeaderButtonsProps) => {
  const { t } = useTranslation();
  return (
    <AntFlex align="center" gap={16}>
      {isClosed && (
        <Button onClick={downloadReport} loading={isReportLoading}>
          {t('actions.report')}
        </Button>
      )}
      <Button onClick={downloadDeliveryDoc} loading={isDeliveryDocLoading}>
        {t('actions.delivery_document')}
      </Button>
      {!isClosed && canClose && (
        <Button type="primary" icon={<CheckOutlined />} onClick={onClose}>
          {t('actions.close_batch')}
        </Button>
      )}
    </AntFlex>
  );
};

const HeaderButtonsMobile = ({
  onClose,
  isClosed,
  canClose,
  downloadReport,
  isReportLoading,
  downloadDeliveryDoc,
  isDeliveryDocLoading,
}: HeaderButtonsProps) => {
  const { t } = useTranslation();
  return (
    <AntFlex align="center" gap={16}>
      {!isClosed && canClose && (
        <Button type="primary" icon={<CheckOutlined />} onClick={onClose}>
          {t('actions.close_batch')}
        </Button>
      )}
      <Dropdown
        overlayClassName={styles.dropdown}
        trigger={['click']}
        dropdownRender={() => (
          <AntSpace direction="vertical" align="end">
            {isClosed && (
              <Button type="text" onClick={downloadReport} loading={isReportLoading}>
                {t('actions.report')}
              </Button>
            )}
            <Button type="text" onClick={downloadDeliveryDoc} loading={isDeliveryDocLoading}>
              {t('actions.delivery_document')}
            </Button>
          </AntSpace>
        )}
      >
        <IconButton icon={<MoreVert />} />
      </Dropdown>
    </AntFlex>
  );
};

export const BatchDetailedHeader = ({ batch }: { batch?: BatchDetailView }) => {
  const { t } = useTranslation();
  const { Header } = AntLayout;
  const { Title, Text } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();
  const { isOpen, toggleIsOpen } = useDrawer();
  const { isNestBornAdmin, getIsHatcheryAdmin, getIsHatcheryUser } = usePermissions();

  const [isReportLoading, setReportLoading] = useState(false);
  const [isDeliveryDocLoading, setDeliveryDocLoading] = useState(false);

  const hatcheryId = batch?.hatchery?.id;

  const canClose =
    isNestBornAdmin ||
    (!!hatcheryId && (getIsHatcheryAdmin(hatcheryId) || getIsHatcheryUser(hatcheryId)));

  const isClosed = batch?.status === BatchViewStatusEnum.Closed;

  const getPdfName = (name: string) => {
    const customerName = batch?.house?.customerLocation.customer?.name?.replace(/ /g, '');
    return `${name}_${customerName}${dayjs().format(DateFormat.DateNoSeparator)}.pdf`;
  };

  const downloadReport = async () => {
    if (batch) {
      setReportLoading(true);
      await saveBlob(`/batches/${batch.id}/report`, getPdfName('batch-report'));
      setReportLoading(false);
    }
  };

  const downloadDeliveryDoc = async () => {
    if (batch) {
      setDeliveryDocLoading(true);
      await saveBlob(`/batches/${batch.id}/delivery-document`, getPdfName('delivery-document'));
      setDeliveryDocLoading(false);
    }
  };

  const tabItems: TabsProps[] = [
    {
      key: BatchTabs.Status,
      label: t('status'),
    },
    {
      key: BatchTabs.Charts,
      label: t('charts'),
    },
    {
      key: BatchTabs.Activity,
      label: t('activity'),
    },
    {
      key: BatchTabs.GeneralInfo,
      label: t('general_info'),
    },
  ];

  return (
    <Header className={styles.headerWithTabs}>
      <AntFlex vertical flex={1}>
        <AntFlex
          align="center"
          justify="space-between"
          style={{ marginLeft: 16, marginBottom: 8, marginRight: xs ? -16 : 0 }}
        >
          <BackButton />
          {xs && (
            <HeaderButtonsMobile
              onClose={toggleIsOpen}
              isClosed={isClosed}
              canClose={canClose}
              downloadReport={downloadReport}
              isReportLoading={isReportLoading}
              downloadDeliveryDoc={downloadDeliveryDoc}
              isDeliveryDocLoading={isDeliveryDocLoading}
            />
          )}
        </AntFlex>

        <AntFlex align="center" justify="space-between">
          <Title level={1} className={styles.headerTitle}>
            {t('batch.batch_details')}
          </Title>

          {!xs && (
            <HeaderButtons
              onClose={toggleIsOpen}
              isClosed={isClosed}
              canClose={canClose}
              downloadReport={downloadReport}
              isReportLoading={isReportLoading}
              downloadDeliveryDoc={downloadDeliveryDoc}
              isDeliveryDocLoading={isDeliveryDocLoading}
            />
          )}
        </AntFlex>

        {batch && (
          <>
            <Text style={{ margin: '0 16px 8px' }}>
              {`${batch.house?.customerLocation.customer?.name}, ${batch.house?.customerLocation.name}, ${t('house')}: ${batch.house?.name}`}
            </Text>

            <AntSpace size="large" align="start" style={{ marginLeft: 16, lineHeight: 1 }}>
              <HeaderItem label={t('status')} value={t(batch.status)} />
              <HeaderItem
                label={t('batch.start_monitoring')}
                value={formatDate(batch.monitoringStart, DateFormat.DateTime)}
              />
              <HeaderItem
                label={t('batch.end_monitoring')}
                value={formatDate(batch.monitoringEnd, DateFormat.DateTime)}
              />
            </AntSpace>
          </>
        )}
        <Tabs items={tabItems} />
      </AntFlex>
      <CloseBatchDrawer batch={batch} open={isOpen} toggleIsOpen={toggleIsOpen} />
    </Header>
  );
};
