import { drawerWidth } from '@ui/constants';
import { Drawer as AntDrawer, type DrawerProps as AntDrawerProps, Grid } from 'antd';

export interface DrawerProps extends AntDrawerProps {
  toggleIsOpen: () => void;
}

export const Drawer = ({ ...props }: AntDrawerProps) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return <AntDrawer width={xs ? '100%' : `${drawerWidth}px`} {...props} />;
};
