import { useDrawer, usePagination, useTable } from '@app/utils/hooks';
import { useGetSensorSets } from '@data/hooks/sensor';
import type { CustomerHatcheryView, SensorApiGetSensorSetsRequest } from '@sdk/api';
import { AntFlex, AntGrid, AntSpace, AntTable, EditButton } from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddSetDrawer } from './AddSetDrawer';
import { EditSetDrawer } from './EditSetDrawer';
import { SetsListFilters } from './SetsListFilters';

export type SensorSetDataType = {
  id: string;
  name?: string;
  hatcheries: CustomerHatcheryView[];
};

export const SetsList = () => {
  const { t } = useTranslation();
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<SensorApiGetSensorSetsRequest>({
    limit: 10,
    offset: 0,
    search: undefined,
    sort: undefined,
    direction: undefined,
    hatcheryIds: undefined,
  });

  const { data } = useGetSensorSets(query);

  const { isOpen: isAddOpen, toggleIsOpen: toggleIsAddOpen } = useDrawer();
  const { isOpen: isEditOpen, toggleIsOpen: toggleIsEditOpen } = useDrawer();

  const { handleSort } = useTable(query, setQuery);
  const pagination = usePagination(query, setQuery, data?.count);

  const [set, setSensorSet] = useState<SensorSetDataType>();

  const dataSource = data?.items.map((item) => {
    const { id, name, hatcheries } = item;
    return {
      id,
      name,
      hatcheries,
    };
  });

  const columns: TableColumnsType<SensorSetDataType> = [
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('name'),
      }),
    },
    {
      title: t('hatchery'),
      render: (_, record) => record.hatcheries.map((hatchery) => hatchery.name).join(', '),
    },
    {
      render: (_, record) => (
        <EditButton
          onClick={() => {
            setSensorSet(record);
            toggleIsEditOpen();
          }}
        />
      ),
      align: 'right',
    },
  ];

  const columnsMobile: TableColumnsType<SensorSetDataType> = [
    {
      render: (_, record) => (
        <AntSpace direction="vertical" size={0} style={{ width: '100%' }}>
          <AntFlex align="center" justify="space-between">
            {record.name}
            <EditButton
              onClick={() => {
                setSensorSet(record);
                toggleIsEditOpen();
              }}
            />
          </AntFlex>
          {record.hatcheries.map((hatchery) => hatchery.name).join(', ')}
        </AntSpace>
      ),
    },
  ];

  return (
    <>
      <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
        <SetsListFilters query={query} setQuery={setQuery} toggleIsOpen={toggleIsAddOpen} />
        <AntTable
          showHeader={!xs}
          columns={xs ? columnsMobile : columns}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          pagination={pagination}
          style={{ marginBottom: xs ? 100 : 0 }}
        />
      </AntSpace>
      {set && (
        <EditSetDrawer open={isEditOpen} toggleIsOpen={toggleIsEditOpen} set={set} query={query} />
      )}
      <AddSetDrawer open={isAddOpen} toggleIsOpen={toggleIsAddOpen} query={query} />
    </>
  );
};
