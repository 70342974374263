import { LoadingOutlined } from '@ant-design/icons';
import { useIsFetching } from '@tanstack/react-query';
import { Spin } from 'antd';

export const Spinner = () => {
  const isFetching = useIsFetching();

  return (
    <Spin
      style={{
        display: isFetching ? 'flex' : 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
        height: '100%',
        width: '100%',
        background: 'rgba(255, 255, 255, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
    />
  );
};
