import { CustomerTabs } from '@app/constants/enums';
import { Employees, Subscriptions } from '@app/pages/shared';
import { usePermissions } from '@app/utils/hooks';
import { useGetCustomerById, useGetCustomerSubscriptions } from '@data/hooks';
import { AntLayout } from '@ui/components';
import { useParams, useSearchParams } from 'react-router-dom';

import styles from '../styles.module.css';
import { CustomerDetailedHeader } from './CustomerDetailedHeader';
import { CustomerInfo, CustomerLocations } from './Tabs';

export const CustomerDetailed = () => {
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');
  const { isNestBornAdmin, getIsCustomerHatcheryAdmin, getIsCustomerAdmin } = usePermissions();

  const { Content } = AntLayout;

  const { customerId = '' } = useParams();
  const { data: customer } = useGetCustomerById({ id: customerId });
  const { data: subscriptions } = useGetCustomerSubscriptions({ id: customerId });

  const isAdmin = isNestBornAdmin || (!!customer && getIsCustomerHatcheryAdmin(customer));
  const isCustomerAdmin = !!customer && getIsCustomerAdmin(customer.id);

  const renderContent = () => {
    if (!selectedTab) return;
    return {
      [CustomerTabs.Locations]: <CustomerLocations customer={customer} isAdmin={isAdmin} />,
      [CustomerTabs.Employees]: <Employees customer={customer} />,
      [CustomerTabs.Notifications]: (
        <Subscriptions subscriptions={subscriptions} isAdmin={isAdmin || isCustomerAdmin} />
      ),
      [CustomerTabs.GeneralInfo]: <CustomerInfo customer={customer} isAdmin={isAdmin} />,
    }[selectedTab];
  };
  return (
    <AntLayout>
      <CustomerDetailedHeader customer={customer} canDelete={isAdmin} />
      <Content className={styles.content}>{renderContent()}</Content>
    </AntLayout>
  );
};
