import { Language } from '@sdk/api';
import type { i18n as Ii18n } from 'i18next';

export const changeLanguage = (value: Language, i18n: Ii18n) => {
  void i18n.changeLanguage(value);
  document.documentElement.lang = value;
};

export const getLanguageOptions = () => {
  const languages = {
    [Language.En]: 'English',
    [Language.Nl]: 'Nederlands',
    [Language.Fr]: 'Français',
  };

  return Object.entries(languages).map(([value, label]) => ({ value, label }));
};
