export enum HatcheryTabs {
  Employees = 'employees',
  Notifications = 'notifications',
  GeneralInfo = 'general-info',
}

export enum CustomerTabs {
  Locations = 'locations',
  Employees = 'employees',
  Notifications = 'notifications',
  GeneralInfo = 'general-info',
}

export enum BatchTabs {
  Status = 'status',
  Charts = 'charts',
  Activity = 'activity',
  GeneralInfo = 'general-info',
}

export enum OvoscanTabs {
  Sensors = 'sensors',
  Sets = 'sets',
  Boxes = 'boxes',
}
