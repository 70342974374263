import '../i18n';
import 'react-toastify/dist/ReactToastify.css';

import { sentryInit } from '@app/utils';
import { useUserStore } from '@data/storage';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AntConfigProvider } from '@ui/components';
import { colors, fontFamily, theme } from '@ui/constants';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { queryClient } from '../config';
import { router } from './router';

if (import.meta.env.MODE && ['production', 'staging'].includes(import.meta.env.MODE)) sentryInit();

const App = () => {
  const { user } = useUserStore();

  return (
    <QueryClientProvider client={queryClient}>
      <AntConfigProvider
        theme={{
          components: {
            Button: theme.Button,
            Checkbox: theme.Checkbox,
            DatePicker: theme.DatePicker,
            Input: theme.Input,
            InputNumber: theme.Input,
            Select: theme.Select,
            Card: theme.Card,
            Tag: theme.Tag,
            Switch: theme.Switch,
            Radio: theme.Radio,
            Pagination: theme.Pagination,
          },
          token: {
            fontFamily: fontFamily,
            colorPrimary: colors.teal700,
            colorBgMask: 'rgba(0, 0, 0, 0.88)',
          },
        }}
      >
        <RouterProvider router={router(!!user)} />
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastContainer />
      </AntConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
