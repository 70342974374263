export const routes = {
  root: '/',
  app: 'app',
  signIn: 'sign-in',
  signUp: 'sign-up',
  resetPassword: 'reset-password',
  forgotPassword: 'forgot-password',
  forgotPasswordConfirmation: 'forgot-password-confirmation',
  expiredInvitation: 'expired-invitation',
  dashboard: 'dashboard',
  ovoscans: 'ovoscans',
  batches: {
    root: 'batches',
    status: 'status',
    charts: 'charts',
    activity: 'activity',
    generalInfo: 'general-info',
  },
  batchReport: 'batch-report',
  deliveryDocument: 'delivery-document',
  customers: 'customers',
  hatcheries: 'hatcheries',
  settings: 'settings',
};
