import { Form, type FormItemProps as AntFormItemProps } from 'antd';

interface FormItemProps extends AntFormItemProps {
  error?: string;
}

export const FormItem = ({ children, error, help, ...props }: FormItemProps) => (
  <Form.Item validateStatus={error && 'error'} help={error || help} {...props}>
    {children}
  </Form.Item>
);
