import { default as FloorPlan1 } from './assets/floor-plan-1.svg';
import { default as FloorPlan2 } from './assets/floor-plan-2.svg';
import { default as FloorPlan3 } from './assets/floor-plan-3.svg';
import { default as FloorPlan4 } from './assets/floor-plan-4.svg';
import { default as FloorPlan5 } from './assets/floor-plan-5.svg';
import { default as FloorPlan6 } from './assets/floor-plan-6.svg';

const plans = [
  { id: 6, src: FloorPlan6 },
  { id: 1, src: FloorPlan1 },
  { id: 2, src: FloorPlan2 },
  { id: 3, src: FloorPlan3 },
  { id: 4, src: FloorPlan4 },
  { id: 5, src: FloorPlan5 },
];

export const getFloorPlans = () => plans;

export const getFloorPlanSrc = (id: number) => plans.find((plan) => plan.id === id)?.src;
