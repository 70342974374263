import { PlusOutlined } from '@ant-design/icons';
import { countries } from '@app/constants';
import { useMeasurementConversion } from '@app/utils/hooks';
import { customerKeys } from '@data/constants';
import { useCreateCustomerLocation } from '@data/hooks';
import { AddLocationSchema, type AddLocationValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import type { CustomerDetailView } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntCard,
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  DeleteButton,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { colors } from '@ui/constants';
import TextArea from 'antd/es/input/TextArea';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';

interface AddLocationDrawerProps extends DrawerProps {
  customer?: CustomerDetailView;
}

export const AddLocationDrawer = ({ toggleIsOpen, customer, ...props }: AddLocationDrawerProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { Text } = AntTypography;

  const emptyHouse = { name: '', size: '', description: '' };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset,
  } = useForm<AddLocationValidator>({
    mode: 'onBlur',
    resolver: zodResolver(AddLocationSchema),
    defaultValues: { houses: [emptyHouse] },
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'houses' });
  const handleAddHouse = () => append(emptyHouse);

  const { mutateAsync: createCustomerLocation } = useCreateCustomerLocation();

  const { areaMeasurementUnit, toSquareMeters } = useMeasurementConversion();

  const onSubmit = async (values: AddLocationValidator) => {
    if (!customer) return;
    const { name, country, city, address, postalCode, houses } = values;
    await createCustomerLocation({
      id: customer.id,
      customerLocationBody: {
        name,
        location: { country, city, address, postalCode },
        houses: houses.map((h) => ({ ...h, size: toSquareMeters(h.size) })),
      },
    });
    await queryClient.invalidateQueries({ queryKey: customerKeys.detail(customer.id) });
    reset();
    toggleIsOpen();
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('new_location')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.add')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntSpace direction="vertical" style={{ width: '100%' }} size="large">
        <AntForm name="createHatchery" layout="vertical">
          <Text className={styles.drawerLabel}>{t('location_info')}</Text>

          <FormItem required name="name" label={t('location_name')} error={errors.name?.message}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <Input {...field} />}
            />
          </FormItem>

          <FormItem required name="country" label={t('country')} error={errors.country?.message}>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <Select
                  showSearch
                  options={countries.map((c) => ({ value: c.name, label: c.name }))}
                  {...field}
                />
              )}
            />
          </FormItem>

          <FormItem required name="city" label={t('city')} error={errors.city?.message}>
            <Controller
              control={control}
              name="city"
              render={({ field }) => <Input {...field} />}
            />
          </FormItem>

          <FormItem
            required
            name="postalCode"
            label={t('postal_code')}
            error={errors.postalCode?.message}
          >
            <Controller
              control={control}
              name="postalCode"
              render={({ field }) => <Input {...field} />}
            />
          </FormItem>

          <FormItem required name="address" label={t('address')} error={errors.address?.message}>
            <Controller
              control={control}
              name="address"
              render={({ field }) => <Input {...field} />}
            />
          </FormItem>

          <AntSpace direction="vertical" style={{ width: '100%' }} size="large">
            {fields.map((field, index) => (
              <AntCard key={field.id} style={{ border: 'none', background: colors.teal200 }}>
                <AntFlex justify="space-between">
                  <Text className={styles.drawerLabel}>{`House ${Number(index) + 1}`}</Text>
                  {Number(index) > 0 && <DeleteButton onClick={() => remove(index)} />}
                </AntFlex>

                <FormItem
                  required
                  name={`houses.${index}.name`}
                  label={t('house_name')}
                  error={errors?.houses?.[index]?.name?.message}
                >
                  <Controller
                    control={control}
                    {...register(`houses.${index}.name`)}
                    render={({ field }) => <Input {...field} />}
                  />
                </FormItem>

                <FormItem
                  name={`houses.${index}.size`}
                  label={t('net_area_size', { unit: areaMeasurementUnit })}
                  error={errors?.houses?.[index]?.size?.message}
                >
                  <Controller
                    control={control}
                    {...register(`houses.${index}.size`)}
                    render={({ field }) => <Input {...field} />}
                  />
                </FormItem>

                <FormItem
                  name={`houses.${index}.description`}
                  label={t('description')}
                  error={errors?.houses?.[index]?.description?.message}
                >
                  <Controller
                    control={control}
                    {...register(`houses.${index}.description`)}
                    render={({ field }) => <TextArea showCount maxLength={500} {...field} />}
                  />
                </FormItem>
              </AntCard>
            ))}
          </AntSpace>
        </AntForm>

        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddHouse}>
          {t('new_house')}
        </Button>
      </AntSpace>
    </Drawer>
  );
};
