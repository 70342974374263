import { EmptyContent } from '@ui/icons';
import { Button, Empty as AntEmpty, type EmptyProps, Typography } from 'antd';

interface ComponentProps extends EmptyProps {
  description: string;
  buttonLabel: string;
  onClick: () => void;
}

export const Empty = ({ description, buttonLabel, onClick, ...props }: ComponentProps) => {
  const { Text } = Typography;

  return (
    <AntEmpty
      image={<EmptyContent />}
      description={<Text type="secondary">{description}</Text>}
      {...props}
    >
      <Button style={{ background: 'transparent' }} onClick={onClick}>
        {buttonLabel}
      </Button>
    </AntEmpty>
  );
};
