import { filterOption } from '@app/utils/helpers';
import { useGetCustomerById, useGetCustomers, useGetHatcheries } from '@data/hooks';
import { useGetBreeds } from '@data/hooks/batch';
import { type AddBatchValidator } from '@data/utils/validation';
import { AntSpace, DatePicker, FormItem, Select, Spinner, TimePicker } from '@ui/components';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const AddBatchStep1 = () => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<AddBatchValidator>();

  const customerId = watch('customerId');
  const hatcheryId = watch('hatcheryId');
  const customerLocationId = watch('customerLocationId');
  const houseId = watch('houseId');

  const query = {
    limit: 1000,
    offset: 0,
  };

  const { data: hatcheries } = useGetHatcheries(query);
  const { data: customers } = useGetCustomers(
    { ...query, hatcheryIds: [hatcheryId] },
    { enabled: !!hatcheryId },
  );
  const { data: customer } = useGetCustomerById({ id: customerId });
  const { data: breeds } = useGetBreeds(query);

  useEffect(() => {
    const lastFloorPlan = customer?.customerLocations
      ?.find((location) => location.id === customerLocationId)
      ?.houses.find((house) => house.id === houseId)?.lastFloorPlan;
    if (lastFloorPlan) setValue('floorPlan', lastFloorPlan);
  }, [setValue, customer, houseId, customerLocationId]);

  const renderDayPicker = (
    name: keyof AddBatchValidator,
    Component: typeof DatePicker | typeof TimePicker,
  ) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Component
          showNow={false}
          disabledDate={(current) => {
            const startDate = getValues('startDate');
            return (
              field.name === 'endDate' && !!startDate && current.diff(dayjs(startDate), 'day') < 1
            );
          }}
          minuteStep={5}
          style={{ width: '100%' }}
          value={field.value ? dayjs(field.value) : null}
          onChange={(date) => field.onChange(date?.toISOString())}
        />
      )}
    />
  );

  return (
    <>
      <Spinner />
      <FormItem required name="hatcheryId" label={t('hatchery')} error={errors.hatcheryId?.message}>
        <Controller
          control={control}
          name="hatcheryId"
          render={({ field }) => (
            <Select
              showSearch
              filterOption={filterOption}
              options={hatcheries?.items.map((h) => ({ value: h.id, label: h.name }))}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem required name="customerId" label={t('customer')} error={errors.customerId?.message}>
        <Controller
          control={control}
          name="customerId"
          render={({ field }) => (
            <Select
              showSearch
              filterOption={filterOption}
              options={customers?.items.map((h) => ({ value: h.id, label: h.name }))}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem
        required
        name="customerLocationId"
        label={t('location')}
        error={errors.customerLocationId?.message}
      >
        <Controller
          control={control}
          name="customerLocationId"
          render={({ field }) => (
            <Select
              showSearch
              filterOption={filterOption}
              options={customer?.customerLocations.map((location) => ({
                value: location.id,
                label: location.name,
              }))}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem required name="houseId" label={t('house')} error={errors.houseId?.message}>
        <Controller
          control={control}
          name="houseId"
          render={({ field }) => (
            <Select
              showSearch
              filterOption={filterOption}
              options={customer?.customerLocations
                .find((location) => location.id === customerLocationId)
                ?.houses.map((house) => ({ value: house.id, label: house.name }))}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem required name="breedId" label={t('breed')} error={errors.breedId?.message}>
        <Controller
          control={control}
          name="breedId"
          render={({ field }) => (
            <Select
              options={breeds?.items.map((breed) => ({ value: breed.id, label: breed.name }))}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem
        required
        name="startDate"
        label={t('batch.start_monitoring')}
        error={errors.startDate?.message || errors.startTime?.message}
      >
        <AntSpace style={{ width: '100%' }} styles={{ item: { flex: 1 } }}>
          {renderDayPicker('startDate', DatePicker)}
          {renderDayPicker('startTime', TimePicker)}
        </AntSpace>
      </FormItem>

      <FormItem
        required
        name="endDate"
        label={t('batch.end_monitoring')}
        error={errors.endDate?.message || errors.endTime?.message}
      >
        <AntSpace style={{ width: '100%' }} styles={{ item: { flex: 1 } }}>
          {renderDayPicker('endDate', DatePicker)}
          {renderDayPicker('endTime', TimePicker)}
        </AntSpace>
      </FormItem>
    </>
  );
};
