import { formatDateRange } from '@app/utils/helpers';
import type { BatchApiGetBatchesRequest } from '@sdk/api';
import { Tag } from 'antd';

export const DateFilters = ({
  query,
  setQuery,
}: {
  query: BatchApiGetBatchesRequest;
  setQuery: (q: BatchApiGetBatchesRequest) => void;
}) =>
  query.start ? (
    <Tag
      bordered={false}
      closable
      onClose={() =>
        setQuery({
          ...query,
          start: undefined,
        })
      }
    >
      {formatDateRange(query.start, query.end)}
    </Tag>
  ) : null;
