import { HatcheryTabs } from '@app/constants/enums';
import { Employees, Subscriptions } from '@app/pages/shared';
import { useGetHatcheryById, useGetHatcherySubscriptions } from '@data/hooks';
import { AntLayout } from '@ui/components';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import styles from '../styles.module.css';
import { HatcheryDetailedHeader } from './HatcheryDetailedHeader';
import { HatcheryInfo } from './Tabs';

export const HatcheryDetailed: React.FC = () => {
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');
  const { Content } = AntLayout;

  const { hatcheryId = '' } = useParams();
  const { data: hatchery } = useGetHatcheryById({ id: hatcheryId });
  const { data: subscriptions } = useGetHatcherySubscriptions({ id: hatcheryId || '' });

  const renderContent = () => {
    if (!selectedTab) return;
    return {
      [HatcheryTabs.Employees]: <Employees />,
      [HatcheryTabs.Notifications]: <Subscriptions subscriptions={subscriptions} />,
      [HatcheryTabs.GeneralInfo]: <HatcheryInfo hatchery={hatchery} />,
    }[selectedTab];
  };

  return (
    <AntLayout>
      <HatcheryDetailedHeader hatchery={hatchery} />
      <Content className={styles.content}>{renderContent()}</Content>
    </AntLayout>
  );
};
