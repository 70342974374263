import { type BatchStatsView, type PointView, StatViewTypeEnum } from '@sdk/api';
import { AntCard, AntSpace } from '@ui/components';

import { sortDataPoints } from '../../utils';
import { EggTemperatureChartSingle } from './EggTemperatureChartSingle';
import { EmptyChartCover } from './EmptyChartCover';

type ChartProps = {
  stats?: BatchStatsView;
  timeRange: [string, string];
};

export const EggTemperatureCharts = ({ stats, timeRange }: ChartProps) => {
  const getSensorAverage = (serialNumber?: string) =>
    serialNumber
      ? stats?.ovo.find(
          (sensor) =>
            sensor.type === StatViewTypeEnum.EggShellTemperature &&
            !sensor.groupedByEgg &&
            sensor.serialNumber === serialNumber,
        )?.points
      : [];

  const groupByEgg = (points: PointView[]) => {
    const eggs: { [key: number]: PointView[] } = {};
    points.forEach((point) => {
      if (!point || !point.value || point.position === undefined) return;
      eggs[point.position] ? eggs[point.position].push(point) : (eggs[point.position] = [point]);
    });
    return eggs;
  };

  const getTempRange = (averagePoints?: PointView[], sensorPoints?: PointView[]) => {
    const averages = averagePoints?.reduce(
      (result, point) => {
        result[point.time] = { min: point.value, max: point.value };
        return result;
      },
      {} as { [key: string]: { min: number; max: number } },
    );

    if (!averages) return;

    sensorPoints?.forEach((point) => {
      if (!point.value) return;
      if (point.value < averages[point.time]?.min) averages[point.time].min = point.value;
      if (point.value > averages[point.time]?.max) averages[point.time].max = point.value;
    });

    return Object.entries(averages).map(([time, { min, max }]) => ({ time, value: { min, max } }));
  };

  const sensors: {
    position: number;
    eggs: { [key: number]: PointView[] };
    average?: PointView[];
    tempRange?: { time: string; value: { min: number; max: number } }[];
  }[] = [];

  stats?.ovo.forEach((sensor) => {
    if (
      sensor.type !== StatViewTypeEnum.EggShellTemperature ||
      !sensor.groupedByEgg ||
      sensor.position === undefined
    )
      return;

    const average = sortDataPoints(getSensorAverage(sensor.serialNumber));
    const tempRange = getTempRange(average, sensor.points);

    sensors.push({
      position: sensor.position,
      eggs: groupByEgg(sensor.points),
      average,
      tempRange,
    });
  });

  return sensors.length ? (
    <AntSpace direction="vertical" size="large">
      {Object.values(sensors)
        .sort((a, b) => a.position - b.position)
        .map((sensor) => {
          const isEmpty = Object.keys(sensor.eggs).length === 0;
          return (
            <AntCard bordered={false} styles={{ body: { padding: 0 } }} key={sensor.position}>
              <EggTemperatureChartSingle
                position={sensor.position}
                eggs={sensor.eggs}
                average={sensor.average}
                tempRange={sensor.tempRange}
                timeRange={timeRange}
              />
              {isEmpty && <EmptyChartCover />}
            </AntCard>
          );
        })}
    </AntSpace>
  ) : null;
};
