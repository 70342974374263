import type { CustomerApiGetCustomersRequest, HatcheryApiGetHatcheriesRequest } from '@sdk/api';
import { Tag } from 'antd';

export const CountryFilters = ({
  query,
  setQuery,
}: {
  query: CustomerApiGetCustomersRequest | HatcheryApiGetHatcheriesRequest;
  setQuery: (q: CustomerApiGetCustomersRequest | HatcheryApiGetHatcheriesRequest) => void;
}) =>
  query.country ? (
    <Tag
      bordered={false}
      closable
      onClose={() =>
        setQuery({
          ...query,
          country: undefined,
        })
      }
    >
      {query.country}
    </Tag>
  ) : null;
