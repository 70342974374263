import { Timeline } from '@app/components/Timeline';
import { routes } from '@app/constants';
import { usePermissions } from '@app/utils/hooks';
import { useGetKpiUser, useGetLatestBatchEvents } from '@data/hooks';
import { BatchViewStatusEnum } from '@sdk/api';
import {
  AntCard,
  AntCol,
  AntFlex,
  AntLayout,
  AntRow,
  AntSpace,
  AntTypography,
} from '@ui/components';
import { colors, fontSize } from '@ui/constants';
import {
  ArrowForward,
  Box,
  EggsFilled,
  FolderSharedFilled,
  LocationAwayFilled,
  NotificationImportantFilled,
} from '@ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from '../styles.module.css';
import { DashboardHeader } from './DashboardHeader';
import dashboardStyles from './styles.module.css';

export const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { isNestBornAdmin, isAnyHatcheryAdmin, isAnyHatcheryUser } = usePermissions();

  const { Content } = AntLayout;
  const { Text } = AntTypography;

  const { data: kpi } = useGetKpiUser();
  const { data: events } = useGetLatestBatchEvents();

  enum DashboardIndicator {
    TotalHatcheries = 'TotalHatcheries',
    TotalCustomers = 'TotalCustomers',
    ActiveCustomers = 'ActiveCustomers',
    TotalBatches = 'TotalBatches',
    ActiveBatches = 'ActiveBatches',
    RecentAlarms = 'RecentAlarms',
  }

  const indicators = {
    [DashboardIndicator.TotalHatcheries]: {
      icon: <LocationAwayFilled />,
      title: t('total_hatcheries'),
      value: kpi?.totalHatcheries,
      onClick: () => {
        navigate(`/${routes.app}/${routes.hatcheries}`);
      },
    },
    [DashboardIndicator.TotalCustomers]: {
      icon: <FolderSharedFilled />,
      title: t('total_customers'),
      value: kpi?.totalCustomers,
      onClick: () => {
        navigate(`/${routes.app}/${routes.customers}`);
      },
    },
    [DashboardIndicator.ActiveCustomers]: {
      icon: <LocationAwayFilled />,
      title: t('active_customers'),
      value: kpi?.activeCustomers,
      onClick: () => {
        navigate(`/${routes.app}/${routes.customers}`);
      },
    },
    [DashboardIndicator.TotalBatches]: {
      icon: <Box />,
      title: t('total_batches'),
      value: kpi?.totalBatches,
      onClick: () => {
        navigate(`/${routes.app}/${routes.batches.root}`);
        setSearchParams({ tab: 'all' });
      },
    },
    [DashboardIndicator.ActiveBatches]: {
      icon: <EggsFilled />,
      title: t('active_batches'),
      value: kpi?.activeBatches,
      onClick: () => {
        navigate(`/${routes.app}/${routes.batches.root}`);
        setSearchParams({ tab: BatchViewStatusEnum.Active });
      },
    },
    [DashboardIndicator.RecentAlarms]: {
      icon: <NotificationImportantFilled />,
      title: t('recent_alarms'),
      value: kpi?.recentAlarms,
      onClick: () => {
        navigate(`/${routes.app}`);
      },
    },
  };

  const Indicator = ({ type }: { type: DashboardIndicator }) => (
    <AntCard
      bordered={false}
      role="button"
      onClick={indicators[type].onClick}
      styles={{ body: { padding: 16, cursor: 'pointer', height: 170 } }}
      className={dashboardStyles.indicator}
    >
      <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
        <AntFlex justify="space-between" align="center">
          <div className={dashboardStyles.iconContainer}>{indicators[type].icon}</div>
          <ArrowForward height={16} width={16} />
        </AntFlex>
        <AntSpace direction="vertical" size="small">
          <Text type="secondary">{indicators[type].title}</Text>
          <Text strong style={{ fontSize: 38, lineHeight: 1 }}>
            {indicators[type].value ?? 0}
          </Text>
        </AntSpace>
      </AntSpace>
    </AntCard>
  );

  const nestbornAdminKPIs = [
    DashboardIndicator.TotalCustomers,
    DashboardIndicator.TotalHatcheries,
    DashboardIndicator.TotalBatches,
    DashboardIndicator.ActiveBatches,
  ];

  const hatcheryAdminKPIs = [
    DashboardIndicator.TotalCustomers,
    DashboardIndicator.ActiveCustomers,
    DashboardIndicator.ActiveBatches,
    DashboardIndicator.RecentAlarms,
  ];

  const customerAdminKPIs = [DashboardIndicator.TotalBatches, DashboardIndicator.ActiveBatches];

  const getKPIs = () => {
    if (isNestBornAdmin) return nestbornAdminKPIs;
    if (isAnyHatcheryAdmin || isAnyHatcheryUser) return hatcheryAdminKPIs;
    return customerAdminKPIs;
  };

  return (
    <AntLayout>
      <DashboardHeader />
      <Content className={styles.content}>
        <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
          <AntRow gutter={[16, 16]}>
            {getKPIs().map((kpi, _, arr) => (
              <AntCol
                key={kpi}
                xs={{ flex: '50%' }}
                sm={{ flex: '50%' }}
                md={{ flex: '50%' }}
                lg={{ flex: arr.length > 2 ? '25%' : '50%' }}
                xl={{ flex: arr.length > 2 ? '25%' : '50%' }}
              >
                <Indicator type={kpi} />
              </AntCol>
            ))}
          </AntRow>

          {!isNestBornAdmin && (
            <AntCard bordered={false}>
              <AntFlex align="center" justify="space-between">
                <Text strong style={{ fontSize: fontSize.xl }}>
                  {t('activity_feed')}
                </Text>
                <Text style={{ fontSize: fontSize.sm, color: colors.grey500 }}>
                  {t('last_24_hours')}
                </Text>
              </AntFlex>
              {!!events?.count && <Timeline events={events} />}
            </AntCard>
          )}
        </AntSpace>
      </Content>
    </AntLayout>
  );
};
