import { getFormattedTemperature } from '@app/utils/helpers';
import { useUserStore } from '@data/storage';
import {
  type BatchSensorView,
  BatchSensorViewAirTemperatureStatusEnum as SensorStatus,
  type BatchView,
  type EggView,
  EggViewStatusEnum as EggStatus,
} from '@sdk/api';
import { AntCard, AntSpace, AntTypography } from '@ui/components';
import { colors, fontSize } from '@ui/constants';
import { AqIndoor, EggHatched, Offline } from '@ui/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const getSensorStyles = (status: SensorStatus) =>
  ({
    [SensorStatus.Normal]: {
      backgroundColor: colors.yellow100,
      borderColor: colors.yellow400,
    },
    [SensorStatus.TooHot]: {
      backgroundColor: colors.red200,
      borderColor: colors.red400,
    },
    [SensorStatus.WayTooHot]: {
      backgroundColor: colors.red200,
      borderColor: colors.red400,
    },
    [SensorStatus.TooCold]: {
      backgroundColor: colors.blue200,
      borderColor: colors.blue400,
    },
    [SensorStatus.WayTooCold]: {
      backgroundColor: colors.purple200,
      borderColor: colors.purple400,
    },
    [SensorStatus.Unknown]: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: 'transparent',
    },
  })[status];

const getEggStyles = (status: EggStatus) =>
  ({
    [EggStatus.Normal]: {
      backgroundColor: colors.yellow200,
      borderColor: colors.yellow600,
    },
    [EggStatus.TooHot]: {
      backgroundColor: colors.red500,
      borderColor: colors.red600,
      color: colors.white,
    },
    [EggStatus.TooCold]: {
      backgroundColor: colors.blue500,
      borderColor: colors.blue600,
      color: colors.white,
    },
    [EggStatus.Hatched]: {
      backgroundColor: colors.green200,
      borderColor: colors.green600,
      color: colors.green600,
    },
    [EggStatus.Unknown]: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: 'transparent',
    },
  })[status];

const OvoscanEgg = (data: EggView) => {
  const { Text } = AntTypography;
  const { status, temperature } = data;
  const { user } = useUserStore();
  return (
    <AntCard
      className={styles.ovoscanEgg}
      style={getEggStyles(status)}
      styles={{ body: { padding: 0 } }}
    >
      {status === EggStatus.Hatched ? (
        <EggHatched color={colors.green700} />
      ) : (
        <Text strong style={{ fontSize: fontSize.lg, color: 'inherit' }}>
          {getFormattedTemperature(temperature, user?.eggTempScale)}
        </Text>
      )}
    </AntCard>
  );
};

type AirTempCellProps = {
  status: SensorStatus;
  value?: number;
};

const AirTempCell = ({ value, status }: AirTempCellProps) => {
  const { Text } = AntTypography;
  const { user } = useUserStore();

  return (
    <AntCard
      className={styles.airTempSensor}
      style={getSensorStyles(status)}
      styles={{ body: { padding: 0 } }}
    >
      <AntSpace direction="vertical" align="center" size={0} style={{ lineHeight: 1 }}>
        <AqIndoor height={18} width={18} />
        <Text strong style={{ fontSize: fontSize.lg }}>
          {getFormattedTemperature(value, user?.airTempScale)}
        </Text>
      </AntSpace>
    </AntCard>
  );
};

type OvoscanProps = {
  batch: BatchView;
  sensor: BatchSensorView;
};

export const Ovoscan = ({ batch, sensor }: OvoscanProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const { position, airTemperature, airTemperatureStatus, eggs, online } = sensor;
  const sortedEggs = eggs.sort((a, b) => a.position - b.position);
  const isNoData = !online && dayjs().isBefore(dayjs(batch.monitoringEnd));

  return (
    position && (
      <div className={styles.ovoscanContainer}>
        <div
          className={styles.ovoscanContainer}
          style={{ backgroundColor: colors.teal200, opacity: online ? 1 : 0.5 }}
        >
          <AntSpace direction="vertical" size={24}>
            <AntSpace size={24}>
              <OvoscanEgg {...sortedEggs[0]} />
              <OvoscanEgg {...sortedEggs[1]} />
            </AntSpace>
            <AntSpace size={24}>
              <OvoscanEgg {...sortedEggs[2]} />
              <OvoscanEgg {...sortedEggs[3]} />
            </AntSpace>
          </AntSpace>

          <AirTempCell value={airTemperature} status={airTemperatureStatus} />
        </div>

        <Text strong style={{ fontSize: fontSize.xs }} className={styles.ovoscanTitle}>
          {t('ovoscan_position', { position })}
        </Text>

        {isNoData && (
          <div className={styles.offline}>
            <Offline />
            <span style={{ marginTop: 12 }}>{t('batch.no_data_2_hours')}</span>
          </div>
        )}
      </div>
    )
  );
};

export const EmptyOvoscan = ({ position }: { position: number }) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  return (
    position && (
      <div className={styles.ovoscanPlaceholder}>
        <Text
          strong
          style={{ fontSize: fontSize.xs, color: colors.grey300 }}
          className={styles.ovoscanTitle}
        >
          {t('ovoscan_position', { position })}
        </Text>
      </div>
    )
  );
};
