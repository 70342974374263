export const fontFamily = 'Inter, sans-serif';

export const fontSize = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 20,
  xxl: 24,
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semibold: 600,
};
