import { DateFormat, formatDate } from '@app/utils/helpers';
import { getIcon, getStyles } from '@app/utils/helpers/timelineHelpers';
import { type AlarmPayloadView, type BatchEventView, BatchEventViewTypeEnum } from '@sdk/api';
import { AntTable, AntTypography } from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

export const Notifications = ({ notifications }: { notifications?: BatchEventView[] }) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const getEventType = (type: BatchEventViewTypeEnum, alarmPayload?: AlarmPayloadView) => {
    if (type === BatchEventViewTypeEnum.Closure) return t('toast.batch_closed');
    return alarmPayload ? t(`alarms.${alarmPayload.type}`) : '';
  };

  const columns: TableColumnsType<BatchEventView> = [
    {
      title: t('date'),
      render: (_, record) => formatDate(record.createdAt, DateFormat.DateTime),
      width: 160,
    },
    {
      title: '',
      render: (_, record) => getIcon(record),
      width: 24,
    },
    {
      title: t('type'),
      render: (_, record) => {
        const { type, alarmPayload } = record;
        const color = getStyles(type, alarmPayload)?.color;
        return <Text style={{ color }}>{getEventType(type, alarmPayload)}</Text>;
      },
      width: 200,
    },
    {
      title: t('entity_name'),
      render: (_, record) =>
        record.alarmPayload?.entities
          ?.map((entity) => `${t(entity.type)} ${entity.name}`)
          .join(', '),
    },
  ];

  return (
    <AntTable
      size="small"
      columns={columns}
      dataSource={notifications}
      rowKey={(record) => record.id}
      pagination={false}
    />
  );
};
