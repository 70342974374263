import { sensorKeys } from '@data/constants';
import { useGetSensorSets, useUpdateSensor } from '@data/hooks/sensor';
import { UpdateSensorSchema, type UpdateSensorValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SensorApiGetSensorsRequest, SensorViewTypeEnum } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { SensorDataType } from './SensorsList';

interface EditSensorDrawerProps extends DrawerProps {
  sensor: SensorDataType;
  query: SensorApiGetSensorsRequest;
}

export const EditSensorDrawer = ({
  toggleIsOpen,
  sensor,
  query,
  ...props
}: EditSensorDrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<UpdateSensorValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateSensorSchema),
    defaultValues: {
      serialNumber: sensor?.serialNumber,
      sensorSetId: sensor?.sensorSet?.id,
      position: sensor?.position ?? undefined,
    },
  });

  const sensorSetId = watch('sensorSetId');

  const { data: sensorSets } = useGetSensorSets({ limit: 5000, offset: 0 });

  const { mutateAsync: updateSensor, isPending } = useUpdateSensor();
  const queryClient = useQueryClient();

  const onSubmit = async (values: UpdateSensorValidator) => {
    if (!sensor) return;
    const { position, sensorSetId } = values;
    await updateSensor({
      id: sensor.id,
      updateSensorBody: { position, sensorSetId: sensorSetId ?? null },
    });
    await queryClient.invalidateQueries({ queryKey: sensorKeys.list(query) });
    toggleIsOpen();
    toast(t('toast.item_updated', { item: t('ovoscan') }), { type: 'success' });
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit_ovoscan')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              loading={isPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateOvoscan" layout="vertical">
        <FormItem name="serialNumber" label={t('serial_number')}>
          <Controller
            control={control}
            name="serialNumber"
            render={({ field }) => <Input {...field} disabled />}
          />
        </FormItem>

        <FormItem
          name="sensorSetId"
          label={t('batch.ovoscan_set')}
          error={errors.sensorSetId?.message}
        >
          <Controller
            control={control}
            name="sensorSetId"
            render={({ field }) => (
              <Select
                options={sensorSets?.items.map((set) => ({ value: set.id, label: set.name }))}
                allowClear
                {...field}
                value={sensorSetId}
              />
            )}
          />
        </FormItem>

        {sensor?.type === SensorViewTypeEnum.Ovoscan && (
          <FormItem required name="position" label={t('position')} error={errors.position?.message}>
            <Controller
              control={control}
              name="position"
              render={({ field }) => (
                <Select
                  options={Array.from(Array(8).keys()).map((key) => ({
                    value: key + 1,
                    label: key + 1,
                  }))}
                  {...field}
                />
              )}
            />
          </FormItem>
        )}
      </AntForm>
    </Drawer>
  );
};
