import dayjs from 'dayjs';

export enum DateFormat {
  Date = 'D MMM YYYY',
  DateTime = 'D MMM YYYY HH:mm',
  FullDate = 'dddd, D MMM YYYY',
  Time = 'HH:mm',
  ShortDateTime = 'HH:mm, D MMM',
  DateNoSeparator = 'DDMMYYYY',
}

export const formatDate = (date?: string, format = DateFormat.Date) =>
  date ? dayjs(date).format(format) : '';

export const formatDateRange = (start?: string, end?: string, format = DateFormat.Date) =>
  `${formatDate(start, format)} - ${formatDate(end, format)}`;
