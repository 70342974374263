import { routes } from '@app/constants';
import { authApi, getData } from '@app/utils/api/api';
import { useUserStore } from '@data/storage';
import type { LoginBody, SetPasswordBody } from '@sdk/api';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useLogin = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation({
    mutationFn: (values: LoginBody) => getData(authApi.login({ loginBody: values })),
    onSuccess,
  });

export const useRefreshToken = () =>
  useMutation({
    mutationFn: (refreshToken: string) =>
      getData(authApi.refresh({ refreshBody: { value: refreshToken } })),
  });

export const useForgotPassword = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation({
    mutationFn: (email: string) =>
      getData(authApi.forgotPassword({ forgotPasswordBody: { email } })),
    onSuccess,
  });

export const useSetPassword = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation({
    mutationFn: (setPasswordBody: SetPasswordBody) =>
      getData(authApi.setPassword({ setPasswordBody })),
    onSuccess,
  });

export const useLogout = () => {
  const navigate = useNavigate();
  const { setUser } = useUserStore();

  const logout = () => {
    setUser(undefined);
    navigate(`/${routes.signIn}`);
  };

  return { logout };
};
