import { changeLanguage, getTimeRange } from '@app/utils/helpers';
import { useGetBatchById, useGetBatchEvents, useGetBatchStats } from '@data/hooks';
import type { Language } from '@sdk/api';
import { AntConfigProvider, AntTypography } from '@ui/components';
import { fontSize } from '@ui/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { AvgAirTemperatureChart } from '../Tabs/Charts/AvgAirTemperatureChart';
import { AvgEggTemperatureChart } from '../Tabs/Charts/AvgEggTemperatureChart';
import { AvgSensorDataChart } from '../Tabs/Charts/AvgSensorDataChart';
import { HatchWindowChart } from '../Tabs/Charts/HatchWindowChart';
import { getCommentsBlocks, getNotifications } from '../utils/reportHelpers';
import { BatchDetails } from './BatchDetails';
import { BatchInfo } from './BatchInfo';
import { Comments } from './Comments';
import { Notifications } from './Notifications';
import { PageLayout } from './PageLayout';

export const BatchReport = () => {
  const { t, i18n } = useTranslation();
  const { batchId = '' } = useParams();
  const { data: batch } = useGetBatchById(batchId);
  const { data: stats } = useGetBatchStats(batchId);
  const { data: events } = useGetBatchEvents(batchId);

  const [searchParams] = useSearchParams();
  const language = searchParams.get('lang');

  useEffect(() => {
    language && changeLanguage(language as Language, i18n);
  }, [language, i18n]);

  const { Title } = AntTypography;

  const timeRange = getTimeRange(batch);
  const chartProps = { stats, timeRange, isPrintable: true };
  const titleStyle = { fontSize: fontSize.xl, marginTop: 18 };

  const comments = getCommentsBlocks(t, events);
  const notifications = getNotifications(events);

  return (
    <AntConfigProvider
      theme={{
        components: {
          Table: {
            cellPaddingBlockSM: 3,
            cellPaddingInlineSM: 3,
            headerSplitColor: 'transparent',
          },
        },
      }}
    >
      <PageLayout batch={batch} header={t('report')}>
        <Title style={titleStyle}>{t('general')}</Title>
        <BatchInfo batch={batch} />
        <Title style={{ ...titleStyle, marginTop: 25 }}>{t('batch.batch_details')}</Title>
        <BatchDetails batch={batch} />
        {comments.length <= 1 && (
          <>
            <Title style={titleStyle}>{t('comments_and_conclusion')}</Title>
            <Comments comments={comments} />
          </>
        )}
      </PageLayout>

      {comments.length > 1 && (
        <PageLayout batch={batch} header={t('report')}>
          <Title style={titleStyle}>{t('comments_and_conclusion')}</Title>
          <Comments comments={comments} />
        </PageLayout>
      )}

      <PageLayout batch={batch} header={t('report')}>
        <Title style={titleStyle}>{t('charts')}</Title>
        <AvgSensorDataChart {...chartProps} />
        <AvgAirTemperatureChart {...chartProps} />
      </PageLayout>

      <PageLayout batch={batch} header={t('report')}>
        <AvgEggTemperatureChart {...chartProps} />
        <HatchWindowChart {...chartProps} />
      </PageLayout>

      <PageLayout batch={batch} header={t('report')}>
        <Title style={titleStyle}>{t('notifications')}</Title>
        <Notifications notifications={notifications} />
      </PageLayout>
    </AntConfigProvider>
  );
};
