import { SensorTypeFilters, SensorTypeSelector } from '@app/components/Filters';
import { useTable } from '@app/utils/hooks';
import type { SensorApiGetSensorsRequest } from '@sdk/api';
import { AntCard, AntFlex, AntSpace, Button, Input } from '@ui/components';
import { fontSize } from '@ui/constants';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';

export const SensorsListFilters = ({
  query,
  setQuery,
}: {
  query: SensorApiGetSensorsRequest;
  setQuery: (q: SensorApiGetSensorsRequest) => void;
}) => {
  const { t } = useTranslation();
  const { handleSearch, clearQueryParams } = useTable(query, setQuery);

  return (
    <AntCard bordered={false} style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }}>
      <AntSpace direction="vertical" className={styles.container}>
        <AntFlex justify="space-between" wrap="wrap">
          <Input type="search" className={styles.input} onChange={handleSearch()} />
          <SensorTypeSelector query={query} setQuery={setQuery} />
        </AntFlex>

        {query.type?.length && (
          <AntSpace className={styles.filters} size={[0, 6]} wrap>
            <SensorTypeFilters query={query} setQuery={setQuery} />
            <Button
              size="small"
              onClick={() => clearQueryParams(['type'])}
              style={{ fontSize: fontSize.sm, height: 22 }}
            >
              {t('actions.clear_filters')}
            </Button>
          </AntSpace>
        )}
      </AntSpace>
    </AntCard>
  );
};
