import { routes } from '@app/constants';
import { usePagination, useTable } from '@app/utils/hooks';
import { useGetHatcheries } from '@data/hooks';
import type { HatcheryApiGetHatcheriesRequest } from '@sdk/api';
import { AntGrid, AntLayout, AntSpace, AntTable, MultilineTableCell } from '@ui/components';
import { type TableColumnsType } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from '../styles.module.css';
import { HatcheriesListFilters } from './HatcheriesListFilters';
import { HatcheriesListHeader } from './HatcheriesListHeader';

interface DataType {
  id: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  contactPerson?: string;
}

export const HatcheriesList: React.FC = () => {
  const { t } = useTranslation();

  const { Content } = AntLayout;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<HatcheryApiGetHatcheriesRequest>({
    limit: 10,
    offset: 0,
    country: undefined,
    search: undefined,
    sort: undefined,
    direction: undefined,
  });

  const { data } = useGetHatcheries(query);

  const { handleSort } = useTable(query, setQuery);

  const dataSource = data?.items.map((item) => {
    const { id, name, location, contactPerson } = item;
    return {
      id,
      name,
      addressLine1: location.address,
      addressLine2: `${location.postalCode}, ${location.city}, ${location.country}`,
      contactPerson: contactPerson,
    };
  });

  const CellLink = ({ record, children }: { record: DataType; children: React.ReactNode }) => (
    <Link className={styles.cellLink} to={`/${routes.app}/${routes.hatcheries}/${record.id}`}>
      {children}
    </Link>
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: t('hatchery'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell text={record.name} description={[record.contactPerson]} />
        </CellLink>
      ),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('name'),
      }),
    },
    {
      title: t('location'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell text={record.addressLine1} description={[record.addressLine2]} />
        </CellLink>
      ),
    },
  ];

  const columnsMobile: TableColumnsType<DataType> = [
    {
      render: (_, record) => (
        <CellLink record={record}>
          <AntSpace direction="vertical" size={0}>
            <MultilineTableCell text={record.name} description={[record.contactPerson]} />
            <MultilineTableCell text={record.addressLine1} description={[record.addressLine2]} />
          </AntSpace>
        </CellLink>
      ),
    },
  ];

  const pagination = usePagination(query, setQuery, data?.count);

  return (
    <AntLayout>
      <HatcheriesListHeader />
      <Content className={styles.content}>
        <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
          <HatcheriesListFilters query={query} setQuery={setQuery} />
          <AntTable
            showHeader={!xs}
            columns={xs ? columnsMobile : columns}
            dataSource={dataSource}
            pagination={pagination}
            rowKey={(record) => record.id}
            style={{ marginBottom: xs ? 100 : 0 }}
            className={styles.tableWithCellLinks}
          />
        </AntSpace>
      </Content>
    </AntLayout>
  );
};
