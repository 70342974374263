import { debounce } from '@app/utils/helpers';
import type {
  BatchApiGetBatchesRequest,
  CustomerApiGetCustomersRequest,
  HatcheryApiGetHatcheriesRequest,
  SensorApiGetSensorsRequest,
} from '@sdk/api';
import type { ChangeEvent } from 'react';

type QueryType = CustomerApiGetCustomersRequest &
  HatcheryApiGetHatcheriesRequest &
  BatchApiGetBatchesRequest &
  SensorApiGetSensorsRequest;

export const useTable = (query: QueryType, setQuery: (query: QueryType) => void) => {
  const handleSort = (columnName = 'name'): void => {
    const { sort, direction } = query;
    if (columnName === sort) {
      setQuery({
        ...query,
        direction: !direction ? 'asc' : direction === 'asc' ? 'desc' : undefined,
      });
    } else {
      setQuery({
        ...query,
        sort: columnName,
        direction: 'asc',
      });
    }
  };

  const handleSearch = () =>
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setQuery({ ...query, offset: 0, search: event.target.value });
    }, 500);

  const clearQueryParam = (param: string) =>
    setQuery({
      ...query,
      [param]: undefined,
    });

  const clearQueryParams = (
    params: (
      | 'country'
      | 'hatcheryIds'
      | 'customerIds'
      | 'status'
      | 'withData'
      | 'start'
      | 'type'
    )[],
  ) => {
    const newQuery = { ...query };
    params.forEach((param) => (newQuery[param] = undefined));
    setQuery(newQuery);
  };

  return {
    handleSort,
    handleSearch,
    clearQueryParam,
    clearQueryParams,
  };
};
