import { routes } from '@app/constants';
import { AntResult, AntTypography, Button } from '@ui/components';
import { fontSize, fontWeight } from '@ui/constants';
import { Error } from '@ui/icons';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const ErrorPage = () => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  return (
    <AntResult
      icon={<Error />}
      title={
        <Text style={{ fontSize: 30, fontWeight: fontWeight.semibold }}>{t('error.title')}</Text>
      }
      subTitle={<Text style={{ fontSize: fontSize.lg }}>{t('error.description')}</Text>}
      extra={
        <NavLink to={routes.root}>
          <Button type="primary">{t('actions.go_to_home_page')}</Button>
        </NavLink>
      }
    />
  );
};
