import { DateFormat, formatDate } from '@app/utils/helpers';
import type { BatchDetailView } from '@sdk/api';
import { AntTable } from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

type DataSourceType = {
  key: string;
  value?: string | number;
};

export const BatchInfo = ({ batch }: { batch?: BatchDetailView }) => {
  const { t } = useTranslation();

  if (!batch) return null;
  const { location, customer } = batch.house?.customerLocation ?? {};

  const data = {
    [t('hatchery')]: batch.hatchery?.name,
    [t('customer')]: customer?.name,
    [t('location')]: location
      ? `${location.address}, ${location.city}, ${location.country}`
      : undefined,
    [t('house')]: batch.house?.name,
    [t('contact_person')]: batch.house?.customerLocation.customer?.contactPerson,
    [t('breed')]: batch.breed?.name,
    [t('batch.average_age_parent_stock')]: batch.averageParentAge,
    [t('batch.parent_stock_flock_id')]: batch.parentFlockId,
    [t('batch.monitoring_start')]: batch.monitoringStart,
    [t('batch.monitoring_end')]: batch.monitoringEnd,
  };

  const dataSource: DataSourceType[] = Object.entries(data).map(([key, value]) => ({
    key,
    value:
      [t('batch.monitoring_start'), t('batch.monitoring_end')].includes(key) && value
        ? formatDate(value as string, DateFormat.DateTime)
        : value,
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: 250 },
    { render: (_, record) => record.value },
  ];

  return (
    <AntTable
      size="small"
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.key}
      pagination={false}
      showHeader={false}
    />
  );
};
