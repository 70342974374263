import { Tabs, type TabsProps } from '@app/components/Tabs';
import { HatcheryTabs } from '@app/constants/enums';
import { useDrawer } from '@app/utils/hooks';
import { useDeleteHatchery } from '@data/hooks';
import type { HatcheryView } from '@sdk/api';
import { AntFlex, AntLayout, AntTypography, BackButton, DeleteButton, Modal } from '@ui/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../styles.module.css';

type ComponentType = {
  hatchery?: HatcheryView;
};

export const HatcheryDetailedHeader = ({ hatchery }: ComponentType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen: isDeleteModalOpen, toggleIsOpen: toggleDeleteModal } = useDrawer();

  const { Header } = AntLayout;
  const { Title, Text } = AntTypography;

  const { mutateAsync: deleteHatchery } = useDeleteHatchery();

  const handleDelete = async () => {
    if (!hatchery) return;
    await deleteHatchery({ id: hatchery.id });
    navigate('..');
    toggleDeleteModal();
    toast(t('toast.hatchery_deleted'), { type: 'success' });
  };

  const tabItems: TabsProps[] = [
    {
      key: HatcheryTabs.Employees,
      label: t('employees'),
    },
    {
      key: HatcheryTabs.Notifications,
      label: t('notifications'),
    },
    {
      key: HatcheryTabs.GeneralInfo,
      label: t('general_info'),
    },
  ];

  return (
    <Header className={styles.headerWithTabs}>
      <AntFlex vertical flex={1}>
        <BackButton className={styles.backButton} />
        <AntFlex align="center" justify="space-between">
          <Title level={1} className={styles.headerTitle}>
            {hatchery?.name}
          </Title>
          <DeleteButton onClick={toggleDeleteModal} />
        </AntFlex>
        <Tabs items={tabItems} />
      </AntFlex>

      <Modal
        title={t('delete_modal.title')}
        cancelText={t('delete_modal.no')}
        okText={t('delete_modal.yes')}
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={toggleDeleteModal}
        isDanger
      >
        <Text>{t('delete_modal.description_hatchery')}</Text>
      </Modal>
    </Header>
  );
};
