import { useMeasurementConversion } from '@app/utils/hooks';
import { customerKeys } from '@data/constants';
import { useCreateHouse } from '@data/hooks';
import { HouseSchema, type HouseValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
} from '@ui/components';
import { Button } from '@ui/components';
import TextArea from 'antd/es/input/TextArea';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface AddHouseDrawerProps extends DrawerProps {
  customerLocationId?: string;
}

export const AddHouseDrawer = ({
  toggleIsOpen,
  customerLocationId,
  ...props
}: AddHouseDrawerProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const queryClient = useQueryClient();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<HouseValidator>({
    mode: 'onBlur',
    resolver: zodResolver(HouseSchema),
  });

  const { mutateAsync: createHouse } = useCreateHouse();

  const { areaMeasurementUnit, toSquareMeters } = useMeasurementConversion();

  const onSubmit = async ({ size, ...values }: HouseValidator) => {
    if (!customerLocationId || !customerId) return;
    await createHouse({
      id: customerId,
      customerLocationId,
      houseBody: { ...values, size: toSquareMeters(size) },
    });
    await queryClient.invalidateQueries({ queryKey: customerKeys.detail(customerId) });
    reset();
    toggleIsOpen();
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('add_house')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="createHouse" layout="vertical">
        <FormItem required name="name" label={t('house_name')} error={errors.name?.message}>
          <Controller control={control} name="name" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem
          name="size"
          label={t('net_area_size', { unit: areaMeasurementUnit })}
          error={errors.size?.message}
        >
          <Controller control={control} name="size" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem name="description" label={t('description')} error={errors.description?.message}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => <TextArea showCount maxLength={500} {...field} />}
            defaultValue=""
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
