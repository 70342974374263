import { routes } from '@app/constants';
import { useConfirmInvite } from '@data/hooks';
import { SignUpSchema, type SignUpValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AntCard,
  AntFlex,
  AntForm,
  AntTypography,
  Button,
  FormItem,
  Input,
  PhoneInput,
} from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';

export const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const { Title, Text } = AntTypography;
  const navigate = useNavigate();

  const { mutate: invite, isPending } = useConfirmInvite({
    onSuccess: () => navigate(`/${routes.app}/${routes.dashboard}`),
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onSubmit = (values: SignUpValidator) => {
    if (!token) return;
    invite({ ...values, token });
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignUpValidator>({
    mode: 'onBlur',
    resolver: zodResolver(SignUpSchema),
  });

  return (
    <AntCard classNames={{ body: styles.card }}>
      <AntFlex vertical justify="center">
        <Title level={2}>{t('sign_up')}</Title>
        <Text type="secondary" className={styles.description}>
          {t('create_a_password_and_login')}
        </Text>
      </AntFlex>

      <AntForm name="signUp" onFinish={handleSubmit(onSubmit)} autoComplete="off" layout="vertical">
        <FormItem
          required
          name="firstName"
          label={t('first_name')}
          error={errors.firstName?.message}
        >
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => <Input type="text" {...field} />}
          />
        </FormItem>
        <FormItem required name="lastName" label={t('last_name')} error={errors.lastName?.message}>
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => <Input type="text" {...field} />}
          />
        </FormItem>
        <FormItem
          required
          name="mobilePhone"
          label={t('mobile_phone')}
          error={errors.mobilePhone?.message}
        >
          <Controller
            control={control}
            name="mobilePhone"
            render={({ field }) => <PhoneInput {...field} />}
          />
        </FormItem>
        <FormItem name="fixedPhone" label={t('fixed_phone')} error={errors.fixedPhone?.message}>
          <Controller
            control={control}
            name="fixedPhone"
            render={({ field }) => <PhoneInput {...field} />}
          />
        </FormItem>
        <FormItem
          required
          name="password"
          label={t('new_password')}
          error={errors.password?.message}
        >
          <Controller
            control={control}
            name="password"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>
        <FormItem
          required
          name="confirmedPassword"
          label={t('confirm_new_password')}
          error={errors.confirmedPassword?.message}
        >
          <Controller
            control={control}
            name="confirmedPassword"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className={styles.button}
          loading={isPending}
          disabled={!isValid}
        >
          {t('actions.create_password')}
        </Button>
      </AntForm>
    </AntCard>
  );
};
