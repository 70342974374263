import { routes } from '@app/constants';
import { DateFormat, formatDate, formatDateRange } from '@app/utils/helpers';
import { usePagination, useTable } from '@app/utils/hooks';
import { useGetBatches } from '@data/hooks';
import { type BatchApiGetBatchesRequest, BatchViewStatusEnum } from '@sdk/api';
import {
  AntFlex,
  AntGrid,
  AntLayout,
  AntSpace,
  AntTable,
  AntTag,
  AntTypography,
  MultilineTableCell,
} from '@ui/components';
import { colors, fontSize } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import styles from '../styles.module.css';
import { BatchesListFilters } from './BatchesListFilters.tsx';
import { BatchesListHeader } from './BatchesListHeader.tsx';

interface DataType {
  id: string;
  houseName?: string;
  customerName?: string;
  contactPerson?: string;
  customerLocationName?: string;
  hatcheryName?: string;
  monitoringStart: string;
  monitoringEnd: string;
  status: BatchViewStatusEnum;
}

export const BatchesList: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') as BatchViewStatusEnum | 'all';
  const getSelectedTab = () => (selectedTab === 'all' ? undefined : selectedTab);

  const { Content } = AntLayout;
  const { Text } = AntTypography;

  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<BatchApiGetBatchesRequest>({
    limit: 10,
    offset: 0,
    search: undefined,
    sort: undefined,
    direction: undefined,
    status: getSelectedTab(),
    withData: false,
    start: undefined,
    end: undefined,
  });

  useEffect(() => {
    setQuery({ ...query, status: getSelectedTab() });
  }, [selectedTab]);

  const { data } = useGetBatches(query);

  const { handleSort } = useTable(query, setQuery);

  const dataSource = data
    ? data.items.map((item) => {
        const { id, hatchery, house, monitoringStart, monitoringEnd, status } = item;
        return {
          id,
          houseName: house?.name,
          customerName: house?.customerLocation?.customer?.name,
          contactPerson: house?.customerLocation?.customer?.contactPerson,
          customerLocationName: house?.customerLocation?.name,
          hatcheryName: hatchery?.name,
          monitoringStart,
          monitoringEnd,
          status,
        };
      })
    : [];

  const renderTag = (status?: BatchViewStatusEnum) => {
    if (!status) return null;
    const color = {
      [BatchViewStatusEnum.Active]: 'green',
      [BatchViewStatusEnum.Inactive]: 'orange',
      [BatchViewStatusEnum.Closed]: 'default',
    }[status];

    return <AntTag color={color}>{t(status)}</AntTag>;
  };

  const CellLink = ({ record, children }: { record: DataType; children: React.ReactNode }) => (
    <Link className={styles.cellLink} to={`/${routes.app}/${routes.batches.root}/${record.id}`}>
      {children}
    </Link>
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: t('customer'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell text={record.customerName} description={[record.contactPerson]} />
        </CellLink>
      ),
      sortDirections: ['descend'],
    },
    {
      title: t('location'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell
            text={record.customerLocationName}
            description={[
              <Trans
                key="house_with_name"
                i18nKey="house_with_name"
                values={{ name: record.houseName }}
                components={{
                  bold: <Text strong style={{ fontSize: fontSize.md, color: colors.grey500 }} />,
                }}
              />,
            ]}
          />
        </CellLink>
      ),
    },
    {
      title: t('hatchery'),
      render: (_, record) => (
        <CellLink record={record}>
          <Text>{record.hatcheryName}</Text>
        </CellLink>
      ),
    },
    {
      title: t('batch.monitoring_start'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell
            text={formatDate(record.monitoringStart)}
            description={[formatDate(record.monitoringStart, DateFormat.Time)]}
          />
        </CellLink>
      ),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('monitoringStart'),
      }),
    },
    {
      title: t('batch.monitoring_end'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell
            text={formatDate(record.monitoringEnd)}
            description={[formatDate(record.monitoringEnd, DateFormat.Time)]}
          />
        </CellLink>
      ),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('monitoringEnd'),
      }),
    },
    {
      title: t('status'),
      render: (_, record) => <CellLink record={record}>{renderTag(record.status)}</CellLink>,
    },
  ];

  const columnsMobile: TableColumnsType<DataType> = [
    {
      render: (_, record) => (
        <CellLink record={record}>
          <AntSpace direction="vertical" size={0} style={{ width: '100%' }}>
            <AntFlex align="center" justify="space-between">
              <Text>{record.customerName}</Text>
              {renderTag(record.status)}
            </AntFlex>

            <Text style={{ fontSize: fontSize.sm, color: colors.grey500 }}>
              {record.contactPerson}
            </Text>

            <MultilineTableCell
              text={record.customerLocationName}
              description={[record.houseName]}
            />

            <MultilineTableCell
              text={record.hatcheryName}
              description={[
                formatDateRange(record.monitoringStart, record.monitoringEnd, DateFormat.DateTime),
              ]}
            />
          </AntSpace>
        </CellLink>
      ),
    },
  ];

  const pagination = usePagination(query, setQuery, data?.count);

  return (
    <AntLayout>
      <BatchesListHeader />
      <Content className={styles.content}>
        <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
          <BatchesListFilters query={query} setQuery={setQuery} />
          <AntTable
            showHeader={!xs}
            columns={xs ? columnsMobile : columns}
            dataSource={dataSource}
            rowKey={(record) => record.id}
            pagination={pagination}
            style={{ marginBottom: xs ? 100 : 0 }}
            className={styles.tableWithCellLinks}
          />
        </AntSpace>
      </Content>
    </AntLayout>
  );
};
