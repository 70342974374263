import { breakpointWidth } from '@ui/constants';
import { useEffect, useState } from 'react';

export const useMobile = () => {
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia('(orientation: landscape)').matches,
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia('(orientation: landscape)').matches);
    };

    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  const isMobile =
    window.matchMedia(`(max-width: ${breakpointWidth.sm}px)`).matches ||
    (isLandscape && window.matchMedia(`(max-height: ${breakpointWidth.sm}px)`).matches);

  return { isMobile, isLandscape };
};
