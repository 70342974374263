import { CloseOutlined } from '@ant-design/icons';
import { UserCard } from '@app/components';
import { routes } from '@app/constants';
import { useMobile, usePermissions } from '@app/utils/hooks';
import { useLogout } from '@data/hooks';
import { useUserStore } from '@data/storage';
import { RoleType } from '@sdk/api';
import { AntConfigProvider, AntFlex, AntFloatButton, AntLayout } from '@ui/components';
import { Button } from '@ui/components';
import { colors, sidebarWidth } from '@ui/constants';
import {
  Dashboard,
  DashboardFilled,
  Eggs,
  EggsFilled,
  FolderShared,
  FolderSharedFilled,
  LocationAway,
  LocationAwayFilled,
  Logout,
  NestBornLogo,
  Ovoscan,
  OvoscanFilled,
  Settings,
  SettingsFilled,
} from '@ui/icons';
import { Drawer, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

type SidebarProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
};

export const Sidebar = ({ isOpen, toggleIsOpen }: SidebarProps) => {
  const { t } = useTranslation();
  const { Sider } = AntLayout;
  const { isMobile, isLandscape } = useMobile();
  const {
    isNestBornAdmin,
    isAnyHatcheryAdmin,
    isAnyCustomerAdmin,
    isAnyCustomerUser,
    isAnyHatcheryUser,
    roles,
  } = usePermissions();

  const navigate = useNavigate();

  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');

  useEffect(() => {
    const segments = location.pathname.split('/');
    if (segments[2] !== selectedMenuItem) setSelectedMenuItem(segments[2]);
  }, [location.pathname, selectedMenuItem]);

  const { user } = useUserStore();

  const onMenuItemClick = (key: string) => {
    toggleIsOpen();
    navigate(key);
    setSelectedMenuItem(key);
  };

  const { logout } = useLogout();

  const iconSize = { width: 16, height: 16 };

  enum MenuItemKeys {
    Dashboard = 'dashboard',
    Batches = 'batches',
    Hatcheries = 'hatcheries',
    Customers = 'customers',
    Ovoscans = 'ovoscans',
    Settings = 'settings',
  }

  const menuItemProps = {
    [MenuItemKeys.Dashboard]: {
      key: routes.dashboard,
      label: t('dashboard'),
      icon: <Dashboard {...iconSize} />,
      iconFilled: <DashboardFilled {...iconSize} />,
    },
    [MenuItemKeys.Batches]: {
      key: routes.batches.root,
      label: t('batches'),
      icon: <Eggs {...iconSize} />,
      iconFilled: <EggsFilled {...iconSize} />,
    },
    [MenuItemKeys.Hatcheries]: {
      key: routes.hatcheries,
      label: t('hatcheries'),
      icon: <LocationAway {...iconSize} />,
      iconFilled: <LocationAwayFilled {...iconSize} />,
    },
    [MenuItemKeys.Customers]: {
      key: routes.customers,
      label: t('customers'),
      icon: <FolderShared {...iconSize} />,
      iconFilled: <FolderSharedFilled {...iconSize} />,
    },
    [MenuItemKeys.Ovoscans]: {
      key: routes.ovoscans,
      label: t('ovoscans'),
      icon: <Ovoscan {...iconSize} />,
      iconFilled: <OvoscanFilled {...iconSize} />,
    },
    [MenuItemKeys.Settings]: {
      key: routes.settings,
      label: t('settings'),
      icon: <Settings {...iconSize} />,
      iconFilled: <SettingsFilled {...iconSize} />,
    },
  };

  const isAdmin = isNestBornAdmin || isAnyHatcheryAdmin;
  const isCustomersMenu = isAdmin || isAnyHatcheryUser;
  const isFarmMenu = (isAnyCustomerAdmin || isAnyCustomerUser) && !isCustomersMenu;

  const getAllowedMenuItems = () => {
    if (isAdmin)
      return [
        MenuItemKeys.Dashboard,
        MenuItemKeys.Batches,
        MenuItemKeys.Hatcheries,
        MenuItemKeys.Customers,
        MenuItemKeys.Ovoscans,
        MenuItemKeys.Settings,
      ];

    if (isCustomersMenu || isFarmMenu)
      return [
        MenuItemKeys.Dashboard,
        MenuItemKeys.Batches,
        MenuItemKeys.Customers,
        MenuItemKeys.Settings,
      ];

    return [MenuItemKeys.Dashboard, MenuItemKeys.Batches, MenuItemKeys.Settings];
  };

  const menuItems = getAllowedMenuItems().map((item) => {
    const farms = roles
      .filter((r) => r.type === RoleType.CustomerAdmin || r.type === RoleType.CustomerUser)
      .map((r) => r.customerId);

    const path =
      item === MenuItemKeys.Customers && isFarmMenu && farms.length === 1
        ? `${routes.customers}/${farms[0]}`
        : menuItemProps[item].key;

    const getLabel = () => {
      if (item === MenuItemKeys.Customers && isFarmMenu) {
        return farms.length === 1 ? t('my_farm') : t('my_farms');
      }
      return menuItemProps[item].label;
    };

    return (
      <Menu.Item
        key={menuItemProps[item].key}
        onClick={() => onMenuItemClick(path)}
        icon={
          selectedMenuItem === menuItemProps[item].key
            ? menuItemProps[item].iconFilled
            : menuItemProps[item].icon
        }
      >
        <Link to={menuItemProps[item].key} className={styles.sidebarLink}>
          {getLabel()}
        </Link>
      </Menu.Item>
    );
  });

  const LogoutButton = () => (
    <Button
      icon={isMobile ? <Logout width={16} height={16} /> : undefined}
      iconRight={isMobile ? undefined : <Logout width={16} height={16} opacity={0.5} />}
      type="link"
      size="small"
      style={{
        color: colors.white,
        margin: 4,
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={logout}
    >
      {t('actions.log_out')}
    </Button>
  );

  const SideMenu = () => (
    <AntConfigProvider
      theme={{
        components: {
          Menu: {
            itemColor: colors.white,
            itemHoverColor: colors.yellowGreen500,
            itemSelectedColor: colors.yellowGreen500,
            itemHoverBg: colors.grey200,
            itemActiveBg: colors.grey200,
            itemSelectedBg: colors.grey200,
            itemBg: 'none',
            lineWidth: 0,
          },
        },
      }}
    >
      <AntFlex vertical justify="space-between" className={styles.siderContent}>
        <div>
          <UserCard
            user={{
              name: `${user?.firstName} ${user?.lastName}`,
              email: user?.email,
            }}
            className={styles.userCard}
            isDark
          />
          <Menu selectedKeys={[selectedMenuItem]}>{menuItems}</Menu>
          {isMobile && <LogoutButton />}
        </div>
        <AntFlex align="center" justify="space-between">
          <NestBornLogo />
          {!isMobile && <LogoutButton />}
        </AntFlex>
      </AntFlex>
    </AntConfigProvider>
  );

  return isMobile ? (
    <Drawer
      width={isLandscape ? sidebarWidth : '100%'}
      open={isOpen}
      placement="left"
      styles={{ content: { backgroundColor: colors.teal700 } }}
      closeIcon={null}
    >
      <AntFloatButton icon={<CloseOutlined />} onClick={toggleIsOpen} />
      <SideMenu />
    </Drawer>
  ) : (
    <Sider width={sidebarWidth} className={styles.sider}>
      <SideMenu />
    </Sider>
  );
};
