import { z } from 'zod';

export const SetPasswordSchema = z
  .object({
    password: z.string().min(10),
    confirmedPassword: z.string().min(10),
  })
  .refine((data) => data.password === data.confirmedPassword, {
    message: 'Confirmed password must match the password',
    path: ['confirmedPassword'],
  });

export const UpdatePasswordSchema = z
  .object({
    oldPassword: z.string().min(10),
    password: z.string().min(10),
    confirmedPassword: z.string().min(10),
  })
  .refine((data) => data.password === data.confirmedPassword, {
    message: 'Confirmed password must match the password',
    path: ['confirmedPassword'],
  });

export const SignUpSchema = z
  .object({
    password: z.string().min(10),
    confirmedPassword: z.string().min(10),
    firstName: z.string(),
    lastName: z.string(),
    mobilePhone: z.string(),
    fixedPhone: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmedPassword, {
    message: 'Confirmed password must match the password',
    path: ['confirmedPassword'],
  });

export const EmailSchema = z.object({
  email: z.string().email(),
});

export const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(10),
});

export const LanguageSchema = z.enum(['nl', 'en', 'fr']);

export const InviteSchema = z.object({
  email: z.string().email(),
  type: z.enum(['nestbornAdmin', 'hatcheryAdmin', 'hatcheryUser', 'customerAdmin', 'customerUser']),
  hatcheryId: z.string().optional(),
  customerId: z.string().optional(),
  language: LanguageSchema,
});

export const SettingsSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().email().optional(),
  mobilePhone: z.string().optional(),
  fixedPhone: z.string().optional(),
  eggTempScale: z.string(),
  airTempScale: z.string(),
  measurementSystem: z.string(),
  language: LanguageSchema,
});

export const CreateHatcherySchema = z.object({
  name: z.string(),
  country: z.string(),
  city: z.string(),
  address: z.string(),
  postalCode: z.string(),
  email: z.string().email(),
  language: LanguageSchema,
});

export const CreateCustomerSchema = z.object({
  name: z.string(),
  hatcheryIds: z.array(z.string()),
  email: z.string().email(),
  externalId: z.string().optional(),
  language: LanguageSchema,
});

export const HouseSchema = z.object({
  name: z.string().min(1),
  size: z
    .string()
    .optional()
    .transform((val) => val ?? ''),
  description: z.string().optional(),
});

export const AddLocationSchema = z.object({
  name: z.string(),
  country: z.string(),
  city: z.string(),
  address: z.string(),
  postalCode: z.string(),
  houses: z.array(HouseSchema).min(1),
});

export const UpdateLocationSchema = z.object({
  name: z.string().optional(),
  country: z.string().optional(),
  city: z.string().optional(),
  address: z.string().optional(),
  postalCode: z.string().optional(),
});

export const UpdateHatcherySchema = z.object({
  name: z.string().optional(),
  country: z.string().optional(),
  city: z.string().optional(),
  address: z.string().optional(),
  postalCode: z.string().optional(),
});

export const UpdateCustomerSchema = z.object({
  name: z.string().optional(),
  hatcheryIds: z.array(z.string()).optional(),
  externalId: z.string().optional(),
});

export const AddBatchSchema = z
  .object({
    hatcheryId: z.string(),
    customerId: z.string(),
    customerLocationId: z.string(),
    houseId: z.string(),
    breedId: z.string(),
    startDate: z.string(),
    startTime: z.string(),
    endDate: z.string(),
    endTime: z.string(),
    numberPlaced: z.number(),
    expectedNonHatched: z.number().optional().nullable(),
    averageParentAge: z.number().optional().nullable(),
    parentFlockId: z.string().optional(),
    sensorSetId: z.string(),
    floorPlan: z.number(),
  })
  .refine(
    (data) => {
      const startDate = new Date(data.startDate);
      const endDate = new Date(data.endDate);
      return endDate > startDate;
    },
    {
      message: 'End date must be after the start date',
      path: ['endDate'],
    },
  );

export const UpdateBatchGeneralSchema = z.object({
  startDate: z.string(),
  startTime: z.string(),
  endDate: z.string(),
  endTime: z.string(),
});

export const UpdateBatchDetailsSchema = z.object({
  breedId: z.string().optional(),
  sensorSetId: z.string().optional(),
  numberPlaced: z.number().optional(),
  expectedNonHatched: z.number().optional().nullable(),
  averageParentAge: z.number().optional().nullable(),
  parentFlockId: z
    .string()
    .optional()
    .nullable()
    .transform((val) => (val === null ? '' : val)),
});

export const UpdateBatchPlanSchema = z.object({
  floorPlan: z.number(),
});

export const UpdateSensorSchema = z.object({
  serialNumber: z.string().readonly(),
  sensorSetId: z.string().optional().nullable(),
  position: z.number().optional(),
});

export const UpdateSensorSetSchema = z.object({
  name: z.string().min(1, { message: 'Required' }),
  hatcheryIds: z.array(z.string()).min(1, { message: 'Required' }),
});

export const CreateSensorSetSchema = UpdateSensorSetSchema;

export const UpdateGatewaySchema = z.object({
  name: z.string().min(1, { message: 'Required' }),
  hatcheryIds: z.array(z.string()).min(1, { message: 'Required' }),
  code: z.string().min(1, { message: 'Required' }),
});

export const CloseBatchSchema = z.object({
  numberPlaced: z.number().optional().nullable(),
  numberNonHatched: z.number().optional().nullable(),
  selectionDay1: z.number().optional().nullable(),
  selectionDay7: z.number().optional().nullable(),
  mortalityDay7: z.number().optional().nullable(),
  conclusion: z.string().optional(),
});

export type SetPasswordValidator = z.infer<typeof SetPasswordSchema>;
export type UpdatePasswordValidator = z.infer<typeof UpdatePasswordSchema>;
export type SignUpValidator = z.infer<typeof SignUpSchema>;
export type EmailValidator = z.infer<typeof EmailSchema>;
export type LoginValidator = z.infer<typeof LoginSchema>;
export type InviteValidator = z.infer<typeof InviteSchema>;
export type SettingsValidator = z.infer<typeof SettingsSchema>;
export type CreateHatcheryValidator = z.infer<typeof CreateHatcherySchema>;
export type CreateCustomerValidator = z.infer<typeof CreateCustomerSchema>;
export type AddLocationValidator = z.infer<typeof AddLocationSchema>;
export type HouseValidator = z.infer<typeof HouseSchema>;
export type UpdateHatcheryValidator = z.infer<typeof UpdateHatcherySchema>;
export type UpdateLocationValidator = z.infer<typeof UpdateLocationSchema>;
export type UpdateCustomerValidator = z.infer<typeof UpdateCustomerSchema>;
export type AddBatchValidator = z.infer<typeof AddBatchSchema>;
export type UpdateBatchGeneralValidator = z.infer<typeof UpdateBatchGeneralSchema>;
export type UpdateBatchDetailsValidator = z.infer<typeof UpdateBatchDetailsSchema>;
export type UpdateBatchPlanValidator = z.infer<typeof UpdateBatchPlanSchema>;
export type UpdateSensorValidator = z.infer<typeof UpdateSensorSchema>;
export type UpdateSensorSetValidator = z.infer<typeof UpdateSensorSetSchema>;
export type CreateSensorSetValidator = z.infer<typeof CreateSensorSetSchema>;
export type UpdateGatewayValidator = z.infer<typeof UpdateGatewaySchema>;
export type CloseBatchValidator = z.infer<typeof CloseBatchSchema>;
