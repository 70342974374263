import { useDrawer } from '@app/utils/hooks';
import type { CustomerDetailView } from '@sdk/api';
import { AntCard, AntFlex, AntGrid, AntTable, AntTypography, EditButton } from '@ui/components';
import { fontSize, fontWeight } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import { EditCustomerDrawer } from '../Drawers';

type CustomerInfoProps = {
  customer?: CustomerDetailView;
  isAdmin: boolean;
};

type DataType = {
  name: string;
  hatcheryIds: string[];
  contactPerson: string | undefined;
  externalId: string | undefined;
};

type DataSourceType = {
  key: string;
  value?: string;
};

export const CustomerInfo = ({ customer, isAdmin }: CustomerInfoProps) => {
  const { t } = useTranslation();
  const { isOpen, toggleIsOpen } = useDrawer();
  const { Text, Title } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  if (!customer) return null;
  const { name, hatcheries, contactPerson, externalId } = customer;

  const data: DataType = {
    name,
    hatcheryIds: hatcheries.map((h) => h.id),
    contactPerson,
    externalId,
  };

  const translationKeys: Record<string, string> = {
    name: t('name'),
    hatcheryIds: t('hatchery'),
    contactPerson: t('contact_person'),
    externalId: t('external_id'),
  };

  const dataSource: DataSourceType[] = Object.entries(data).map(([key, value]) => ({
    key: translationKeys[key],
    value: Array.isArray(value) ? customer?.hatcheries.map((h) => h.name).join(', ') : value,
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: xs ? 'unset' : '25%' },
    {
      render: (_, record) => (
        <Text style={{ fontWeight: fontWeight.semibold }}>{record.value}</Text>
      ),
    },
  ];

  return (
    <AntCard bordered={false} style={{ width: '100%' }}>
      <AntFlex align="center" justify="space-between">
        <Title level={2} style={{ fontSize: fontSize.xl }}>
          {t('general')}
        </Title>
        {isAdmin && <EditButton onClick={toggleIsOpen} />}
      </AntFlex>

      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.key}
        pagination={false}
        showHeader={false}
      />

      {data && (
        <EditCustomerDrawer open={isOpen} toggleIsOpen={toggleIsOpen} initialValues={data} />
      )}
    </AntCard>
  );
};
