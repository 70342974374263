import { useCreateBatch } from '@data/hooks/batch';
import { AddBatchSchema, type AddBatchValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AntFlex,
  AntForm,
  AntProgress,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
} from '@ui/components';
import { Button } from '@ui/components';
import { colors, fontSize, fontWeight } from '@ui/constants';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { toDateTimeISO } from '../../utils';
import { AddBatchStep1 } from './Steps/Step1';
import { AddBatchStep2 } from './Steps/Step2';
import { AddBatchStep3 } from './Steps/Step3';

export const AddBatchDrawer = ({ toggleIsOpen, ...props }: DrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;
  const [currentStep, setCurrentStep] = useState(1);

  const methods = useForm<AddBatchValidator>({
    mode: 'onBlur',
    resolver: zodResolver(AddBatchSchema),
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const { mutateAsync: createBatch } = useCreateBatch();

  const steps = [
    { title: t('general_info') },
    { title: t('batch.batch_details') },
    { title: t('batch.select_floor_plan') },
  ];

  const stepsCount = steps.length;
  const percent = (currentStep / stepsCount) * 100;

  const handleStepChange = (step: number) => () => {
    setCurrentStep((current) => current + step);
  };

  const onSubmit = async (values: AddBatchValidator) => {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      expectedNonHatched,
      averageParentAge,
      ...other
    } = values;
    const monitoringStart = toDateTimeISO(startDate, startTime);
    const monitoringEnd = toDateTimeISO(endDate, endTime);
    await createBatch({
      batchBody: {
        monitoringStart,
        monitoringEnd,
        expectedNonHatched: expectedNonHatched ?? undefined,
        averageParentAge: averageParentAge ?? undefined,
        ...other,
      },
    });
    toggleIsOpen();
    reset();
    setCurrentStep(1);
    toast(t('toast.item_created', { item: t('batch.batch') }), { type: 'success' });
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('batch.new_batch')}</Text>
          <AntSpace>
            <Button onClick={handleStepChange(-1)} disabled={currentStep === 1}>
              {t('previous')}
            </Button>
            <Button
              type="primary"
              onClick={currentStep < 3 ? handleStepChange(1) : handleSubmit(onSubmit)}
              disabled={currentStep < 3 ? false : !isValid}
              style={{ minWidth: 100 }}
            >
              {t('next')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <>
        <AntFlex align="center" justify="space-between">
          <Text style={{ fontSize: fontSize.xl, fontWeight: fontWeight.semibold }}>
            {steps[currentStep - 1].title}
          </Text>
          <Text style={{ fontSize: fontSize.lg }}>
            {currentStep}/{stepsCount}
          </Text>
        </AntFlex>

        <AntProgress
          percent={percent}
          size="small"
          strokeColor={colors.yellowGreen500}
          showInfo={false}
          style={{ marginBottom: 24 }}
        />
      </>

      <FormProvider {...methods}>
        <AntForm name="addBatch" autoComplete="off" layout="vertical">
          {currentStep === 1 && <AddBatchStep1 />}
          {currentStep === 2 && <AddBatchStep2 />}
          {currentStep === 3 && <AddBatchStep3 />}
        </AntForm>
      </FormProvider>
    </Drawer>
  );
};
