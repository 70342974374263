import { getData, sensorApi } from '@app/utils/api/api';
import { sensorKeys } from '@data/constants';
import type {
  SensorApiCreateSensorSetRequest,
  SensorApiGetGatewaysRequest,
  SensorApiGetSensorSetsRequest,
  SensorApiGetSensorsRequest,
  SensorApiUpdateGatewayRequest,
  SensorApiUpdateSensorRequest,
  SensorApiUpdateSensorSetRequest,
} from '@sdk/api';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetSensorSets = (params: SensorApiGetSensorSetsRequest) =>
  useQuery({
    queryKey: sensorKeys.set(params),
    queryFn: () => getData(sensorApi.getSensorSets(params)),
  });

export const useCreateSensorSet = () =>
  useMutation({
    mutationFn: ({ sensorSetBody }: SensorApiCreateSensorSetRequest) =>
      getData(sensorApi.createSensorSet({ sensorSetBody })),
  });

export const useUpdateSensorSet = () =>
  useMutation({
    mutationFn: ({ id, updateSensorSetBody }: SensorApiUpdateSensorSetRequest) =>
      getData(sensorApi.updateSensorSet({ id, updateSensorSetBody })),
  });

export const useGetSensors = (params: SensorApiGetSensorsRequest) =>
  useQuery({
    queryKey: sensorKeys.list(params),
    queryFn: () => getData(sensorApi.getSensors(params)),
  });

export const useUpdateSensor = () =>
  useMutation({
    mutationFn: ({ id, updateSensorBody }: SensorApiUpdateSensorRequest) =>
      getData(sensorApi.updateSensor({ id, updateSensorBody })),
  });

export const useGetGateways = (params: SensorApiGetGatewaysRequest) =>
  useQuery({
    queryKey: sensorKeys.gatewaysList(params),
    queryFn: () => getData(sensorApi.getGateways(params)),
  });

export const useUpdateGateway = () =>
  useMutation({
    mutationFn: ({ id, updateGatewayBody }: SensorApiUpdateGatewayRequest) =>
      getData(sensorApi.updateGateway({ id, updateGatewayBody })),
  });
