import { routes } from '@app/constants';
import { useSetPassword } from '@data/hooks';
import { SetPasswordSchema, type SetPasswordValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { AntCard, AntFlex, AntForm, AntTypography, Button, FormItem, Input } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const { Title, Text } = AntTypography;
  const navigate = useNavigate();

  const { mutate: setPassword, isPending } = useSetPassword({
    onSuccess: () => navigate(`/${routes.app}/${routes.dashboard}`),
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onSubmit = (values: SetPasswordValidator) => {
    if (!token) return;
    setPassword({ password: values.password, token });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SetPasswordValidator>({
    mode: 'onBlur',
    resolver: zodResolver(SetPasswordSchema),
  });

  return (
    <AntCard classNames={{ body: styles.card }}>
      <AntFlex vertical justify="center">
        <Title level={2}>{t('reset_your_password')}</Title>
        <Text type="secondary" className={styles.description}>
          {t('create_a_password_and_login')}
        </Text>
      </AntFlex>

      <AntForm
        name="resetPassword"
        onFinish={handleSubmit(onSubmit)}
        autoComplete="off"
        layout="vertical"
      >
        <FormItem
          required
          name="password"
          label={t('new_password')}
          error={errors.password?.message}
        >
          <Controller
            control={control}
            name="password"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>

        <FormItem
          required
          name="confirmedPassword"
          label={t('confirm_new_password')}
          error={errors.confirmedPassword?.message}
        >
          <Controller
            control={control}
            name="confirmedPassword"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className={styles.button}
          loading={isPending}
          disabled={!token}
        >
          {t('actions.create_password')}
        </Button>
      </AntForm>
    </AntCard>
  );
};
