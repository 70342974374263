import { useUpdatePassword } from '@data/hooks/user';
import { UpdatePasswordSchema, type UpdatePasswordValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface SettingsDrawerProps extends DrawerProps {
  userId: string;
}

export const SettingsDrawer = ({ toggleIsOpen, userId, ...props }: SettingsDrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdatePasswordValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdatePasswordSchema),
  });

  const { mutate: updatePassword, isPending } = useUpdatePassword({
    onSuccess: () => {
      toggleIsOpen();
      toast(t('toast.password_updated'), { type: 'success' });
    },
  });

  const onSubmit = (values: UpdatePasswordValidator) => {
    const { password, oldPassword } = values;
    updatePassword({ id: userId, body: { password, oldPassword } });
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('actions.change_password')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending}
              disabled={!isValid}
              style={{ minWidth: 100 }}
              onClick={handleSubmit(onSubmit)}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="changePassword" layout="vertical">
        <FormItem name="oldPassword" label={t('current_password')} error={errors.password?.message}>
          <Controller
            control={control}
            name="oldPassword"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>

        <FormItem name="password" label={t('new_password')} error={errors.password?.message}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>

        <FormItem
          name="confirmedPassword"
          label={t('confirm_new_password')}
          error={errors.confirmedPassword?.message}
        >
          <Controller
            control={control}
            name="confirmedPassword"
            render={({ field }) => <Input type="password" {...field} />}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
