import { Tabs, type TabsProps } from '@app/components/Tabs';
import { OvoscanTabs } from '@app/constants';
import { AntFlex, AntLayout, AntTypography } from '@ui/components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from '../styles.module.css';
import { BoxesList } from './Boxes/BoxesList';
import { SensorsList } from './Sensors/SensorsList';
import { SetsList } from './Sets/SetsList';

export const Ovoscans = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { Header, Content } = AntLayout;
  const { Title } = AntTypography;

  const tabItems: TabsProps[] = [
    {
      key: OvoscanTabs.Sensors,
      label: t('sensors'),
    },
    {
      key: OvoscanTabs.Sets,
      label: t('sets'),
    },
    {
      key: OvoscanTabs.Boxes,
      label: t('boxes'),
    },
  ];

  const renderContent = () => {
    const tab = searchParams.get('tab') as OvoscanTabs;
    return {
      [OvoscanTabs.Sensors]: <SensorsList />,
      [OvoscanTabs.Sets]: <SetsList />,
      [OvoscanTabs.Boxes]: <BoxesList />,
    }[tab];
  };

  return (
    <AntLayout>
      <Header className={styles.headerWithTabs}>
        <AntFlex vertical flex={1}>
          <Title level={1} className={styles.headerTitle}>
            {t('ovoscans')}
          </Title>
          <Tabs items={tabItems} />
        </AntFlex>
      </Header>
      <Content className={styles.content}>{renderContent()}</Content>
    </AntLayout>
  );
};
