import { Tabs, type TabsProps } from '@app/components/Tabs';
import { CustomerTabs } from '@app/constants/enums';
import { useDrawer } from '@app/utils/hooks';
import { useDeleteCustomer } from '@data/hooks';
import type { CustomerDetailView } from '@sdk/api';
import { AntFlex, AntLayout, AntTypography, BackButton, DeleteButton, Modal } from '@ui/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../styles.module.css';

type CustomerDetailedHeaderProps = {
  customer?: CustomerDetailView;
  canDelete: boolean;
};

export const CustomerDetailedHeader = ({ customer, canDelete }: CustomerDetailedHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen: isDeleteModalOpen, toggleIsOpen: toggleDeleteModal } = useDrawer();

  const { mutateAsync: deleteCustomer } = useDeleteCustomer();

  const handleDelete = async () => {
    if (!customer) return;
    await deleteCustomer({ id: customer.id });
    navigate('..');
    toggleDeleteModal();
    toast(t('toast.customer_deleted'), { type: 'success' });
  };

  const { Header } = AntLayout;
  const { Title, Text } = AntTypography;

  const tabItems: TabsProps[] = [
    {
      key: CustomerTabs.Locations,
      label: t('locations'),
    },
    {
      key: CustomerTabs.Employees,
      label: t('employees'),
    },
    {
      key: CustomerTabs.Notifications,
      label: t('notifications'),
    },
    {
      key: CustomerTabs.GeneralInfo,
      label: t('general_info'),
    },
  ];

  return (
    <Header className={styles.headerWithTabs}>
      <AntFlex vertical flex={1}>
        <BackButton className={styles.backButton} />
        <AntFlex align="center" justify="space-between">
          <Title level={1} className={styles.headerTitle}>
            {customer?.name}
          </Title>
          {canDelete && <DeleteButton onClick={toggleDeleteModal} />}
        </AntFlex>
        <Tabs items={tabItems} />
      </AntFlex>
      <Modal
        title={t('delete_modal.title')}
        cancelText={t('delete_modal.no')}
        okText={t('delete_modal.yes')}
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={toggleDeleteModal}
        isDanger
      >
        <Text>{t('delete_modal.description_customer')}</Text>
      </Modal>
    </Header>
  );
};
