import { DateFormat, formatDate, formatNumber } from '@app/utils/helpers';
import { useDrawer, usePermissions } from '@app/utils/hooks';
import { useUpdateBatch } from '@data/hooks';
import { type BatchDetailView, BatchViewStatusEnum, type UpdateBatchBody } from '@sdk/api';
import {
  AntCard,
  AntFlex,
  AntGrid,
  AntSpace,
  AntTable,
  AntTypography,
  EditButton,
} from '@ui/components';
import { fontSize, fontWeight } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { EditBatchDetailsDrawer, EditBatchGeneralDrawer } from '../Drawers/EditBatch';

type DataSourceType = {
  key: string;
  value?: string;
};

const getBatchInfo = (batch?: BatchDetailView) => {
  if (!batch) return {};
  const location = batch.house?.customerLocation.location;
  return {
    hatchery: batch.hatchery?.name,
    customer: batch.house?.customerLocation.customer?.name,
    location: location ? `${location.address}, ${location.city}, ${location.country}` : undefined,
    house: batch.house?.name,
    contactPerson: batch.house?.customerLocation.customer?.contactPerson,
    monitoringStart: batch.monitoringStart,
    monitoringEnd: batch.monitoringEnd,
    status: batch.status,
  };
};

const getBatchDetails = (batch?: BatchDetailView) => {
  return batch
    ? {
        numberPlaced: batch.numberPlaced,
        expectedNonHatched: batch.expectedNonHatched,
        averageParentAge: batch.averageParentAge,
        breed: batch.breed?.name,
        parentFlockId: batch.parentFlockId,
        sensorSetId: batch.sensorSet?.name,
      }
    : {};
};

const translateKey = (key: string, t: TFunction) =>
  ({
    hatchery: t('hatchery'),
    customer: t('customer'),
    location: t('location'),
    house: t('house'),
    contactPerson: t('contact_person'),
    breed: t('breed'),
    monitoringStart: t('batch.monitoring_start'),
    monitoringEnd: t('batch.monitoring_end'),
    status: t('status'),
    numberPlaced: t('batch.number_of_eggs_placed'),
    expectedNonHatched: t('batch.expected_non_hatched_eggs'),
    averageParentAge: t('batch.average_age_parent_stock'),
    parentFlockId: t('batch.parent_stock_flock_id'),
    sensorSetId: t('batch.ovoscan_set'),
  })[key];

export const BatchInfo = ({ batch }: { batch?: BatchDetailView }) => {
  const { t } = useTranslation();
  const { isOpen: isGeneralOpen, toggleIsOpen: toggleIsGeneralOpen } = useDrawer();
  const { isOpen: isDetailsOpen, toggleIsOpen: toggleIsDetailsOpen } = useDrawer();
  const { Text, Title } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();
  const { isNestBornAdmin, getIsHatcheryAdmin } = usePermissions();

  const { mutateAsync: updateBatch, isPending } = useUpdateBatch();

  const onSubmit = async (values: UpdateBatchBody) => {
    if (!batch) return;
    await updateBatch({ id: batch.id, updateBatchBody: values });
    isGeneralOpen && toggleIsGeneralOpen();
    isDetailsOpen && toggleIsDetailsOpen();
  };

  const batchInfo = getBatchInfo(batch);
  const batchDetails = getBatchDetails(batch);

  const getDataSource = (obj: { [key: string]: string | number | undefined }): DataSourceType[] => {
    const getValue = (key: string, value: string | number | undefined) => {
      if (['monitoringStart', 'monitoringEnd'].includes(key)) {
        return formatDate(value as string, DateFormat.DateTime);
      }
      return typeof value === 'number' ? formatNumber(value) : value;
    };

    return Object.entries(obj).map(([key, value]) => ({
      key: translateKey(key, t) || key,
      value: getValue(key, value),
    }));
  };

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: xs ? 'unset' : '25%' },
    {
      render: (_, record) => (
        <Text style={{ fontWeight: fontWeight.semibold }}>{record.value}</Text>
      ),
    },
  ];

  const hatcheryId = batch?.hatchery?.id;
  const isAdmin = isNestBornAdmin || (!!hatcheryId && getIsHatcheryAdmin(hatcheryId));
  const isClosed = batch?.status === BatchViewStatusEnum.Closed;

  return (
    <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
      <AntCard bordered={false} style={{ width: '100%' }}>
        <AntFlex align="center" justify="space-between">
          <Title level={2} style={{ fontSize: fontSize.xl }}>
            {t('general')}
          </Title>
          {!isClosed && isAdmin && <EditButton onClick={toggleIsGeneralOpen} />}
        </AntFlex>

        <AntTable
          columns={columns}
          dataSource={getDataSource(batchInfo)}
          rowKey={(record) => record.key}
          pagination={false}
          showHeader={false}
        />

        {isAdmin && (
          <EditBatchGeneralDrawer
            open={isGeneralOpen}
            toggleIsOpen={toggleIsGeneralOpen}
            initialValues={{
              monitoringStart: batchInfo.monitoringStart,
              monitoringEnd: batchInfo.monitoringEnd,
            }}
            onSubmit={onSubmit}
            isPending={isPending}
          />
        )}
      </AntCard>

      <AntCard bordered={false} style={{ width: '100%' }}>
        <AntFlex align="center" justify="space-between">
          <Title level={2} style={{ fontSize: fontSize.xl }}>
            {t('batch.batch_details')}
          </Title>
          {!isClosed && isAdmin && <EditButton onClick={toggleIsDetailsOpen} />}
        </AntFlex>

        <AntTable
          columns={columns}
          dataSource={getDataSource(batchDetails)}
          rowKey={(record) => record.key}
          pagination={false}
          showHeader={false}
        />

        {isAdmin && (
          <EditBatchDetailsDrawer
            open={isDetailsOpen}
            toggleIsOpen={toggleIsDetailsOpen}
            initialValues={{
              ...batchDetails,
              breedId: batch?.breed?.id,
              sensorSetId: batch?.sensorSet?.id,
            }}
            onSubmit={onSubmit}
            isPending={isPending}
          />
        )}
      </AntCard>
    </AntSpace>
  );
};
