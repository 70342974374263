import { UpdateBatchGeneralSchema, type UpdateBatchGeneralValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import type { UpdateBatchBody } from '@sdk/api';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Button,
  DatePicker,
  Drawer,
  type DrawerProps,
  FormItem,
  TimePicker,
} from '@ui/components';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { toDateTimeISO } from '../../utils';

type InitialValues = { monitoringStart?: string; monitoringEnd?: string };
interface EditBatchGeneralDrawerProps extends DrawerProps {
  initialValues: InitialValues;
  onSubmit: (body: UpdateBatchBody) => Promise<void>;
  isPending?: boolean;
}

export const EditBatchGeneralDrawer = ({
  toggleIsOpen,
  initialValues,
  onSubmit,
  isPending,
  ...props
}: EditBatchGeneralDrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const submitFormatted = async (values: UpdateBatchGeneralValidator) => {
    const { startDate, startTime, endDate, endTime } = values;
    const monitoringStart = toDateTimeISO(startDate, startTime);
    const monitoringEnd = toDateTimeISO(endDate, endTime);
    await onSubmit({ monitoringStart, monitoringEnd });
  };

  const getInitValues = () => {
    const formatDate = (date?: string) => dayjs(date).toISOString();
    const { monitoringStart, monitoringEnd } = initialValues;
    return {
      startDate: formatDate(monitoringStart),
      startTime: formatDate(monitoringStart),
      endDate: formatDate(monitoringEnd),
      endTime: formatDate(monitoringEnd),
    };
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
  } = useForm<UpdateBatchGeneralValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateBatchGeneralSchema),
    values: getInitValues(),
  });

  const renderDayPicker = (
    name: keyof UpdateBatchGeneralValidator,
    Component: typeof DatePicker | typeof TimePicker,
  ) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Component
          showNow={false}
          needConfirm={false}
          disabledDate={(current) => {
            const startDate = getValues('startDate');
            return (
              field.name === 'endDate' && !!startDate && current.diff(dayjs(startDate), 'day') < 1
            );
          }}
          minuteStep={5}
          style={{ width: '100%' }}
          value={field.value ? dayjs(field.value) : null}
          onChange={(date) => field.onChange(date?.toISOString())}
        />
      )}
    />
  );

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
              loading={isPending}
              onClick={handleSubmit(submitFormatted)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateBatchGeneralInfo" layout="vertical">
        <FormItem
          required
          name="monitoringStart"
          label={t('batch.start_monitoring')}
          error={errors.startDate?.message || errors.startTime?.message}
        >
          <AntSpace style={{ width: '100%' }} styles={{ item: { flex: 1 } }}>
            {renderDayPicker('startDate', DatePicker)}
            {renderDayPicker('startTime', TimePicker)}
          </AntSpace>
        </FormItem>

        <FormItem
          required
          name="monitoringEnd"
          label={t('batch.end_monitoring')}
          error={errors.endDate?.message || errors.endTime?.message}
        >
          <AntSpace style={{ width: '100%' }} styles={{ item: { flex: 1 } }}>
            {renderDayPicker('endDate', DatePicker)}
            {renderDayPicker('endTime', TimePicker)}
          </AntSpace>
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
