import { getData, roleApi } from '@app/utils/api/api';
import { roleKeys } from '@data/constants';
import type {
  RoleApiDeleteRoleRequest,
  RoleApiGetRolesRequest,
  RoleApiReinviteRequest,
  RoleApiUpdateRoleRequest,
} from '@sdk/api';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetRoles = (params: RoleApiGetRolesRequest) =>
  useQuery({
    queryKey: roleKeys.list(params),
    queryFn: () => getData(roleApi.getRoles(params)),
  });

export const useUpdateRole = () =>
  useMutation({
    mutationFn: (params: RoleApiUpdateRoleRequest) => getData(roleApi.updateRole(params)),
  });

export const useDeleteRole = () =>
  useMutation({
    mutationFn: (params: RoleApiDeleteRoleRequest) => getData(roleApi.deleteRole(params)),
  });

export const useReinviteRole = () =>
  useMutation({
    mutationFn: (params: RoleApiReinviteRequest) => getData(roleApi.reinvite(params)),
  });
