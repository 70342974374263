import { batchApi, getData } from '@app/utils/api/api';
import { batchKeys } from '@data/constants';
import type {
  BatchApiCloseBatchRequest,
  BatchApiCreateBatchCommentRequest,
  BatchApiCreateBatchRequest,
  BatchApiGetBatchesRequest,
  BatchApiGetBreedsRequest,
  BatchApiUpdateBatchRequest,
} from '@sdk/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useGetBatches = (params: BatchApiGetBatchesRequest) =>
  useQuery({
    queryKey: batchKeys.list(params),
    queryFn: () => getData(batchApi.getBatches(params)),
  });

export const useGetBreeds = (params: BatchApiGetBreedsRequest) =>
  useQuery({
    queryKey: batchKeys.breed(params),
    queryFn: () => getData(batchApi.getBreeds(params)),
  });

export const useCreateBatch = () =>
  useMutation({
    mutationFn: (batchBody: BatchApiCreateBatchRequest) => getData(batchApi.createBatch(batchBody)),
  });

export const useGetBatchById = (id: string) =>
  useQuery({
    queryKey: batchKeys.detail(id),
    queryFn: () => getData(batchApi.getBatchById({ id })),
  });

export const useGetBatchEvents = (id: string) =>
  useQuery({
    queryKey: batchKeys.event(id),
    queryFn: () => getData(batchApi.getBatchEvents({ id })),
  });

export const useCreateBatchComment = () =>
  useMutation({
    mutationFn: ({ id, commentBody }: BatchApiCreateBatchCommentRequest) =>
      getData(batchApi.createBatchComment({ id, commentBody })),
  });

export const useUpdateBatch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, updateBatchBody }: BatchApiUpdateBatchRequest) =>
      getData(batchApi.updateBatch({ id, updateBatchBody })),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(batchKeys.detail(variables.id), data);
    },
  });
};

export const useGetBatchStats = (id: string) =>
  useQuery({
    queryKey: batchKeys.stats(id),
    queryFn: () => getData(batchApi.getBatchStats({ id })),
  });

export const usePrefetchBatchStats = (id: string): void => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  queryClient.prefetchQuery({
    queryKey: batchKeys.stats(id),
    queryFn: () => getData(batchApi.getBatchStats({ id })),
    staleTime: 60000,
  });
};

export const useCloseBatch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, closeBatchBody }: BatchApiCloseBatchRequest) =>
      getData(batchApi.closeBatch({ id, closeBatchBody })),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(batchKeys.detail(variables.id), data);
    },
  });
};

export const useGetLatestBatchEvents = () =>
  useQuery({
    queryKey: batchKeys.latestEvents(),
    queryFn: () => getData(batchApi.getLatestBatchEvents()),
  });
