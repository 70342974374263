import { getTimeRange } from '@app/utils/helpers/chart';
import { useMobile } from '@app/utils/hooks';
import { useGetBatchStats } from '@data/hooks';
import type { BatchDetailView } from '@sdk/api';
import { AntCard, AntSpace, Spinner } from '@ui/components';

import { AvgAirTemperatureChart } from './AvgAirTemperatureChart';
import { AvgEggTemperatureChart } from './AvgEggTemperatureChart';
import { AvgSensorDataChart } from './AvgSensorDataChart';
import { EggTemperatureCharts } from './EggTemperatureCharts';
import { HatchWindowChart } from './HatchWindowChart';

export const BatchCharts = ({ batch }: { batch: BatchDetailView }) => {
  const { isMobile } = useMobile();

  const { data: stats, isLoading } = useGetBatchStats(batch.id);
  const timeRange = getTimeRange(batch);

  const charts = [
    { id: 'AvgSensorDataChart', component: AvgSensorDataChart },
    { id: 'AvgAirTemperatureChart', component: AvgAirTemperatureChart },
    { id: 'AvgEggTemperatureChart', component: AvgEggTemperatureChart },
    { id: 'HatchWindowChart', component: HatchWindowChart },
  ];
  return (
    <AntSpace
      direction="vertical"
      size="large"
      align="center"
      style={{ width: '100%', padding: isMobile ? '20px 10px' : 0 }}
    >
      {charts.map(({ id, component: Chart }) => (
        <AntCard bordered={false} styles={{ body: { padding: 0 } }} key={id}>
          {isLoading && <Spinner />}
          <Chart stats={stats} timeRange={timeRange} />
        </AntCard>
      ))}
      <EggTemperatureCharts stats={stats} timeRange={timeRange} />
    </AntSpace>
  );
};
