import { getData, userApi } from '@app/utils/api/api';
import { userKeys } from '@data/constants';
import type {
  UpdateUserBody,
  UpdateUserPasswordBody,
  UserApiGetUsersRequest,
  UserView,
} from '@sdk/api';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetUsers = (params: UserApiGetUsersRequest) =>
  useQuery({
    queryKey: [userKeys.all(), params],
    queryFn: () => getData(userApi.getUsers(params)),
  });

export const useUpdateUser = ({ onSuccess }: { onSuccess: (user: UserView) => void }) =>
  useMutation({
    mutationFn: ({ id, body }: { id: string; body: UpdateUserBody }) =>
      getData(userApi.updateUser({ id, updateUserBody: body })),
    onSuccess,
  });

export const useUpdatePassword = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation({
    mutationFn: ({ id, body }: { id: string; body: UpdateUserPasswordBody }) =>
      getData(userApi.updatePassword({ id, updateUserPasswordBody: body })),
    onSuccess,
  });

export const useGetKpiUser = () =>
  useQuery({
    queryKey: [userKeys.kpi()],
    queryFn: () => getData(userApi.getKpiUser()),
  });
