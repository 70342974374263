import type { BatchApiGetBatchesRequest, CustomerView } from '@sdk/api';
import { Tag } from 'antd';

export const CustomerFilters = ({
  items,
  query,
  setQuery,
}: {
  items?: CustomerView[];
  query: BatchApiGetBatchesRequest;
  setQuery: (q: BatchApiGetBatchesRequest) => void;
}) => {
  return (
    <>
      {query.customerIds?.map((filterId) => (
        <Tag
          key={filterId}
          bordered={false}
          closable
          onClose={() =>
            setQuery({
              ...query,
              customerIds: query.customerIds?.filter((id) => id !== filterId),
            })
          }
        >
          {items?.find((h) => h.id === filterId)?.name}
        </Tag>
      ))}
    </>
  );
};
