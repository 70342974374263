import { colors } from '@ui/constants/style.constant';

const sharedInputProps = {
  colorPrimaryHover: colors.yellowGreen500,
  activeBorderColor: colors.yellowGreen500,
  activeShadow: `0px 0px 0px 2px #1890FF33`,
};

export const theme = {
  Button: {
    primaryShadow: 'none',
    primaryColor: colors.yellowGreen500,
    colorPrimaryHover: colors.teal500,
    colorPrimaryActive: colors.teal700,
    colorTextLightSolid: colors.yellowGreen500,
    colorLink: colors.teal700,
    colorLinkActive: colors.teal700,
    colorLinkHover: colors.teal700,
  },
  Checkbox: {
    colorWhite: colors.yellowGreen500,
  },
  Input: {
    ...sharedInputProps,
  },
  Select: {
    colorPrimary: colors.yellowGreen500,
    colorPrimaryHover: colors.yellowGreen500,
    optionActiveBg: colors.teal200,
    optionSelectedBg: colors.teal200,
    controlOutline: '#1890FF33',
  },
  Layout: {
    headerBg: colors.white,
    bodyBg: colors.teal200,
    siderBg: colors.teal700,
  },
  PublicLayout: {
    bodyBg: colors.teal200,
    footerBg: colors.teal200,
    headerBg: colors.teal200,
  },
  Card: {
    borderRadiusLG: 16,
    boxShadowTertiary: 'none',
  },
  Tag: { defaultBg: colors.grey100 },
  Switch: {
    colorPrimary: colors.yellowGreen500,
    colorPrimaryHover: colors.yellowGreen700,
  },
  Radio: {
    colorPrimary: colors.yellowGreen500,
  },
  Pagination: {
    itemActiveBg: 'none',
  },
  DatePicker: {
    ...sharedInputProps,
    colorPrimary: colors.yellowGreen500,
    cellHoverBg: colors.teal200,
    cellActiveWithRangeBg: colors.teal200,
  },
};
