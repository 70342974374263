import { changeLanguage, getLanguageOptions } from '@app/utils/helpers';
import { Language } from '@sdk/api';
import { AntConfigProvider, AntFlex, AntLayout, AntSpace, Select } from '@ui/components';
import { colors } from '@ui/constants';
import { theme } from '@ui/constants';
import { NestBornLogo } from '@ui/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useSearchParams } from 'react-router-dom';

import styles from './styles.module.css';

export const PublicLayout: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { Header, Footer, Content } = AntLayout;

  const [searchParams] = useSearchParams();
  const language = searchParams.get('lang');

  useEffect(() => {
    if (language && language !== i18n.language) {
      changeLanguage(language as Language, i18n);
    }
  }, [language, i18n]);

  return (
    <AntConfigProvider
      theme={{
        components: {
          Layout: theme.PublicLayout,
          Input: theme.Input,
        },
      }}
    >
      <AntLayout className={styles.layout}>
        <Header>
          <AntFlex justify="flex-end">
            <AntSpace>
              <Select
                variant="borderless"
                dropdownStyle={{ width: 120 }}
                options={getLanguageOptions()}
                value={i18n.language}
                onChange={(value: Language) => changeLanguage(value, i18n)}
              />
            </AntSpace>
          </AntFlex>
        </Header>
        <Content className={styles.publicContent}>
          <AntSpace direction="vertical" size={45}>
            <div className={styles.logoContainer}>
              <NestBornLogo />
            </div>
            <Outlet />
          </AntSpace>
        </Content>
        <Footer style={{ color: colors.grey200 }} className={styles.footer}>
          {t('copyright')}
        </Footer>
      </AntLayout>
    </AntConfigProvider>
  );
};
