import { changeLanguage, formatDate, formatNumber } from '@app/utils/helpers';
import { useMeasurementConversion } from '@app/utils/hooks';
import { useGetBatchById } from '@data/hooks';
import type { BatchDetailView, Language } from '@sdk/api';
import { AntConfigProvider, AntTable, AntTypography } from '@ui/components';
import { fontSize } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import { Image } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { getFloorPlans } from '../utils';
import { PageLayout } from './PageLayout';

type DataSourceType = {
  key: string;
  value?: string | number;
};

const BatchInfo = ({ batch }: { batch?: BatchDetailView }) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const language = searchParams.get('lang');

  useEffect(() => {
    language && changeLanguage(language as Language, i18n);
  }, [language, i18n]);

  if (!batch) return null;
  const data = {
    [t('hatchery')]: batch.hatchery?.name,
    [t('batch.ovoscan_set')]: batch.sensorSet?.name,
    [t('breed')]: batch.breed?.name,
    [t('batch.monitoring_start')]: batch.monitoringStart,
    [t('batch.monitoring_end')]: batch.monitoringEnd,
    [t('batch.number_of_eggs_placed')]: batch.numberPlaced,
    [t('batch.average_age_parent_stock')]: batch.averageParentAge,
    [t('batch.parent_stock_flock_id')]: batch.parentFlockId,
  };

  const dataSource: DataSourceType[] = Object.entries(data).map(([key, value]) => ({
    key,
    value: [t('batch.monitoring_start'), t('batch.monitoring_end')].includes(key)
      ? formatDate(value as string)
      : typeof value === 'number'
        ? formatNumber(value)
        : value,
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: '30%' },
    { render: (_, record) => record.value },
  ];

  return (
    <AntTable
      size="small"
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.key}
      pagination={false}
      showHeader={false}
    />
  );
};

const BatchInfo2 = ({ batch }: { batch?: BatchDetailView }) => {
  const { t } = useTranslation();
  const { areaMeasurementUnit, squareMetersToUserUnits } = useMeasurementConversion();

  if (!batch) return null;

  const getHouseSize = () =>
    batch.house?.size && formatNumber(squareMetersToUserUnits(batch.house.size));

  const { location } = batch.house?.customerLocation ?? {};
  const data = {
    [t('contact_person')]: batch.house?.customerLocation.customer?.contactPerson,
    [t('location')]: location
      ? `${location.postalCode}, ${location.address}, ${location.city}, ${location.country}`
      : undefined,
    [t('house')]: batch?.house?.name,
    [t('net_area_size', { unit: areaMeasurementUnit })]: getHouseSize(),
    [t('floor_plan')]: batch.floorPlan,
    [t('description')]: batch.house?.description,
  };

  const dataSource: DataSourceType[] = Object.entries(data).map(([key, value]) => ({
    key,
    value: value?.toString(),
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: '30%' },
    { render: (_, record) => record.value },
  ];

  return (
    <AntTable
      size="small"
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.key}
      pagination={false}
      showHeader={false}
    />
  );
};

const plans = getFloorPlans();

export const DeliveryDocument = () => {
  const { t } = useTranslation();
  const { batchId = '' } = useParams();
  const { data: batch } = useGetBatchById(batchId);

  const { Title } = AntTypography;
  const titleStyle = { fontSize: fontSize.xl, marginTop: 18 };

  return (
    <AntConfigProvider
      theme={{
        components: {
          Table: {
            cellPaddingBlockSM: 3,
            cellPaddingInlineSM: 3,
          },
        },
      }}
    >
      <PageLayout batch={batch} header={t('delivery_document')}>
        <Title style={titleStyle}>{t('batch_information')}</Title>
        <BatchInfo batch={batch} />
        <Title style={titleStyle}>{t('farm_location')}</Title>
        <BatchInfo2 batch={batch} />
        <Image
          width="100%"
          style={{ marginTop: 32 }}
          src={plans.find((plan) => plan.id === batch?.floorPlan)?.src}
          preview={false}
        />
      </PageLayout>
    </AntConfigProvider>
  );
};
