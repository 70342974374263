import { routes } from '@app/constants';
import { useForgotPassword } from '@data/hooks';
import { EmailSchema, type EmailValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { AntCard, AntFlex, AntForm, AntTypography, Button, FormItem, Input } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { Title, Text } = AntTypography;
  const navigate = useNavigate();

  const { mutate: forgotPassword, isPending } = useForgotPassword({
    onSuccess: () => navigate(`/${routes.forgotPasswordConfirmation}`),
  });

  const onSubmit = (values: EmailValidator) => forgotPassword(values.email);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailValidator>({
    mode: 'onBlur',
    resolver: zodResolver(EmailSchema),
  });

  return (
    <AntCard classNames={{ body: styles.card }}>
      <AntFlex vertical justify="center">
        <Title level={2}>{t('forgot_your_password.title')}</Title>
        <Text className={styles.description}>{t('forgot_your_password.enter_the_email')}</Text>
      </AntFlex>

      <AntForm
        name="forgotPassword"
        onFinish={handleSubmit(onSubmit)}
        autoComplete="off"
        layout="vertical"
      >
        <FormItem required name="email" label={t('email_address')} error={errors.email?.message}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => <Input type="email" {...field} />}
          />
        </FormItem>

        <Text className={styles.description}>{t('forgot_your_password.for_security_reasons')}</Text>

        <Button type="primary" htmlType="submit" className={styles.button} loading={isPending}>
          {t('actions.reset_password')}
        </Button>
      </AntForm>
    </AntCard>
  );
};
