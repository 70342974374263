import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { DateFormat, formatDate } from '@app/utils/helpers';
import { useDrawer, usePagination, useTable } from '@app/utils/hooks';
import { useGetGateways } from '@data/hooks/sensor';
import type { CustomerHatcheryView, SensorApiGetGatewaysRequest } from '@sdk/api';
import {
  AntCard,
  AntFlex,
  AntGrid,
  AntSpace,
  AntTable,
  EditButton,
  Input,
  MultilineTableCell,
} from '@ui/components';
import { colors } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';
import { EditBoxDrawer } from './EditBoxDrawer';

export type BoxDataType = {
  id: string;
  name?: string;
  code?: string;
  version?: string;
  hatcheries: CustomerHatcheryView[];
  lastOnline?: string;
};

export const BoxesList = () => {
  const { t } = useTranslation();
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<SensorApiGetGatewaysRequest>({
    limit: 10,
    offset: 0,
    search: undefined,
    sort: undefined,
    direction: undefined,
  });

  const { data } = useGetGateways(query);

  const { isOpen, toggleIsOpen } = useDrawer();
  const { handleSort, handleSearch } = useTable(query, setQuery);
  const pagination = usePagination(query, setQuery, data?.count);

  const [box, setBox] = useState<BoxDataType>();

  const dataSource = data?.items.map((item) => {
    const { id, name, code, version, hatcheries, lastOnline } = item;
    return {
      id,
      name,
      code,
      version,
      hatcheries,
      lastOnline,
    };
  });

  const renderLastOnline = (record: BoxDataType) => {
    const isOnline = dayjs().diff(dayjs(record.lastOnline), 'hour', true) <= 1;
    return (
      <AntSpace>
        {isOnline ? (
          <CheckCircleFilled style={{ color: colors.green600 }} />
        ) : (
          <CloseCircleFilled style={{ color: colors.red600 }} />
        )}
        {formatDate(record.lastOnline, DateFormat.DateTime)}
      </AntSpace>
    );
  };

  const columns: TableColumnsType<BoxDataType> = [
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('name'),
      }),
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('code'),
      }),
    },
    {
      title: t('version'),
      dataIndex: 'version',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('version'),
      }),
      align: 'center',
    },
    {
      title: t('hatchery'),
      render: (_, record) => record.hatcheries.map((hatchery) => hatchery.name).join(', '),
    },
    {
      title: t('last_online'),
      render: (_, record) => renderLastOnline(record),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('lastOnline'),
      }),
    },
    {
      render: (_, record) => (
        <EditButton
          onClick={() => {
            setBox(record);
            toggleIsOpen();
          }}
        />
      ),
      align: 'right',
    },
  ];

  const columnsMobile: TableColumnsType<BoxDataType> = [
    {
      render: (_, record) => (
        <AntSpace direction="vertical" size={0} style={{ width: '100%' }}>
          <AntFlex align="center" justify="space-between">
            {record.name}
            <EditButton
              onClick={() => {
                setBox(record);
                toggleIsOpen();
              }}
            />
          </AntFlex>
          <MultilineTableCell text={record.code} description={[record.version]} />
          {record.hatcheries.map((hatchery) => hatchery.name).join(', ')}
          {renderLastOnline(record)}
        </AntSpace>
      ),
    },
  ];

  return (
    <>
      <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
        <AntCard bordered={false} style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }}>
          <Input type="search" className={styles.input} onChange={handleSearch()} />
        </AntCard>
        <AntTable
          showHeader={!xs}
          columns={xs ? columnsMobile : columns}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          pagination={pagination}
          style={{ marginBottom: xs ? 100 : 0 }}
        />
      </AntSpace>
      {box && <EditBoxDrawer open={isOpen} toggleIsOpen={toggleIsOpen} box={box} query={query} />}
    </>
  );
};
