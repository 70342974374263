import { BatchIndicator } from '@app/constants';
import { formatNumber } from '@app/utils/helpers';

export const formatBatchValues = (indicator: BatchIndicator, value?: number) => {
  if (!value) return '';

  switch (indicator) {
    case BatchIndicator.CO2:
      return `${formatNumber(Math.round(value))}ppm`;
    case BatchIndicator.Humidity:
    case BatchIndicator.HatchedPercentage:
      return `${value?.toFixed(0)}%`;
  }
};
