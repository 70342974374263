import { routes } from '@app/constants';
import { changeLanguage } from '@app/utils/helpers';
import { useUserStore } from '@data/storage';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  const { user } = useUserStore();
  const { i18n } = useTranslation();

  if (!user) return <Navigate to={`/${routes.signIn}`} />;

  if (user.language && user.language !== i18n.language) {
    changeLanguage(user.language, i18n);
  }

  return <Outlet />;
};
