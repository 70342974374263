import { UserOutlined } from '@ant-design/icons';
import { colors, fontSize } from '@ui/constants';
import type { CardProps } from 'antd';

import { AntAvatar, AntCard, AntFlex, AntTypography } from '../../ui/components/index';

interface UserCardProps extends CardProps {
  user: {
    name?: string;
    email?: string;
  };
  icon?: JSX.Element;
  isDark?: boolean;
}

export const UserCard = ({ user, icon, isDark = false, ...props }: UserCardProps) => {
  const { Text } = AntTypography;

  return (
    <AntCard style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }} {...props}>
      <AntFlex>
        <AntAvatar
          style={{
            marginRight: 8,
            color: colors.grey300,
            backgroundColor: colors.teal200,
          }}
          shape="square"
          size={40}
          icon={icon || <UserOutlined />}
          alt="avatar"
        />
        <AntFlex vertical>
          <Text
            style={{
              fontSize: fontSize.lg,
              fontWeight: 600,
              color: isDark ? colors.white : colors.grey700,
            }}
          >
            {user.name}
          </Text>
          <Text
            style={{
              fontSize: fontSize.sm,
              lineHeight: 1.2,
              color: isDark ? '#FFFFFFA6' : colors.grey300,
            }}
          >
            {user.email}
          </Text>
        </AntFlex>
      </AntFlex>
    </AntCard>
  );
};
