import { colors } from '@ui/constants';
import { Modal as AntModal, type ModalProps as AntModalProps } from 'antd';

interface ModalProps extends AntModalProps {
  isDanger?: boolean;
}

export const Modal = ({ isDanger = false, children, ...props }: ModalProps) => (
  <AntModal
    width={412}
    styles={{ body: { marginTop: 22, height: 110 } }}
    okButtonProps={{
      danger: isDanger,
      style: isDanger ? { color: colors.white } : undefined,
    }}
    {...props}
  >
    {children}
  </AntModal>
);
