import { BatchTabs } from '@app/constants/enums';
import { useMobile } from '@app/utils/hooks';
import { useGetBatchById } from '@data/hooks';
import { AntLayout, Spinner } from '@ui/components';
import { useParams, useSearchParams } from 'react-router-dom';

import styles from '../styles.module.css';
import { BatchDetailedHeader } from './BatchDetailedHeader';
import { BatchActivity, BatchCharts, BatchInfo, BatchStatus } from './Tabs';

export const BatchDetailed = () => {
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');
  const { isMobile } = useMobile();
  const { batchId } = useParams();
  const { data: batch } = useGetBatchById(batchId || '');

  const { Content } = AntLayout;

  const renderContent = () => {
    if (!selectedTab) return;
    return batch ? (
      {
        [BatchTabs.Charts]: <BatchCharts batch={batch} />,
        [BatchTabs.Activity]: <BatchActivity batch={batch} />,
        [BatchTabs.Status]: <BatchStatus batch={batch} />,
        [BatchTabs.GeneralInfo]: <BatchInfo batch={batch} />,
      }[selectedTab]
    ) : (
      <Spinner />
    );
  };

  return isMobile && selectedTab === BatchTabs.Charts ? (
    <AntLayout>
      <Content className={styles.contentMobile}>
        <BatchDetailedHeader batch={batch} />
        {renderContent()}
      </Content>
    </AntLayout>
  ) : (
    <AntLayout>
      <BatchDetailedHeader batch={batch} />
      <Content className={styles.content}>{renderContent()}</Content>
    </AntLayout>
  );
};
