import { CheckOutlined } from '@ant-design/icons';
import { AntLayout, AntTypography } from '@ui/components';
import { Button } from '@ui/components';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';

type SettingsHeaderProps = {
  isLoading: boolean;
  isDisabled: boolean;
  handleSubmit: () => void;
};

export const SettingsHeader = ({ isLoading, isDisabled, handleSubmit }: SettingsHeaderProps) => {
  const { t } = useTranslation();
  const { Title } = AntTypography;
  const { Header } = AntLayout;

  return (
    <Header className={styles.header}>
      <Title level={1} className={styles.headerTitle}>
        {t('settings')}
      </Title>
      <Button
        type="primary"
        htmlType="submit"
        icon={<CheckOutlined />}
        loading={isLoading}
        disabled={isDisabled}
        onClick={handleSubmit}
      >
        {t('actions.save')}
      </Button>
    </Header>
  );
};
