import { AntCard, AntTypography } from '@ui/components';
import { fontSize } from '@ui/constants';
import { useTranslation } from 'react-i18next';

export const EmptyChartCover = () => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  return (
    <AntCard
      bordered={false}
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        opacity: 0.5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text style={{ fontSize: fontSize.xxl }}>{t('no_data')}</Text>
    </AntCard>
  );
};
