import { AntSpace, AntTypography } from '@ui/components';
import { colors, fontSize } from '@ui/constants';

type TableCellProps = {
  text?: string;
  description?: Array<string | JSX.Element | undefined>;
  space?: number;
};

export const MultilineTableCell = ({ text, description, space = 0 }: TableCellProps) => {
  const { Text } = AntTypography;
  return (
    <AntSpace direction="vertical" size={space}>
      <Text>{text}</Text>
      {description?.map((line, i) => (
        <Text key={i} style={{ fontSize: fontSize.sm, color: colors.grey500 }}>
          {line}
        </Text>
      ))}
    </AntSpace>
  );
};
