import { filterOption } from '@app/utils/helpers';
import { sensorKeys } from '@data/constants';
import { useGetHatcheries } from '@data/hooks';
import { useUpdateGateway } from '@data/hooks/sensor';
import { UpdateGatewaySchema, type UpdateGatewayValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { type SensorApiGetSensorsRequest } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { BoxDataType } from './BoxesList';

interface EditBoxDrawerProps extends DrawerProps {
  box: BoxDataType;
  query: SensorApiGetSensorsRequest;
}

export const EditBoxDrawer = ({ toggleIsOpen, box, query, ...props }: EditBoxDrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateGatewayValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateGatewaySchema),
    values: {
      name: box?.name ?? '',
      code: box?.code ?? '',
      hatcheryIds: box?.hatcheries.map((h) => h.id),
    },
  });

  const { data: hatcheries } = useGetHatcheries({ limit: 1000, offset: 0 });

  const { mutateAsync: updateGateway, isPending } = useUpdateGateway();
  const queryClient = useQueryClient();

  const onSubmit = async (values: UpdateGatewayValidator) => {
    if (!box) return;
    const { name, code, hatcheryIds } = values;
    await updateGateway({
      id: box.id,
      updateGatewayBody: { name, code, hatcheryIds },
    });
    await queryClient.invalidateQueries({ queryKey: sensorKeys.gatewaysList(query) });
    toggleIsOpen();
    toast(t('toast.item_updated', { item: t('box') }), { type: 'success' });
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit_box')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              loading={isPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateBox" layout="vertical">
        <FormItem required name="name" label={t('name')} error={errors.name?.message}>
          <Controller control={control} name="name" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem required name="code" label={t('code')} error={errors.code?.message}>
          <Controller control={control} name="code" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem
          required
          name="hatcheryIds"
          label={t('select_hatchery')}
          error={errors.hatcheryIds?.message}
        >
          <Controller
            control={control}
            name="hatcheryIds"
            render={({ field }) => (
              <Select
                showSearch
                mode="multiple"
                filterOption={filterOption}
                options={hatcheries?.items.map((h) => ({ value: h.id, label: h.name }))}
                {...field}
              />
            )}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
