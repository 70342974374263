import { formatDate } from '@app/utils/helpers';
import type { BatchDetailView } from '@sdk/api';
import { AntFlex, AntSpace, AntTypography } from '@ui/components';
import { colors, fontSize, fontWeight } from '@ui/constants';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { default as NestBornLogo } from '../../../../../public/assets/nestborn-logo.svg?react';
import styles from './styles.module.css';

export const PageLayout = ({
  batch,
  children,
  header,
}: {
  batch?: BatchDetailView;
  children: React.ReactNode;
  header: string;
}) => {
  const { t } = useTranslation();
  const { Title, Text } = AntTypography;

  const dividerStyle = { borderBlockStart: `2px solid ${colors.yellowGreen500}`, marginBottom: 8 };

  const Header = () => (
    <>
      <AntSpace size="middle">
        <NestBornLogo height={80} width={80} />
        <AntSpace direction="vertical" size={0}>
          <Title
            style={{
              fontWeight: fontWeight.regular,
              fontSize: fontSize.xxl,
              textAlign: 'start',
              margin: 0,
            }}
          >
            {header}
          </Title>
          <Text style={{ fontWeight: fontWeight.regular, fontSize: fontSize.lg }}>
            {t('batch_report_header', {
              date: formatDate(batch?.monitoringStart),
              houseName: batch?.house?.name,
              customerName: batch?.house?.customerLocation.customer?.name,
            })}
          </Text>
        </AntSpace>
      </AntSpace>
      <Divider style={dividerStyle} />
    </>
  );

  const Footer = () => (
    <AntFlex className={styles.footer} vertical align="center">
      <Divider style={dividerStyle} />
      <Text style={{ fontWeight: fontWeight.medium, fontSize: fontSize.sm }}>NestBorn</Text>
      <Text style={{ fontSize: fontSize.sm }}>HFHC nv | Nerm 94 | 3320 Hoegaarden | Belgium</Text>
      <Text style={{ fontSize: fontSize.sm }}>info@nestborn.eu - www.nestborn.eu</Text>
    </AntFlex>
  );

  return (
    <div className={styles.sheet}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
