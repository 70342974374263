import { useMobile } from '@app/utils/hooks';
import { colors, fontFamily, sidebarWidth } from '@ui/constants';

export const useChart = (chartTitle: string, range: [string, string], isPrintable = false) => {
  const { isMobile, isLandscape } = useMobile();

  const width = isPrintable
    ? 750
    : isMobile
      ? window.innerWidth - 20
      : Math.min(window.innerWidth - sidebarWidth - 40, 1100);

  const height = isPrintable ? 400 : 'auto';

  const getLegendY = () => {
    if (isMobile) return isLandscape ? -0.2 : -0.35;
    return isPrintable ? -0.35 : 'auto';
  };

  const paper_bgcolor = 'transparent';
  const font = { family: fontFamily, size: isLandscape ? 12 : 10, color: colors.grey700 };
  const title = {
    text: chartTitle,
    font: { size: 20 },
    pad: { l: 30 },
    x: 0,
  };
  const xaxis = {
    range,
    tickformat: '%H:%M\n%d %b',
    tickmode: 'linear',
    tick0: range[0],
    dtick: (isLandscape ? 6 : 12) * 60 * 60 * 1000,
    showline: true,
    type: 'date',
    ticks: 'outside',
    linecolor: colors.grey200,
    tickcolor: colors.grey200,
    tickangle: -90,
    griddash: 'dot',
  };
  const yaxis = {
    griddash: 'dot',
    fixedrange: isMobile,
    zeroline: false,
  };
  const legend = {
    traceorder: 'normal',
    orientation: isMobile || isPrintable ? 'h' : 'v',
    y: getLegendY(),
  };

  const hovermode = 'x unified';
  const hoverdistance = 1;

  const config = {
    displaylogo: false,
    staticPlot: isPrintable,
  };

  return {
    layout: {
      font,
      title,
      xaxis,
      yaxis,
      legend,
      hovermode,
      hoverdistance,
      width,
      height,
      paper_bgcolor,
    },
    config,
  };
};
