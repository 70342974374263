import { PlusOutlined } from '@ant-design/icons';
import { useDrawer, useMeasurementConversion } from '@app/utils/hooks';
import { customerKeys } from '@data/constants';
import { useDeleteCustomerLocation, useDeleteHouse } from '@data/hooks';
import type { CustomerDetailView, CustomerLocationView, HouseView, LocationView } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntCard,
  AntFlex,
  AntGrid,
  AntSpace,
  AntTable,
  AntTypography,
  Button,
  DeleteButton,
  EditButton,
  Modal,
} from '@ui/components';
import { Empty } from '@ui/components';
import type { TableColumnsType } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { AddLocationDrawer, EditHouseDrawer, EditLocationDrawer } from '../Drawers';
import { AddHouseDrawer } from '../Drawers/AddHouse';
import styles from './styles.module.css';

type CustomerLocationsProps = {
  customer?: CustomerDetailView;
  isAdmin: boolean;
};

export type HouseDataType = {
  customerLocationId: string;
  house: HouseView;
};

export type LocationDataType = {
  customerLocationName?: string;
  customerLocationId: string;
  location: LocationView;
};

export const CustomerLocations = ({ customer, isAdmin }: CustomerLocationsProps) => {
  const { Text } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();
  const queryClient = useQueryClient();

  const [customerLocationId, setCustomerLocationId] = useState<string>();
  const [locationData, setLocationData] = useState<LocationDataType>();
  const [houseData, setHouseData] = useState<HouseDataType>();

  const { isOpen: isAddLocationOpen, toggleIsOpen: toggleAddLocation } = useDrawer();
  const { isOpen: isEditLocationOpen, toggleIsOpen: toggleEditLocation } = useDrawer();
  const { isOpen: isDeleteLocationModalOpen, toggleIsOpen: toggleDeleteLocationModal } =
    useDrawer();
  const { isOpen: isAddHouseOpen, toggleIsOpen: toggleAddHouse } = useDrawer();
  const { isOpen: isEditHouseOpen, toggleIsOpen: toggleEditHouse } = useDrawer();
  const { isOpen: isDeleteHouseModalOpen, toggleIsOpen: toggleDeleteHouseModal } = useDrawer();

  const { mutateAsync: deleteHouse } = useDeleteHouse();
  const { mutateAsync: deleteCustomerLocation } = useDeleteCustomerLocation();

  const { areaMeasurementUnit, squareMetersToUserUnits } = useMeasurementConversion();

  const customerId = customer?.id || '';

  const invalidateCustomerQuery = () =>
    queryClient.invalidateQueries({ queryKey: customerKeys.detail(customerId) });

  const handleEditLocationClick = (locationData: LocationDataType) => {
    setLocationData(locationData);
    toggleEditLocation();
  };

  const handleDeleteLocationClick = (id: string) => {
    setCustomerLocationId(id);
    toggleDeleteLocationModal();
  };

  const handleDeleteLocation = async () => {
    if (!customerLocationId) return;
    await deleteCustomerLocation({ id: customerId, customerLocationId });
    await invalidateCustomerQuery();
    toggleDeleteLocationModal();
    toast(t('toast.item_deleted', { item: t('location') }), { type: 'success' });
  };

  const handleAddHouseClick = (id: string) => {
    setCustomerLocationId(id);
    toggleAddHouse();
  };

  const handleEditHouseClick = (houseData: HouseDataType) => {
    setHouseData(houseData);
    toggleEditHouse();
  };

  const handleDeleteHouseClick = (houseData: HouseDataType) => {
    setHouseData(houseData);
    toggleDeleteHouseModal();
  };

  const handleDeleteHouse = async () => {
    if (!houseData) return;
    const { customerLocationId, house } = houseData;
    await deleteHouse({ id: customerId, customerLocationId, houseId: house.id });
    await invalidateCustomerQuery();
    toggleDeleteHouseModal();
    toast(t('toast.item_deleted', { item: houseData.house.name }), { type: 'success' });
  };

  const columns: TableColumnsType<HouseDataType> = [
    { render: (_, record) => record.house.name, width: '25%' },
    {
      render: (_, record) =>
        record.house.size.length > 0 && (
          <Text>{`${record.house.size} ${areaMeasurementUnit}`}</Text>
        ),
      width: '15%',
    },
    { render: (_, record) => record.house.description },
    {
      align: 'right',
      className: styles.buttonsCol,
      render: (_, record) =>
        isAdmin ? (
          <AntSpace>
            <EditButton onClick={() => handleEditHouseClick(record)} />
            <DeleteButton onClick={() => handleDeleteHouseClick(record)} />
          </AntSpace>
        ) : null,
    },
  ];

  const columnsMobile: TableColumnsType<HouseDataType> = [
    {
      className: styles.buttonsColMobile,
      render: (_, record) => (
        <AntSpace direction="vertical" size={6} style={{ width: '100%' }}>
          <AntFlex align="center" justify="space-between">
            {record.house.name}
            {isAdmin && (
              <AntSpace>
                <EditButton onClick={() => handleEditHouseClick(record)} />
                <DeleteButton onClick={() => handleDeleteHouseClick(record)} />
              </AntSpace>
            )}
          </AntFlex>
          {record.house.size.length > 0 && (
            <Text>{`${record.house.size} ${areaMeasurementUnit}`}</Text>
          )}
          {record.house.description}
        </AntSpace>
      ),
    },
  ];

  const renderHeaderMobile = (customerLocation: CustomerLocationView) => {
    const { id: customerLocationId, name: customerLocationName, location } = customerLocation;
    return (
      <>
        <AntFlex align="center" justify="space-between">
          <Text>{customerLocationName}</Text>

          {isAdmin && (
            <AntSpace>
              <EditButton
                onClick={() => {
                  handleEditLocationClick({
                    customerLocationName,
                    customerLocationId,
                    location,
                  });
                }}
              />
              <DeleteButton onClick={() => handleDeleteLocationClick(customerLocationId)} />
            </AntSpace>
          )}
        </AntFlex>

        <Text>{`${location.address}, ${location.city}, ${location.postalCode}, ${location.country}`}</Text>

        {isAdmin && (
          <Button
            icon={<PlusOutlined />}
            onClick={() => handleAddHouseClick(customerLocationId)}
            style={{ width: '100%' }}
          >
            {t('new_house')}
          </Button>
        )}
      </>
    );
  };

  const renderHeaderWeb = (customerLocation: CustomerLocationView) => {
    const { id: customerLocationId, name: customerLocationName, location } = customerLocation;
    return (
      <AntFlex justify="space-between">
        <AntSpace>
          <Text strong>{customerLocationName}</Text>
          <Text>{`${location.address}, ${location.city}, ${location.postalCode}, ${location.country}`}</Text>
        </AntSpace>

        {isAdmin && (
          <AntSpace>
            <Button
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => handleAddHouseClick(customerLocationId)}
            >
              {t('new_house')}
            </Button>

            <EditButton
              onClick={() => {
                handleEditLocationClick({
                  customerLocationName,
                  customerLocationId,
                  location,
                });
              }}
            />

            <DeleteButton onClick={() => handleDeleteLocationClick(customerLocationId)} />
          </AntSpace>
        )}
      </AntFlex>
    );
  };

  return (
    <AntSpace
      direction="vertical"
      size="large"
      style={{ width: '100%', marginBottom: xs ? 100 : 0 }}
    >
      {isAdmin && (
        <AntFlex justify="flex-end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleAddLocation}
            style={{ width: xs ? '100%' : 'unset' }}
          >
            {t('new_location')}
          </Button>
        </AntFlex>
      )}

      {customer?.customerLocations?.length ? (
        customer.customerLocations.map((item) => (
          <AntCard key={item.location.id} bordered={false}>
            <AntSpace direction="vertical" size="middle" style={{ width: '100%' }}>
              {xs ? renderHeaderMobile(item) : renderHeaderWeb(item)}

              <AntTable
                columns={xs ? columnsMobile : columns}
                dataSource={item.houses
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((house) => ({
                    customerLocationId: item.id,
                    house: { ...house, size: squareMetersToUserUnits(house.size) },
                  }))}
                rowKey={(record) => record.house.id}
                pagination={false}
                showHeader={false}
              />
            </AntSpace>
          </AntCard>
        ))
      ) : (
        <Empty
          description={t('empty.no_locations')}
          buttonLabel={t('actions.create_now')}
          onClick={toggleAddLocation}
        />
      )}

      <AddLocationDrawer
        open={isAddLocationOpen}
        toggleIsOpen={toggleAddLocation}
        customer={customer}
      />
      {!!locationData && (
        <EditLocationDrawer
          open={isEditLocationOpen}
          toggleIsOpen={toggleEditLocation}
          locationData={locationData}
        />
      )}
      {!!houseData && (
        <EditHouseDrawer
          open={isEditHouseOpen}
          toggleIsOpen={toggleEditHouse}
          houseData={houseData}
        />
      )}

      <AddHouseDrawer
        open={isAddHouseOpen}
        toggleIsOpen={toggleAddHouse}
        customerLocationId={customerLocationId}
      />

      <Modal
        title={t('delete_modal.title')}
        cancelText={t('delete_modal.no')}
        okText={t('delete_modal.yes')}
        open={isDeleteLocationModalOpen}
        onOk={handleDeleteLocation}
        onCancel={toggleDeleteLocationModal}
        isDanger
      >
        <Text>{t('delete_modal.generic_description', { item: t('location') })}</Text>
      </Modal>

      <Modal
        title={t('delete_modal.title')}
        cancelText={t('delete_modal.no')}
        okText={t('delete_modal.yes')}
        open={isDeleteHouseModalOpen}
        onOk={handleDeleteHouse}
        onCancel={toggleDeleteHouseModal}
        isDanger
      >
        <Text>{t('delete_modal.generic_description', { item: houseData?.house.name })}</Text>
      </Modal>
    </AntSpace>
  );
};
