import { colors } from '@ui/constants';
import { Back, Create, Delete, Email } from '@ui/icons';
import { Button as AntButton, type ButtonProps as AntButtonProps, Grid as AntGrid } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ButtonProps extends AntButtonProps {
  iconRight?: JSX.Element;
}

export const Button = ({ iconRight, children, size = 'large', ...props }: ButtonProps) => {
  return (
    <AntButton size={size} {...props}>
      {children}
      {iconRight && <span style={{ marginInlineStart: '8px', lineHeight: 0 }}>{iconRight}</span>}
    </AntButton>
  );
};

const iconButtonStyles = {
  border: 'none',
  boxShadow: 'none',
  color: colors.grey300,
  background: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const iconProps = {
  height: 18,
  width: 18,
};

export const IconButton = ({ icon, ...props }: AntButtonProps) => (
  <Button shape="circle" icon={icon} style={iconButtonStyles} {...props} />
);

export const EditButton = (props: AntButtonProps) => (
  <Button
    shape="circle"
    icon={<Create {...iconProps} />}
    style={iconButtonStyles}
    aria-label="edit"
    {...props}
  />
);

export const InviteButton = (props: AntButtonProps) => (
  <Button
    shape="circle"
    icon={<Email {...iconProps} />}
    style={iconButtonStyles}
    aria-label="mail"
    {...props}
  />
);

export const DeleteButton = (props: AntButtonProps) => (
  <Button
    shape="circle"
    icon={<Delete {...iconProps} />}
    style={iconButtonStyles}
    aria-label="delete"
    {...props}
  />
);

export const BackButton = (props: AntButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  return (
    <AntButton
      size={xs ? 'large' : 'small'}
      icon={<Back />}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: xs ? 16 : 14,
        width: 'fit-content',
      }}
      onClick={() => navigate('..')}
      {...props}
    >
      {t('actions.back')}
    </AntButton>
  );
};
