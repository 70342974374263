import { filterOption } from '@app/utils/helpers';
import { useGetHatcheries, useUpdateCustomer } from '@data/hooks';
import { UpdateCustomerSchema, type UpdateCustomerValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface EditCustomerDrawerProps extends DrawerProps {
  initialValues: UpdateCustomerValidator;
}

export const EditCustomerDrawer = ({
  toggleIsOpen,
  initialValues,
  ...props
}: EditCustomerDrawerProps) => {
  const { t } = useTranslation();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UpdateCustomerValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateCustomerSchema),
    values: initialValues,
  });

  const { data: hatcheries } = useGetHatcheries({
    limit: 1000, // aligned with stakeholders
    offset: 0,
  });

  const { mutateAsync: updateCustomer, isPending: isUpdateCustomerPending } = useUpdateCustomer();

  const { customerId = '' } = useParams();
  const onSubmit = async (values: UpdateCustomerValidator) => {
    const { name, hatcheryIds, externalId } = values;
    await updateCustomer({
      id: customerId,
      updateCustomerBody: {
        name,
        hatcheryIds,
        externalId,
      },
    });
    toggleIsOpen();
    toast(t('toast.item_updated', { item: t('customer') }), { type: 'success' });
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
              loading={isUpdateCustomerPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateCustomer" layout="vertical">
        <FormItem name="name" label={t('hatchery_name')} error={errors.name?.message}>
          <Controller control={control} name="name" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem
          name="hatcheryIds"
          label={t('select_hatchery')}
          error={errors.hatcheryIds?.message}
        >
          <Controller
            control={control}
            name="hatcheryIds"
            render={({ field }) => (
              <Select
                showSearch
                mode="multiple"
                filterOption={filterOption}
                options={hatcheries?.items.map((h) => ({ value: h.id, label: h.name }))}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem name="externalId" label={t('external_id')} error={errors.externalId?.message}>
          <Controller
            control={control}
            name="externalId"
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
