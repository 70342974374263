import { routes } from '@app/constants';
import type { Token } from '@app/utils/types';
import { useAuthStore, useUserStore } from '@data/storage';
import type { LoginView } from '@sdk/api';
import {
  AxiosError,
  type AxiosInstance,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

import { authApi, getData } from './api';

export type IError = {
  error: string;
  message: string;
  statusCode: number;
};

const authEndpoints = [
  /\/auth\/login/,
  /\/auth\/refresh/,
  /\/auth\/set-password/,
  /\/invites\/confirm/,
];

const isRefreshUrl = (url?: string): boolean => /\/auth\/refresh/.test(url || '');

const isAuthEndpoint = (responseURL: string): boolean =>
  !!authEndpoints.find((endpoint) => endpoint.test(responseURL));

const onRequest = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const newConfig = { ...config };

  const { auth } = useAuthStore.getState();

  if (auth?.jwtToken) {
    const { exp } = jwtDecode<Token>(auth.jwtToken);
    const tokenHasExpired = new Date().getTime() / 1000 > Number(exp) - 5;

    if (tokenHasExpired) {
      if (auth?.refreshToken) {
        if (!isRefreshUrl(config.url)) {
          const { jwtToken: newToken } = await getData(
            authApi.refresh({ refreshBody: { value: auth.refreshToken } }),
          );
          newConfig.headers.Authorization = `Bearer ${newToken}`;
        }
      } else {
        localStorage.clear();
        window.location.reload();
        return config;
      }
    }
    if (!tokenHasExpired && newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${auth.jwtToken}`;
    }
  }

  return newConfig;
};

const onResponse = (res: AxiosResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, request } = res;

  if (isAuthEndpoint((request as XMLHttpRequest).responseURL)) {
    const { jwtToken, refreshToken, user } = data as LoginView;

    useAuthStore.setState({ auth: { jwtToken, refreshToken } });
    useUserStore.setState({ user });
  }

  return res;
};

const onResponseError = (error: AxiosError<IError>): Promise<AxiosError> => {
  if (error?.response) {
    const {
      response: { data: errorData, request },
    } = error;
    const url = new URL(window.location.href);

    if (errorData) {
      if (errorData.message === 'invalidToken') {
        if (isRefreshUrl((request as XMLHttpRequest).responseURL)) {
          localStorage.clear();
          window.location.reload();
        } else if (url.pathname.includes(routes.signUp)) {
          window.location.href = routes.expiredInvitation;
        }
      } else {
        toast(errorData.error || errorData.message, { type: 'error' });
      }
      return Promise.reject(error);
    }
  }

  return Promise.reject(new Error('Something went wrong'));
};

function axiosInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

export default axiosInterceptors;
