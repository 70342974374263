import { BatchIndicator } from '@app/constants/enums';
import { getFormattedTemperature } from '@app/utils/helpers';
import { useUserStore } from '@data/storage';
import { AntProgress, AntSpace, AntTypography } from '@ui/components';
import { colors, fontSize } from '@ui/constants';
import {
  AqIndoor,
  EggFilled,
  EggHatched,
  HumidityPercentage,
  StatusCO2,
  Thermostat,
} from '@ui/icons';
import { useTranslation } from 'react-i18next';

import { formatBatchValues } from '../../utils';
import styles from './styles.module.css';

type IndicatorProps = {
  type: BatchIndicator;
  value?: number;
};

export const Indicator = ({ type, value }: IndicatorProps) => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const { Text } = AntTypography;

  const indicators = {
    [BatchIndicator.HatchedPercentage]: {
      icon: <EggHatched />,
      title: t('indicators.hatched_eggs'),
      getFormattedValue: () => formatBatchValues(BatchIndicator.HatchedPercentage, value),
    },
    [BatchIndicator.AirTemperature]: {
      icon: <Thermostat />,
      title: t('indicators.sensor_air_temp'),
      getFormattedValue: () => getFormattedTemperature(value, user?.airTempScale),
    },
    [BatchIndicator.AverageOvoscanAirTemperature]: {
      icon: <AqIndoor />,
      title: t('indicators.avg_ovoscan_air_temp'),
      getFormattedValue: () => getFormattedTemperature(value, user?.airTempScale),
    },
    [BatchIndicator.CO2]: {
      icon: <StatusCO2 />,
      title: t('indicators.co2'),
      getFormattedValue: () => formatBatchValues(BatchIndicator.CO2, value),
    },
    averageEggTemperature: {
      icon: <EggFilled />,
      title: t('indicators.avg_egg_shell_temp'),
      getFormattedValue: () => getFormattedTemperature(value, user?.eggTempScale),
    },
    [BatchIndicator.Humidity]: {
      icon: <HumidityPercentage />,
      title: t('indicators.relative_humidity'),
      getFormattedValue: () => formatBatchValues(BatchIndicator.Humidity, value),
    },
  };

  const IndicatorValue = () => (
    <AntSpace direction="vertical" size={0}>
      <Text type="secondary" style={{ fontSize: fontSize.sm }}>
        {indicators[type].title}
      </Text>
      <Text strong style={{ fontSize: fontSize.xxl, lineHeight: 1 }}>
        {indicators[type].getFormattedValue()}
      </Text>
    </AntSpace>
  );

  if (type === BatchIndicator.HatchedPercentage) {
    return (
      <AntSpace>
        <AntProgress
          type="circle"
          size={45}
          percent={value}
          strokeColor={colors.green600}
          format={() => indicators[type].icon}
        />
        <IndicatorValue />
      </AntSpace>
    );
  }

  return (
    Number.isFinite(value) && (
      <AntSpace>
        <div className={styles.iconContainer}>{indicators[type].icon}</div>
        <IndicatorValue />
      </AntSpace>
    )
  );
};
