import { PlusOutlined } from '@ant-design/icons';
import { AddEmployeeDrawer } from '@app/pages/shared';
import { debounce } from '@app/utils/helpers';
import { useDrawer, usePagination, usePermissions } from '@app/utils/hooks';
import { roleKeys } from '@data/constants';
import { useDeleteRole, useGetRoles, useReinviteRole, useUpdateRole } from '@data/hooks';
import {
  type CustomerDetailView,
  type RoleApiGetRolesRequest,
  RoleType,
  RoleViewStatusEnum,
  RoleViewTypeEnum,
} from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntFlex,
  AntGrid,
  AntSpace,
  AntSwitch,
  AntTable,
  AntTag,
  AntTypography,
  Button,
  DeleteButton,
  Input,
  InviteButton,
  Modal,
} from '@ui/components';
import { type TableColumnsType } from 'antd';
import { type ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './styles.module.css';

type DataType = {
  roleId: string;
  userId: string;
  fullName?: string;
  email: string;
  mobilePhone?: string;
  status?: RoleViewStatusEnum;
  isEmployeeAdmin: boolean;
  isContactPerson?: boolean;
  type: RoleViewTypeEnum;
};

export const Employees = ({ customer }: { customer?: CustomerDetailView }) => {
  const { t } = useTranslation();
  const { customerId, hatcheryId } = useParams();
  const queryClient = useQueryClient();
  const { Text } = AntTypography;

  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<RoleApiGetRolesRequest>({
    limit: 10,
    offset: 0,
    search: undefined,
    sort: undefined,
    direction: undefined,
    customerIds: customerId ? [customerId] : undefined,
    hatcheryIds: hatcheryId ? [hatcheryId] : undefined,
  });

  useEffect(() => {
    const newQuery = { ...query };
    customerId && (newQuery.customerIds = [customerId]);
    hatcheryId && (newQuery.hatcheryIds = [hatcheryId]);
    setQuery(newQuery);
  }, [customerId, hatcheryId]);

  const [role, setRole] = useState<Partial<DataType>>({});

  const { isOpen: isDeleteModalOpen, toggleIsOpen: toggleDeleteModal } = useDrawer();
  const { isOpen: isReinviteModalOpen, toggleIsOpen: toggleReinviteModal } = useDrawer();
  const { isOpen: isPermissionsModalOpen, toggleIsOpen: togglePermissionsModal } = useDrawer();
  const { isOpen: isAddEmployeeDrawerOpen, toggleIsOpen: toggleAddEmployeeDrawer } = useDrawer();

  const { data } = useGetRoles(query);
  const { mutateAsync: updateRole } = useUpdateRole();
  const { mutateAsync: deleteRole } = useDeleteRole();
  const { mutateAsync: reinviteRole } = useReinviteRole();

  const handleInvalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: roleKeys.list(query) });

  const handleUpdateRole = async (role: Partial<DataType>) => {
    const { roleId: id, type, isContactPerson } = role;
    if (!id) return;
    await updateRole({ id, updateRoleBody: { type, isContactPerson } });
    await handleInvalidateQuery();
  };

  const handleReinviteRole = async () => {
    const { roleId: id } = role;
    if (!id) return;
    await reinviteRole({ id });
    await handleInvalidateQuery();
    toggleReinviteModal();
    toast(t('toast.reinvite_sent'), { type: 'success' });
  };

  const handleDeleteRole = async () => {
    const { roleId: id } = role;
    if (!id) return;
    await deleteRole({ id });
    await handleInvalidateQuery();
    toggleDeleteModal();
    toast(t('toast.employee_deleted'), { type: 'success' });
  };

  const onInviteSuccess = async () => {
    await handleInvalidateQuery();
    toggleAddEmployeeDrawer();
    toast(t('toast.invitation_sent'), { type: 'success' });
  };

  const { getIsNestBornAdmin, getIsCustomerAdmin, getIsHatcheryAdmin, getIsCustomerHatcheryAdmin } =
    usePermissions();

  const isAdmin =
    getIsNestBornAdmin() ||
    getIsHatcheryAdmin(hatcheryId) ||
    getIsCustomerAdmin(customerId) ||
    getIsCustomerHatcheryAdmin(customer);

  const canInvite =
    getIsNestBornAdmin() || getIsHatcheryAdmin(hatcheryId) || getIsCustomerHatcheryAdmin(customer);

  const getIsEmployeeAdmin = (type?: RoleType) => {
    if (!type) return false;
    return type === RoleType.CustomerAdmin || type === RoleType.HatcheryAdmin;
  };

  const dataSource = data
    ? data.items.map((item) => {
        const { id, user, status, type, isContactPerson } = item;
        return {
          roleId: id,
          userId: user.id,
          fullName: user.fullName,
          email: user.email,
          mobilePhone: user.mobilePhone,
          status,
          isEmployeeAdmin: getIsEmployeeAdmin(type),
          isContactPerson: isContactPerson,
          type,
        };
      })
    : [];

  const renderTag = (status?: RoleViewStatusEnum) => {
    if (!status) return null;
    const color = {
      [RoleViewStatusEnum.Accepted]: 'green',
      [RoleViewStatusEnum.Declined]: 'red',
      [RoleViewStatusEnum.Pending]: 'orange',
    }[status];
    return <AntTag color={color}>{t(`invite.status.${status}`)}</AntTag>;
  };

  const handleChangeRoleType = (record: DataType) => {
    let type;
    if (customerId) {
      type = record.isEmployeeAdmin ? RoleType.CustomerUser : RoleType.CustomerAdmin;
    } else if (hatcheryId) {
      type = record.isEmployeeAdmin ? RoleType.HatcheryUser : RoleType.HatcheryAdmin;
    }

    setRole({
      roleId: record.roleId,
      fullName: record.fullName,
      type,
    });

    togglePermissionsModal();
  };

  const handleChangeContactPerson = async (record: DataType) => {
    await handleUpdateRole({
      roleId: record.roleId,
      isContactPerson: !record.isContactPerson,
    });

    toast(t('toast.item_updated', { item: t('contact_person') }), { type: 'success' });
  };

  const renderAdminSwitch = (record: DataType) => (
    <AntSwitch value={record.isEmployeeAdmin} onChange={() => handleChangeRoleType(record)} />
  );

  const renderContactPersonSwitch = (record: DataType) => (
    <AntSwitch value={record.isContactPerson} onChange={() => handleChangeContactPerson(record)} />
  );

  const renderReInviteButton = (record: DataType) => (
    <InviteButton
      onClick={() => {
        setRole({
          roleId: record.roleId,
          fullName: record.fullName,
        });
        toggleReinviteModal();
      }}
    />
  );

  const renderDeleteButton = (record: DataType) => (
    <DeleteButton
      onClick={() => {
        setRole({
          roleId: record.roleId,
          fullName: record.fullName,
        });
        toggleDeleteModal();
      }}
    />
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: t('name'),
      dataIndex: 'fullName',
    },
    {
      title: t('email_address'),
      dataIndex: 'email',
    },
    {
      title: t('mobile_phone'),
      dataIndex: 'mobilePhone',
    },
    {
      title: t('status'),
      render: (_, record) => renderTag(record.status),
    },
  ];

  if (isAdmin) {
    columns.push(
      {
        title: t('admin_permissions.admin'),
        render: (_, record) => renderAdminSwitch(record),
        align: 'center',
      },
      {
        title: t('contact_person'),
        render: (_, record) => renderContactPersonSwitch(record),
        align: 'center',
      },
      {
        render: (_, record) => (record.fullName ? null : renderReInviteButton(record)),
        align: 'right',
      },
      {
        render: (_, record) => renderDeleteButton(record),
        align: 'right',
      },
    );
  }

  const columnsMobile: TableColumnsType<DataType> = [
    {
      render: (_, record) => (
        <AntSpace direction="vertical" size={6} style={{ width: '100%' }}>
          <AntFlex align="center" justify="space-between">
            <Text>{record.fullName}</Text>
            <AntSpace>
              {renderTag(record.status)}
              {isAdmin && renderDeleteButton(record)}
            </AntSpace>
          </AntFlex>
          {isAdmin && (
            <AntSpace direction="vertical" size="middle">
              <AntSpace>
                {renderAdminSwitch(record)}
                <Text strong>{t('admin_permissions.admin')}</Text>
              </AntSpace>
              <AntSpace>
                {renderContactPersonSwitch(record)}
                <Text strong>{t('contact_person')}</Text>
              </AntSpace>
            </AntSpace>
          )}
          <Text>{record.email}</Text>
          <Text>{record.mobilePhone}</Text>
        </AntSpace>
      ),
    },
  ];

  const pagination = usePagination(query, setQuery, data?.count);

  const searchByName = debounce(
    (event: ChangeEvent<HTMLInputElement>) => setQuery({ ...query, search: event.target.value }),
    500,
  );

  const transPrefix = getIsEmployeeAdmin(role.type)
    ? 'admin_permissions.upgrade'
    : 'admin_permissions.downgrade';

  return (
    <AntSpace direction="vertical" size="large" className={styles.container}>
      <AntFlex justify="space-between" wrap="wrap">
        <Input type="search" onChange={searchByName} className={styles.input} />
        {canInvite && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleAddEmployeeDrawer}
            className={styles.button}
          >
            {t('invite.new_employee')}
          </Button>
        )}
      </AntFlex>

      <AntTable
        showHeader={!xs}
        columns={xs ? columnsMobile : columns}
        dataSource={dataSource}
        rowKey={(record) => record.roleId}
        pagination={pagination}
        style={{ marginBottom: xs ? 100 : 0 }}
      />

      <Modal
        title={t('admin_permissions.title')}
        cancelText={t(`${transPrefix}.no`)}
        okText={t(`${transPrefix}.yes`)}
        open={isPermissionsModalOpen}
        onOk={async () => {
          await handleUpdateRole(role);
          togglePermissionsModal();
        }}
        onCancel={togglePermissionsModal}
        isDanger={!getIsEmployeeAdmin(role.type)}
      >
        <Text>{t(`${transPrefix}.description`, { name: role.fullName })}</Text>
      </Modal>

      <Modal
        title={t('reinvite_modal.title')}
        cancelText={t('reinvite_modal.no')}
        okText={t('reinvite_modal.yes')}
        open={isReinviteModalOpen}
        onOk={handleReinviteRole}
        onCancel={toggleReinviteModal}
        isDanger
      >
        <Text>{t('reinvite_modal.description')}</Text>
      </Modal>

      <Modal
        title={t('delete_modal.title')}
        cancelText={t('delete_modal.no')}
        okText={t('delete_modal.yes')}
        open={isDeleteModalOpen}
        onOk={handleDeleteRole}
        onCancel={toggleDeleteModal}
        isDanger
      >
        <Text>{t('delete_modal.description_employee')}</Text>
      </Modal>

      <AddEmployeeDrawer
        open={isAddEmployeeDrawerOpen}
        toggleIsOpen={toggleAddEmployeeDrawer}
        onSuccess={onInviteSuccess}
      />
    </AntSpace>
  );
};
