import { DateFormat, formatDate } from '@app/utils/helpers';
import { AntTable } from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

type DataSourceType = {
  key: string;
  date: string;
  user?: string;
  text?: string;
};

export const Comments = ({ comments }: { comments?: DataSourceType[] }) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<DataSourceType> = [
    {
      title: t('date'),
      render: (_, record) => record.date && formatDate(record.date, DateFormat.DateTime),
      width: 150,
    },
    {
      title: t('name'),
      render: (_, record) => record.user,
      width: 190,
    },
    {
      title: t('comment'),
      render: (_, record) => record.text,
    },
  ];

  return (
    <AntTable
      size="small"
      columns={columns}
      dataSource={comments}
      rowKey={(record) => record.key}
      pagination={false}
    />
  );
};
