import { CaretDownOutlined } from '@ant-design/icons';
import { countries } from '@app/constants';
import type { CustomerApiGetCustomersRequest, HatcheryApiGetHatcheriesRequest } from '@sdk/api';
import { AntGrid, AntRadio, AntSpace, Button, Input } from '@ui/components';
import { colors } from '@ui/constants';
import { Dropdown } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const CountrySelector = ({
  query,
  setQuery,
}: {
  query: CustomerApiGetCustomersRequest | HatcheryApiGetHatcheriesRequest;
  setQuery: (q: CustomerApiGetCustomersRequest | HatcheryApiGetHatcheriesRequest) => void;
}) => {
  const { t } = useTranslation();

  const [countrySearch, setCountrySearch] = useState<string | undefined>();
  const filteredCountries = countries.filter((country) => {
    return countrySearch ? country.name.toLocaleLowerCase().includes(countrySearch) : true;
  });

  const isCountryFilterActive = !!query.country;

  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();
  return (
    <Dropdown
      overlayClassName={styles.dropdown}
      trigger={['click']}
      dropdownRender={() => (
        <AntSpace direction="vertical" size="middle" style={{ width: xs ? 230 : 350 }}>
          <Input
            type="search"
            onChange={(e) => setCountrySearch(e.target.value.toLocaleLowerCase())}
          />
          <AntSpace direction="vertical">
            {filteredCountries.map((country) => {
              const isChecked = query.country === country.name;
              return (
                <AntRadio
                  key={country.code}
                  checked={isChecked}
                  onChange={() => {
                    setQuery({ ...query, country: isChecked ? undefined : country.name });
                  }}
                >
                  {country.name}
                </AntRadio>
              );
            })}
          </AntSpace>
        </AntSpace>
      )}
    >
      <Button
        type="text"
        iconRight={<CaretDownOutlined style={{ color: colors.grey300 }} />}
        style={{ color: isCountryFilterActive ? colors.yellowGreen700 : colors.grey700 }}
      >
        {t('country')}
      </Button>
    </Dropdown>
  );
};
