import { CaretDownOutlined } from '@ant-design/icons';
import { Select as AntSelect, type SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';

export const Select = ({ ...props }: SelectProps) => {
  const { t } = useTranslation();
  return (
    <AntSelect
      suffixIcon={<CaretDownOutlined />}
      placeholder={t('select')}
      size="large"
      {...props}
    />
  );
};
