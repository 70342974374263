import { getTempScaleSymbol } from '@app/utils/helpers';
import { getTemperature, getTime } from '@app/utils/helpers/chart';
import { useMobile } from '@app/utils/hooks';
import { useUserStore } from '@data/storage';
import { type BatchStatsView, type PointView, StatViewTypeEnum } from '@sdk/api';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';

import { sortDataPoints } from '../../utils';
import { EmptyChartCover } from './EmptyChartCover';
import { useChart } from './hooks/useChart';

type ChartProps = {
  stats?: BatchStatsView;
  timeRange: [string, string];
  isPrintable?: boolean;
};

export const AvgSensorDataChart = ({ stats, timeRange, isPrintable }: ChartProps) => {
  const { isMobile, isLandscape } = useMobile();
  const { user } = useUserStore();
  const { t } = useTranslation();

  const tempScale = user?.airTempScale;
  const tempSuffix = getTempScaleSymbol(tempScale);

  const data: Map<StatViewTypeEnum, PointView[]> = new Map();

  stats?.external.forEach((sensor) => {
    const { type, points } = sensor;
    data.set(type, sortDataPoints(points));
  });

  const getDomain = () => {
    let from = 0.08;
    if (isMobile) {
      from = isLandscape ? 0.1 : 0.25;
    }
    return [from, 0.95];
  };

  const {
    layout: { xaxis, yaxis, ...sharedLayout },
    config,
  } = useChart(t('indicators.avg_sensor_data'), timeRange, isPrintable);

  const layout = {
    ...sharedLayout,
    yaxis: {
      title: t('indicators.temperature_in_scale', { scale: tempSuffix }),
      ticksuffix: tempSuffix,
      showgrid: false,
      ...yaxis,
    },
    yaxis2: {
      title: t('indicators.relative_humidity'),
      ticksuffix: '%',
      anchor: 'free',
      overlaying: 'y',
      side: 'left',
      position: 0,
      showgrid: false,
      ...yaxis,
    },
    yaxis3: {
      title: t('indicators.co2'),
      ticksuffix: 'ppm',
      anchor: 'x',
      overlaying: 'y',
      side: 'right',
      position: 0.96,
      showgrid: false,
      ...yaxis,
    },
    xaxis: {
      domain: getDomain(),
      showgrid: false,
      ...xaxis,
    },
  };

  const formattedData = [
    {
      line: { color: '#1f77b4' },
      name: t('indicators.relative_humidity'),
      x: data.get('humidity')?.map(getTime),
      y: data.get('humidity')?.map((point) => point.value.toFixed(1)),
      yaxis: 'y2',
      mode: 'lines',
    },
    {
      line: { color: '#dbdb06' },
      name: t('indicators.co2'),
      x: data.get('co2')?.map(getTime),
      y: data.get('co2')?.map((point) => point.value.toFixed(0)),
      yaxis: 'y3',
      mode: 'lines',
    },
    {
      line: { color: '#d62728' },
      name: t('indicators.air_temp'),
      x: data.get('airTemperature')?.map(getTime),
      y: data.get('airTemperature')?.map((point) => getTemperature(point.value, tempScale)),
      mode: 'lines',
    },
  ];

  const isEmpty =
    !data.get('humidity')?.length &&
    !data.get('co2')?.length &&
    !data.get('airTemperature')?.length;

  // @ts-expect-error To do: fix the Plotly types
  const Chart = () => <Plot layout={layout} config={config} data={formattedData} />;
  return (
    <div style={{ position: 'relative' }}>
      <Chart />
      {isEmpty && <EmptyChartCover />}
    </div>
  );
};
