import { UpdateBatchPlanSchema, type UpdateBatchPlanValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import type { UpdateBatchBody } from '@sdk/api';
import {
  AntFlex,
  AntSpace,
  AntTypography,
  Button,
  Drawer,
  type DrawerProps,
  FormItem,
} from '@ui/components';
import { colors } from '@ui/constants';
import { Image } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getFloorPlans } from '../../utils';

const plans = getFloorPlans();

interface EditBatchPlanDrawerProps extends DrawerProps {
  initialValues: UpdateBatchPlanValidator;
  onSubmit: (body: UpdateBatchBody) => Promise<void>;
  isPending?: boolean;
}

export const EditBatchPlanDrawer = ({
  toggleIsOpen,
  initialValues,
  onSubmit,
  isPending,
  ...props
}: EditBatchPlanDrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UpdateBatchPlanValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateBatchPlanSchema),
    values: initialValues,
  });

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
              loading={isPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <FormItem required name="floorPlan" error={errors.floorPlan?.message}>
        <Controller
          control={control}
          name="floorPlan"
          render={({ field }) => (
            <AntSpace direction="vertical">
              {plans.map((plan) => (
                <Image
                  key={plan.id}
                  src={plan.src}
                  preview={false}
                  role="button"
                  style={{
                    cursor: 'pointer',
                    border: '3px solid',
                    borderColor: field.value === plan.id ? colors.yellowGreen500 : 'transparent',
                    borderRadius: 8,
                  }}
                  onClick={() => field.onChange(plan.id)}
                />
              ))}
            </AntSpace>
          )}
        />
      </FormItem>
    </Drawer>
  );
};
