import { countries } from '@app/constants';
import { customerKeys } from '@data/constants';
import { useUpdateCustomerLocation } from '@data/hooks';
import { UpdateLocationSchema, type UpdateLocationValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import styles from '../styles.module.css';
import type { LocationDataType } from '../Tabs/CustomerLocations';

interface EditLocationDrawerProps extends DrawerProps {
  locationData: LocationDataType;
}

export const EditLocationDrawer = ({
  toggleIsOpen,
  locationData,
  ...props
}: EditLocationDrawerProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const queryClient = useQueryClient();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateLocationValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateLocationSchema),
    values: {
      name: locationData.customerLocationName,
      country: locationData.location.country,
      city: locationData.location.city,
      address: locationData.location.address,
      postalCode: locationData.location.postalCode,
    },
  });

  const { mutateAsync: updateCustomerLocation } = useUpdateCustomerLocation();

  const onSubmit = async (values: UpdateLocationValidator) => {
    if (!customerId) return;
    const { customerLocationId } = locationData;
    const { name, country, city, address, postalCode } = values;

    await updateCustomerLocation({
      id: customerId,
      customerLocationId,
      updateCustomerLocationBody: {
        name,
        location: {
          country,
          city,
          address,
          postalCode,
        },
      },
    });
    await queryClient.invalidateQueries({ queryKey: customerKeys.detail(customerId) });

    toggleIsOpen();
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit_location')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="createHatchery" layout="vertical">
        <Text className={styles.drawerLabel}>{t('location_info')}</Text>

        <FormItem name="name" label={t('location_name')} error={errors.name?.message}>
          <Controller control={control} name="name" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem name="country" label={t('country')} error={errors.country?.message}>
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <Select
                showSearch
                options={countries.map((c) => ({ value: c.name, label: c.name }))}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem name="city" label={t('city')} error={errors.city?.message}>
          <Controller control={control} name="city" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem name="postalCode" label={t('postal_code')} error={errors.postalCode?.message}>
          <Controller
            control={control}
            name="postalCode"
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <FormItem name="address" label={t('address')} error={errors.address?.message}>
          <Controller
            control={control}
            name="address"
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
