import { useGetBreeds } from '@data/hooks';
import { useGetSensorSets } from '@data/hooks/sensor';
import { UpdateBatchDetailsSchema, type UpdateBatchDetailsValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import type { UpdateBatchBody } from '@sdk/api';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  InputNumber,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditBatchDetailsDrawerProps extends DrawerProps {
  initialValues: UpdateBatchDetailsValidator;
  onSubmit: (body: UpdateBatchBody) => Promise<void>;
  isPending?: boolean;
}

export const EditBatchDetailsDrawer = ({
  toggleIsOpen,
  initialValues,
  onSubmit,
  isPending,
  ...props
}: EditBatchDetailsDrawerProps) => {
  const { t } = useTranslation();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UpdateBatchDetailsValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateBatchDetailsSchema),
    values: initialValues,
  });

  const { data: breeds } = useGetBreeds({ limit: 1000, offset: 0 });
  const { data: sensorSets } = useGetSensorSets({ limit: 5000, offset: 0 });

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
              loading={isPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateBatchGeneralInfo" layout="vertical">
        <FormItem
          required
          name="numberPlaced"
          label={t('batch.number_of_eggs_placed')}
          error={errors.numberPlaced?.message}
        >
          <Controller
            control={control}
            name="numberPlaced"
            render={({ field }) => <InputNumber style={{ width: '100%' }} {...field} />}
          />
        </FormItem>

        <FormItem
          name="expectedNonHatched"
          label={t('batch.expected_non_hatched_eggs')}
          error={errors.expectedNonHatched?.message}
        >
          <Controller
            control={control}
            name="expectedNonHatched"
            render={({ field }) => (
              <InputNumber style={{ width: '100%' }} addonAfter="%" {...field} />
            )}
          />
        </FormItem>

        <FormItem
          name="averageParentAge"
          label={t('batch.average_age_parent_stock')}
          error={errors.averageParentAge?.message}
        >
          <Controller
            control={control}
            name="averageParentAge"
            render={({ field }) => (
              <InputNumber style={{ width: '100%' }} addonAfter={t('weeks')} {...field} />
            )}
          />
        </FormItem>

        <FormItem name="breedId" label={t('breed')} error={errors.breedId?.message}>
          <Controller
            control={control}
            name="breedId"
            render={({ field }) => (
              <Select
                options={breeds?.items.map((b) => ({ value: b.id, label: b.name }))}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem
          name="parentFlockId"
          label={t('batch.parent_stock_flock_id')}
          error={errors.parentFlockId?.message}
        >
          <Controller
            control={control}
            name="parentFlockId"
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <FormItem
          required
          name="sensorSetId"
          label={t('batch.ovoscan_set')}
          error={errors.sensorSetId?.message}
        >
          <Controller
            control={control}
            name="sensorSetId"
            render={({ field }) => (
              <Select
                options={sensorSets?.items.map((set) => ({ value: set.id, label: set.name }))}
                {...field}
              />
            )}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
