import type {
  BatchApiGetBatchesRequest,
  CustomerApiGetCustomersRequest,
  HatcheryView,
} from '@sdk/api';
import { Tag } from 'antd';

export const HatcheryFilters = ({
  items,
  query,
  setQuery,
}: {
  items?: HatcheryView[];
  query: BatchApiGetBatchesRequest | CustomerApiGetCustomersRequest;
  setQuery: (q: BatchApiGetBatchesRequest | CustomerApiGetCustomersRequest) => void;
}) => {
  return (
    <>
      {query.hatcheryIds?.map((filterId) => (
        <Tag
          key={filterId}
          bordered={false}
          closable
          onClose={() =>
            setQuery({
              ...query,
              hatcheryIds: query.hatcheryIds?.filter((id) => id !== filterId),
            })
          }
        >
          {items?.find((h) => h.id === filterId)?.name}
        </Tag>
      ))}
    </>
  );
};
