import { useDrawer } from '@app/utils/hooks';
import { hatcheryKeys } from '@data/constants/hook-keys';
import type { HatcheryView } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import { AntCard, AntFlex, AntGrid, AntTable, AntTypography, EditButton } from '@ui/components';
import { fontSize, fontWeight } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import { EditHatcheryDrawer } from './EditHatcheryDrawer';

type DataSourceType = {
  key: string;
  value?: string;
};

type ComponentType = {
  hatchery?: HatcheryView;
};

const getHatcheryInfo = (data?: HatcheryView) =>
  data
    ? {
        name: data.name,
        country: data.location.country,
        city: data.location.city,
        address: data.location.address,
        contactPerson: data.contactPerson,
      }
    : {};

export const HatcheryInfo = ({ hatchery }: ComponentType) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isOpen, toggleIsOpen } = useDrawer();
  const { Text, Title } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const onUpdateSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: hatcheryKeys.detail(hatchery?.id || '') });
    toggleIsOpen();
  };

  const hatcheryInfo = getHatcheryInfo(hatchery);

  const dataSource: DataSourceType[] = Object.entries(hatcheryInfo).map(([key, value]) => ({
    // @ts-expect-error i18next type check
    key: key === 'contactPerson' ? t('contact_person') : t(key),
    value,
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: xs ? 'unset' : '25%' },
    {
      render: (_, record) => (
        <Text style={{ fontWeight: fontWeight.semibold }}>{record.value}</Text>
      ),
    },
  ];

  return (
    <AntCard bordered={false} style={{ width: '100%' }}>
      <AntFlex align="center" justify="space-between">
        <Title level={2} style={{ fontSize: fontSize.xl }}>
          {t('general')}
        </Title>
        <EditButton onClick={toggleIsOpen} />
      </AntFlex>

      <AntTable
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.key}
        pagination={false}
        showHeader={false}
      />

      <EditHatcheryDrawer
        open={isOpen}
        toggleIsOpen={toggleIsOpen}
        initialValues={hatcheryInfo}
        onSuccess={onUpdateSuccess}
      />
    </AntCard>
  );
};
