import { CaretDownOutlined } from '@ant-design/icons';
import type { BatchApiGetBatchesRequest, CustomerView } from '@sdk/api';
import { AntCheckbox, AntSpace, Button, Input } from '@ui/components';
import { colors } from '@ui/constants';
import { Dropdown } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const CustomerSelector = ({
  items,
  query,
  setQuery,
}: {
  items?: CustomerView[];
  query: BatchApiGetBatchesRequest;
  setQuery: (q: BatchApiGetBatchesRequest) => void;
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string | undefined>();

  const filteredItems = items?.filter((item) =>
    search ? item.name.toLocaleLowerCase().includes(search) : true,
  );

  const selectedIds = query.customerIds || [];

  return (
    <Dropdown
      overlayClassName={styles.dropdown}
      trigger={['click']}
      dropdownRender={() => (
        <AntSpace direction="vertical" size="middle">
          <Input type="search" onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())} />
          <AntSpace direction="vertical">
            {filteredItems?.map((item) => {
              const isChecked = selectedIds.includes(item.id);
              return (
                <AntCheckbox
                  key={item.id}
                  checked={isChecked}
                  onChange={() => {
                    setQuery({
                      ...query,
                      customerIds: isChecked
                        ? selectedIds.filter((id) => id !== item.id)
                        : [...selectedIds, item.id],
                    });
                  }}
                >
                  {item.name}
                </AntCheckbox>
              );
            })}
          </AntSpace>
        </AntSpace>
      )}
    >
      <Button
        type="text"
        iconRight={<CaretDownOutlined style={{ color: colors.grey300 }} />}
        style={{ color: selectedIds.length ? colors.yellowGreen700 : colors.grey700 }}
      >
        {t('customer')}
      </Button>
    </Dropdown>
  );
};
