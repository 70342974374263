import { CountryFilters, CountrySelector } from '@app/components/Filters';
import { useTable } from '@app/utils/hooks';
import type { HatcheryApiGetHatcheriesRequest } from '@sdk/api';
import { AntCard, AntFlex, AntSpace, Input } from '@ui/components';

import styles from './styles.module.css';

export const HatcheriesListFilters = ({
  query,
  setQuery,
}: {
  query: HatcheryApiGetHatcheriesRequest;
  setQuery: (q: HatcheryApiGetHatcheriesRequest) => void;
}) => {
  const { handleSearch } = useTable(query, setQuery);

  return (
    <AntCard bordered={false} style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }}>
      <AntSpace direction="vertical" style={{ width: '100%' }}>
        <AntFlex justify="space-between">
          <Input type="search" className={styles.input} onChange={handleSearch()} />
          <CountrySelector query={query} setQuery={setQuery} />
        </AntFlex>

        {query.country && <CountryFilters query={query} setQuery={setQuery} />}
      </AntSpace>
    </AntCard>
  );
};
