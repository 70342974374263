import { countries } from '@app/constants';
import { useUpdateHatchery } from '@data/hooks';
import { UpdateHatcherySchema, type UpdateHatcheryValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import styles from '../styles.module.css';

interface EditHatcheryDrawerProps extends DrawerProps {
  initialValues: UpdateHatcheryValidator;
  onSuccess: () => Promise<void>;
}

export const EditHatcheryDrawer = ({
  toggleIsOpen,
  initialValues,
  onSuccess,
  ...props
}: EditHatcheryDrawerProps) => {
  const { t } = useTranslation();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UpdateHatcheryValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateHatcherySchema),
  });

  const { hatcheryId = '' } = useParams();
  const { mutateAsync: updateHatchery, isPending: isUpdateHatcheryPending } = useUpdateHatchery();

  const onSubmit = async (values: UpdateHatcheryValidator) => {
    const { name, country, city, address, postalCode } = values;
    await updateHatchery({
      id: hatcheryId,
      updateHatcheryBody: {
        name,
        location: {
          country,
          city,
          address,
          postalCode,
        },
      },
    });
    await onSuccess();
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
              loading={isUpdateHatcheryPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateHatchery" layout="vertical">
        <Text className={styles.drawerLabel}>{t('general')}</Text>

        <FormItem name="name" label={t('hatchery_name')} error={errors.name?.message}>
          <Controller
            control={control}
            name="name"
            defaultValue={initialValues.name}
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <FormItem name="country" label={t('country')} error={errors.country?.message}>
          <Controller
            control={control}
            name="country"
            defaultValue={initialValues.country}
            render={({ field }) => (
              <Select
                showSearch
                options={countries.map((c) => ({ value: c.name, label: c.name }))}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem name="city" label={t('city')} error={errors.city?.message}>
          <Controller
            control={control}
            name="city"
            defaultValue={initialValues.city}
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <FormItem name="address" label={t('address')} error={errors.address?.message}>
          <Controller
            control={control}
            name="address"
            defaultValue={initialValues.address}
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <FormItem name="postalCode" label={t('postal_code')} error={errors.postalCode?.message}>
          <Controller
            control={control}
            name="postalCode"
            defaultValue={initialValues.postalCode}
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
