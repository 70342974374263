import { AntCard, AntFlex, AntTypography } from '@ui/components';
import { UnreadMailbox } from '@ui/icons';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const ForgotPasswordConfirmation: React.FC = () => {
  const { t } = useTranslation();
  const { Title, Text } = AntTypography;

  return (
    <AntCard classNames={{ body: styles.card }}>
      <AntFlex vertical justify="center" align="center">
        <UnreadMailbox className={styles.expiredIcon} />
        <Title level={2}>{t('forgot_your_password.check_email')}</Title>
        <Text type="secondary" style={{ textAlign: 'center' }}>
          {t('forgot_your_password.check_email_description')}
        </Text>
      </AntFlex>
    </AntCard>
  );
};
