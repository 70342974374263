import { getData, subscriptionApi } from '@app/utils/api/api';
import type { SubscriptionApiUpdateSubscriptionRequest, SubscriptionBody } from '@sdk/api';
import { useMutation } from '@tanstack/react-query';

export const useCreateSubscription = () =>
  useMutation({
    mutationFn: (subscriptionBody: SubscriptionBody) =>
      getData(subscriptionApi.createSubscription({ subscriptionBody })),
  });

export const useUpdateSubscription = () =>
  useMutation({
    mutationFn: ({ id, updateSubscriptionBody }: SubscriptionApiUpdateSubscriptionRequest) =>
      getData(subscriptionApi.updateSubscription({ id, updateSubscriptionBody })),
  });
