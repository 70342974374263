import type { BatchDetailView } from '@sdk/api';

// 'chicks day 1' is also referred to as 'chicks end of hatch'
// 'selection day 1' is also referred to as 'culls & dead chicks end of hatch'

const getChicksDay1Quantity = (batch: BatchDetailView) => {
  const { numberPlaced, numberNonHatched = 0, selectionDay1 = 0 } = batch;
  return numberPlaced - numberNonHatched - selectionDay1;
};

const getChicksDay7Quantity = (batch: BatchDetailView) => {
  const {
    numberPlaced,
    numberNonHatched = 0,
    selectionDay1 = 0,
    selectionDay7 = 0,
    mortalityDay7 = 0,
  } = batch;
  return numberPlaced - numberNonHatched - selectionDay1 - selectionDay7 - mortalityDay7;
};

export const getChicksDay1 = (batch?: BatchDetailView) => {
  if (!batch) return;
  const quantity = getChicksDay1Quantity(batch);
  const percentage = (quantity / batch.numberPlaced) * 100;
  return [quantity, percentage];
};

export const getChicksDay7 = (batch?: BatchDetailView) => {
  if (!batch) return;
  const quantity = getChicksDay7Quantity(batch);
  const percentage = (quantity / getChicksDay1Quantity(batch)) * 100;
  return [quantity, percentage];
};

export const getBatchValues = (
  key:
    | 'numberNonHatched'
    | 'expectedNonHatched'
    | 'selectionDay1'
    | 'selectionDay7'
    | 'mortalityDay7',
  batch?: BatchDetailView,
) => {
  const quantity = batch?.[key];
  if (!quantity) return;

  const comparisonBase = ['selectionDay7', 'mortalityDay7'].includes(key)
    ? getChicksDay1Quantity(batch)
    : batch.numberPlaced;

  const percentage = (quantity / comparisonBase) * 100;
  return [quantity, percentage];
};
