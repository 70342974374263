import { customerKeys, hatcheryKeys } from '@data/constants';
import { useCreateSubscription, useUpdateSubscription } from '@data/hooks';
import {
  type ListSubscriptionRoleView,
  SubscriptionBodyTypesEnum,
  SubscriptionRoleViewTypeEnum,
  type SubscriptionView,
} from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import { AntFlex, AntGrid, AntSpace, AntSwitch, AntTable, AntTypography } from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type DataType = {
  roleId: string;
  fullName?: string;
  email: string;
  role: SubscriptionRoleViewTypeEnum;
  subscriptions: SubscriptionView[];
};
type SubscriptionsProps = {
  subscriptions?: ListSubscriptionRoleView;
  isAdmin?: boolean;
};
export const Subscriptions = ({ subscriptions, isAdmin }: SubscriptionsProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { hatcheryId, customerId } = useParams();
  const { Text } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const { mutateAsync: createSubscription } = useCreateSubscription();
  const { mutateAsync: updateSubscription } = useUpdateSubscription();

  const dataSource = subscriptions
    ? subscriptions.items.map((item) => {
        const { id: roleId, type: role, user, subscriptions } = item;
        return {
          roleId,
          fullName: user.fullName,
          email: user.email,
          role,
          subscriptions,
        };
      })
    : [];

  const renderToast = (
    subscriptionType: SubscriptionBodyTypesEnum,
    isEnabled: boolean,
    fullName?: string,
  ) => {
    const translationKeys = {
      [SubscriptionBodyTypesEnum.Notifications]: t('notifications'),
      [SubscriptionBodyTypesEnum.BatchReports]: t('batch_reports'),
    };
    isEnabled
      ? toast(
          t('toast.subscription_disabled', {
            type: translationKeys[subscriptionType],
            name: fullName,
          }),
          { type: 'success' },
        )
      : toast(
          t('toast.subscription_enabled', {
            type: translationKeys[subscriptionType],
            name: fullName,
          }),
          { type: 'success' },
        );
  };

  const handleUpdateSubscription = async (
    subscription: SubscriptionView,
    type: SubscriptionBodyTypesEnum,
    isEnabled: boolean,
  ) => {
    const { id, types } = subscription;
    let updatedTypes;
    isEnabled
      ? (updatedTypes = types.filter((t) => t !== type))
      : (updatedTypes = [...types, type]);

    await updateSubscription({ id, updateSubscriptionBody: { types: updatedTypes } });
  };

  const renderSwitch = (record: DataType, subscriptionType: SubscriptionBodyTypesEnum) => {
    const { roleId, subscriptions, fullName } = record;
    const isSubscriptionCreated = subscriptions.length;
    const isSubscriptionEnabled = subscriptions[0]?.types.some((type) => type === subscriptionType);

    return (
      <AntSwitch
        value={isSubscriptionEnabled}
        onChange={async () => {
          !isSubscriptionCreated
            ? await createSubscription({
                roleId,
                customerId,
                hatcheryId,
                types: [subscriptionType],
              })
            : await handleUpdateSubscription(
                subscriptions[0],
                subscriptionType,
                isSubscriptionEnabled,
              );

          hatcheryId &&
            (await queryClient.invalidateQueries({
              queryKey: hatcheryKeys.subscription(hatcheryId),
            }));

          customerId &&
            (await queryClient.invalidateQueries({
              queryKey: customerKeys.subscription(customerId),
            }));

          renderToast(subscriptionType, isSubscriptionEnabled, fullName);
        }}
      />
    );
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: t('name'),
      dataIndex: 'fullName',
    },
    {
      title: t('email_address'),
      dataIndex: 'email',
    },
    {
      title: t('role'),
      dataIndex: 'role',
    },
  ];

  (hatcheryId || isAdmin) &&
    columns.push({
      title: t('notifications'),
      render: (_, record) => renderSwitch(record, SubscriptionBodyTypesEnum.Notifications),
      align: 'center',
    });

  hatcheryId &&
    columns.push({
      title: t('batch_reports'),
      render: (_, record) => renderSwitch(record, SubscriptionBodyTypesEnum.BatchReports),
      align: 'center',
    });

  const columnsMobile: TableColumnsType<DataType> = [
    {
      render: (_, record) => (
        <AntSpace direction="vertical" size={6} style={{ width: '100%' }}>
          <Text>{record.fullName}</Text>
          <Text>{record.email}</Text>
          <Text>{record.role}</Text>
          <AntFlex align="center" justify="space-between">
            <Text strong>{t('notifications')}</Text>
            {renderSwitch(record, SubscriptionBodyTypesEnum.Notifications)}
          </AntFlex>
          {hatcheryId && (
            <AntFlex align="center" justify="space-between">
              <Text strong>{t('batch_reports')}</Text>
              {renderSwitch(record, SubscriptionBodyTypesEnum.BatchReports)}
            </AntFlex>
          )}
        </AntSpace>
      ),
    },
  ];

  return (
    <AntTable
      showHeader={!xs}
      columns={xs ? columnsMobile : columns}
      dataSource={dataSource}
      rowKey={(record) => record.roleId}
      pagination={false}
      style={{ width: '100%', marginBottom: xs ? 100 : 0 }}
    />
  );
};
