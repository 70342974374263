import { PlusOutlined } from '@ant-design/icons';
import { usePermissions } from '@app/utils/hooks';
import { useDrawer } from '@app/utils/hooks/useDrawer';
import { AntLayout, AntTypography } from '@ui/components';
import { Button } from '@ui/components';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';
import { AddCustomerDrawer } from './Drawers';

export const CustomersListHeader: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, toggleIsOpen } = useDrawer();
  const { isNestBornAdmin, isAnyHatcheryAdmin } = usePermissions();

  const { Header } = AntLayout;
  const { Title } = AntTypography;

  return (
    <Header className={styles.header}>
      <Title level={1} className={styles.headerTitle}>
        {t('customers')}
      </Title>
      {(isNestBornAdmin || isAnyHatcheryAdmin) && (
        <Button type="primary" icon={<PlusOutlined />} onClick={toggleIsOpen}>
          {t('new_customer')}
        </Button>
      )}
      <AddCustomerDrawer open={isOpen} toggleIsOpen={toggleIsOpen} />
    </Header>
  );
};
