import { getTempScaleSymbol } from '@app/utils/helpers';
import { getTemperature, getTime } from '@app/utils/helpers/chart';
import { useUserStore } from '@data/storage';
import type { PointView } from '@sdk/api';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';

import { useChart } from './hooks/useChart';

type EggTempChartProps = {
  position: number;
  eggs: { [key: number]: PointView[] };
  average?: PointView[];
  tempRange?: { time: string; value: { min: number; max: number } }[];
  timeRange: [string, string];
};

export const EggTemperatureChartSingle = ({
  position,
  eggs,
  average,
  tempRange,
  timeRange,
}: EggTempChartProps) => {
  const { user } = useUserStore();
  const { t } = useTranslation();

  const tempScale = user?.eggTempScale;
  const tempSuffix = getTempScaleSymbol(tempScale);

  const {
    layout: { yaxis, ...sharedLayout },
    config,
  } = useChart(`Ovo ${position}`, timeRange);

  const layout = {
    ...sharedLayout,
    yaxis: {
      title: `Temperature in ${tempSuffix}`,
      ticksuffix: tempSuffix,
      ...yaxis,
    },
  };

  const data = [
    {
      hoverinfo: 'skip',
      legendgroup: 'average',
      line: { color: 'transparent' },
      showlegend: false,
      x: tempRange?.map(getTime),
      y: tempRange?.map((point) => getTemperature(point.value.min, tempScale)),
      mode: 'lines',
    },
    {
      fill: 'tonexty',
      fillcolor: 'rgba(37, 50, 63, 0.25)',
      legendgroup: 'average',
      line: { color: '#25323F', width: 3 },
      name: t('indicators.average'),
      visible: true,
      x: average?.map(getTime),
      y: average?.map((point) => getTemperature(point.value, tempScale)),
      mode: 'lines',
    },
    {
      fill: 'tonexty',
      fillcolor: 'rgba(37, 50, 63, 0.25)',
      hoverinfo: 'skip',
      legendgroup: 'average',
      showlegend: false,
      x: tempRange?.map(getTime),
      y: tempRange?.map((point) => getTemperature(point.value.max, tempScale)),
      mode: 'none',
    },
    ...Object.entries(eggs).map(([position, points]) => ({
      name: t('egg_position', { position }),
      visible: 'legendonly',
      x: points.map(getTime),
      y: points.map((point) => getTemperature(point.value, tempScale)),
      mode: 'lines',
    })),
  ];

  // @ts-expect-error To do: fix the Plotly types
  return <Plot layout={layout} config={config} data={data} />;
};
