import { getTempScaleSymbol } from '@app/utils/helpers';
import { getTemperature, getTime } from '@app/utils/helpers/chart';
import { useUserStore } from '@data/storage';
import { type BatchStatsView, type PointView, StatViewTypeEnum, TemperatureScale } from '@sdk/api';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';
import { useSearchParams } from 'react-router-dom';

import { sortDataPoints } from '../../utils';
import { EmptyChartCover } from './EmptyChartCover';
import { useChart } from './hooks/useChart';

type ChartProps = {
  stats?: BatchStatsView;
  timeRange: [string, string];
  isPrintable?: boolean;
};

export const AvgAirTemperatureChart = ({ stats, timeRange, isPrintable }: ChartProps) => {
  const { user } = useUserStore();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const tempScale = (searchParams.get('airtempscale') as TemperatureScale) ?? user?.airTempScale;
  const tempSuffix = getTempScaleSymbol(tempScale);

  const data: {
    average: PointView[];
    ovoscans: { position: number; points: PointView[] }[];
    sensor: PointView[];
  } = {
    average: [],
    ovoscans: [],
    sensor: [],
  };

  stats?.ovo.forEach((sensor) => {
    if (sensor.type !== StatViewTypeEnum.AirTemperature) return;
    sensor.position
      ? data.ovoscans.push({
          position: sensor.position,
          points: sortDataPoints(sensor.points),
        })
      : (data.average = sortDataPoints(sensor.points));
  });

  const externalSensorPoints = stats?.external.find(
    (sensor) => sensor.type === StatViewTypeEnum.AirTemperature,
  )?.points;

  if (externalSensorPoints) data.sensor = sortDataPoints(externalSensorPoints);

  const {
    layout: { yaxis, ...sharedLayout },
    config,
  } = useChart(t('indicators.avg_air_temp'), timeRange, isPrintable);

  const layout = {
    ...sharedLayout,
    yaxis: {
      title: t('indicators.temperature_in_scale', { scale: tempSuffix }),
      ticksuffix: tempSuffix,
      ...yaxis,
    },
  };

  const formattedData = [
    ...data.ovoscans
      .sort((a, b) => a.position - b.position)
      .map(({ position, points }) => ({
        visible: isPrintable ? true : 'legendonly',
        name: `Ovo ${position}`,
        x: points.map(getTime),
        y: points.map((point) => getTemperature(point.value, tempScale)),
        mode: 'lines',
      })),

    {
      line: { color: '#d62728' },
      name: t('sensor'),
      x: data.sensor.map(getTime),
      y: data.sensor.map((point) => getTemperature(point.value, tempScale)),
      visible: isPrintable ? true : 'legendonly',
      mode: 'lines',
    },

    {
      line: { color: '#25323F', width: 3 },
      name: t('indicators.average'),
      x: data.average.map(getTime),
      y: data.average.map((point) => getTemperature(point.value, tempScale)),
      mode: 'lines',
    },
  ];

  const isEmpty = !data.average.length;

  // @ts-expect-error To do: fix the Plotly types
  const Chart = () => <Plot layout={layout} config={config} data={formattedData} />;
  return (
    <div style={{ position: 'relative' }}>
      <Chart />
      {isEmpty && <EmptyChartCover />}
    </div>
  );
};
