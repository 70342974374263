import { useDrawer, usePagination, useTable } from '@app/utils/hooks';
import { useGetSensors } from '@data/hooks/sensor';
import type { SensorApiGetSensorsRequest, SensorSetView, SensorViewTypeEnum } from '@sdk/api';
import {
  AntFlex,
  AntGrid,
  AntSpace,
  AntTable,
  EditButton,
  MultilineTableCell,
} from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditSensorDrawer } from './EditSensorDrawer';
import { SensorsListFilters } from './SensorsListFilters';

export type SensorDataType = {
  id: string;
  serialNumber: string;
  name: string;
  type: SensorViewTypeEnum;
  sensorSet?: SensorSetView;
  position?: number | null;
};

export const SensorsList = () => {
  const { t } = useTranslation();
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<SensorApiGetSensorsRequest>({
    limit: 10,
    offset: 0,
    search: undefined,
    sort: undefined,
    direction: undefined,
    type: undefined,
  });

  const { data } = useGetSensors(query);

  const { isOpen, toggleIsOpen } = useDrawer();
  const { handleSort } = useTable(query, setQuery);
  const pagination = usePagination(query, setQuery, data?.count);

  const [sensor, setSensor] = useState<SensorDataType>();

  const dataSource = data?.items.map((item) => {
    const { id, name, serialNumber, type, sensorSet, position } = item;
    return {
      id,
      serialNumber,
      name,
      type,
      sensorSet,
      position,
    };
  });

  const columns: TableColumnsType<SensorDataType> = [
    {
      title: t('serial_number'),
      dataIndex: 'serialNumber',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('serialNumber'),
      }),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('type'),
      }),
    },
    {
      title: t('batch.ovoscan_set'),
      sorter: true,
      render: (_, record) => record.sensorSet?.name,
      onHeaderCell: () => ({
        onClick: () => handleSort('sensorSet'),
      }),
    },
    {
      title: t('position'),
      dataIndex: 'position',
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('position'),
      }),
    },
    {
      render: (_, record) => (
        <EditButton
          onClick={() => {
            setSensor(record);
            toggleIsOpen();
          }}
        />
      ),
      align: 'right',
    },
  ];

  const columnsMobile: TableColumnsType<SensorDataType> = [
    {
      render: (_, record) => (
        <AntSpace direction="vertical" size={0} style={{ width: '100%' }}>
          <AntFlex justify="space-between">
            <MultilineTableCell text={record.serialNumber} description={[record.type]} />
            <EditButton
              onClick={() => {
                setSensor(record);
                toggleIsOpen();
              }}
            />
          </AntFlex>
          {record.sensorSet?.name}
          {record.position}
        </AntSpace>
      ),
    },
  ];

  return (
    <>
      <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
        <SensorsListFilters query={query} setQuery={setQuery} />
        <AntTable
          showHeader={!xs}
          columns={xs ? columnsMobile : columns}
          dataSource={dataSource}
          rowKey={(record) => record.serialNumber}
          pagination={pagination}
          style={{ marginBottom: xs ? 100 : 0 }}
        />
      </AntSpace>
      {sensor && (
        <EditSensorDrawer
          key={sensor.id}
          open={isOpen}
          toggleIsOpen={toggleIsOpen}
          sensor={sensor}
          query={query}
        />
      )}
    </>
  );
};
