export const sidebarWidth = 300;
export const drawerWidth = 500;

export const breakpointWidth = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
