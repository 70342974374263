import { getTime } from '@app/utils/helpers';
import type { BatchStatsView } from '@sdk/api';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';

import { sortDataPoints } from '../../utils';
import { EmptyChartCover } from './EmptyChartCover';
import { useChart } from './hooks/useChart';

type ChartProps = {
  stats?: BatchStatsView;
  timeRange: [string, string];
  isPrintable?: boolean;
};

export const HatchWindowChart = ({ stats, timeRange, isPrintable }: ChartProps) => {
  const { t } = useTranslation();

  const {
    layout: { yaxis, ...sharedLayout },
    config,
  } = useChart(t('indicators.hatch_window'), timeRange, isPrintable);

  const layout = {
    ...sharedLayout,
    hovermode: 'closest',
    yaxis: {
      title: t('indicators.eggs_hatched_percentage'),
      ticksuffix: '%',
      range: [0, 101],
      ...yaxis,
    },
  };

  const sortedData = sortDataPoints(stats?.hatched);

  const data = [
    {
      marker: { color: '#25323F' },
      type: 'bar',
      x: sortedData.map(getTime),
      y: sortedData.map((point) => Math.round(point.percentage)),
    },
  ];

  const isEmpty = !sortedData.some((point) => point.percentage > 0);

  // @ts-expect-error To do: fix the Plotly types
  const Chart = () => <Plot layout={layout} config={config} data={data} />;
  return (
    <div style={{ position: 'relative' }}>
      <Chart />
      {isEmpty && <EmptyChartCover />}
    </div>
  );
};
