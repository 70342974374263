import { getData, hatcheryApi } from '@app/utils/api/api';
import { hatcheryKeys } from '@data/constants';
import type {
  HatcheryApiDeleteHatcheryRequest,
  HatcheryApiGetHatcheriesRequest,
  HatcheryApiGetHatcheryByIdRequest,
  HatcheryApiGetHatcherySubscriptionsRequest,
  HatcheryApiUpdateHatcheryRequest,
  HatcheryBody,
} from '@sdk/api';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetHatcheries = (params: HatcheryApiGetHatcheriesRequest) =>
  useQuery({
    queryKey: [hatcheryKeys.list(params)],
    queryFn: () => getData(hatcheryApi.getHatcheries(params)),
  });

export const useCreateHatchery = () =>
  useMutation({
    mutationFn: (hatcheryBody: HatcheryBody) =>
      getData(hatcheryApi.createHatchery({ hatcheryBody })),
  });

export const useGetHatcheryById = ({ id }: HatcheryApiGetHatcheryByIdRequest) =>
  useQuery({
    queryKey: hatcheryKeys.detail(id),
    queryFn: () => getData(hatcheryApi.getHatcheryById({ id })),
  });

export const useUpdateHatchery = () =>
  useMutation({
    mutationFn: (data: HatcheryApiUpdateHatcheryRequest) =>
      getData(hatcheryApi.updateHatchery(data)),
  });

export const useDeleteHatchery = () =>
  useMutation({
    mutationFn: (params: HatcheryApiDeleteHatcheryRequest) =>
      getData(hatcheryApi.deleteHatchery(params)),
  });

export const useGetHatcherySubscriptions = ({ id }: HatcheryApiGetHatcherySubscriptionsRequest) =>
  useQuery({
    queryKey: hatcheryKeys.subscription(id),
    queryFn: () => getData(hatcheryApi.getHatcherySubscriptions({ id })),
  });
