import { convertTemperature } from '@app/utils/helpers';
import type { BatchDetailView, PointView, TemperatureScale } from '@sdk/api';
import dayjs from 'dayjs';

export const getTime = (dataPoint: PointView | { time: string }) => dayjs(dataPoint.time).format();

export const getTemperature = (temperature: number, tempScale?: TemperatureScale) =>
  convertTemperature(temperature, tempScale)?.toFixed(1);

export const getTimeRange = (batch: BatchDetailView | undefined): [string, string] => [
  // subtract/add 30 minutes to correctly display the first- and the last bar in the bar chart
  dayjs(batch?.monitoringStart).subtract(30, 'm').format(),
  dayjs(batch?.monitoringEnd).add(30, 'm').format(),
];
