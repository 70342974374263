import { CaretDownOutlined } from '@ant-design/icons';
import { type SensorApiGetSensorsRequest, SensorViewTypeEnum } from '@sdk/api';
import { AntRadio, AntSpace, Button } from '@ui/components';
import { colors } from '@ui/constants';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const SensorTypeSelector = ({
  query,
  setQuery,
}: {
  query: SensorApiGetSensorsRequest;
  setQuery: (q: SensorApiGetSensorsRequest) => void;
}) => {
  const { t } = useTranslation();

  const types = [SensorViewTypeEnum.External, SensorViewTypeEnum.Ovoscan];

  return (
    <Dropdown
      overlayClassName={styles.dropdown}
      trigger={['click']}
      dropdownRender={() => (
        <AntSpace direction="vertical" size="middle">
          <AntSpace direction="vertical">
            {types.map((type) => (
              <AntRadio
                key={type}
                checked={query.type === type}
                onChange={() => {
                  setQuery({
                    ...query,
                    type,
                  });
                }}
              >
                {t(type)}
              </AntRadio>
            ))}
          </AntSpace>
        </AntSpace>
      )}
    >
      <Button
        type="text"
        iconRight={<CaretDownOutlined style={{ color: colors.grey300 }} />}
        style={{ color: query.type ? colors.yellowGreen700 : colors.grey700 }}
      >
        {t('type')}
      </Button>
    </Dropdown>
  );
};
