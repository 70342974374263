import { routes } from '@app/constants';
import { DateFormat, formatDate } from '@app/utils/helpers';
import { useTable } from '@app/utils/hooks';
import { usePagination } from '@app/utils/hooks';
import { useGetCustomers } from '@data/hooks';
import type { CustomerApiGetCustomersRequest, CustomerHatcheryView } from '@sdk/api';
import {
  AntGrid,
  AntLayout,
  AntSpace,
  AntTable,
  AntTypography,
  MultilineTableCell,
} from '@ui/components';
import type { TableColumnsType } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from '../styles.module.css';
import { CustomersListFilters } from './CustomersListFilters';
import { CustomersListHeader } from './CustomersListHeader';

type DataType = {
  id: string;
  name: string;
  hatcheries: CustomerHatcheryView[];
  contactPerson?: string;
  lastActiveBatchAt?: string;
};

export const CustomersList = () => {
  const { t } = useTranslation();

  const { Content } = AntLayout;
  const { Text } = AntTypography;

  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const [query, setQuery] = useState<CustomerApiGetCustomersRequest>({
    limit: 10,
    offset: 0,
    search: undefined,
    sort: undefined,
    direction: undefined,
    hatcheryIds: [],
  });

  const { data } = useGetCustomers(query);

  const { handleSort } = useTable(query, setQuery);

  const dataSource = data?.items.map((item) => {
    const { id, name, hatcheries, contactPerson, lastActiveBatchAt } = item;
    return {
      id,
      name,
      hatcheries,
      contactPerson,
      lastActiveBatchAt,
    };
  });

  const CellLink = ({ record, children }: { record: DataType; children: React.ReactNode }) => (
    <Link className={styles.cellLink} to={`/${routes.app}/${routes.customers}/${record.id}`}>
      {children}
    </Link>
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: t('customer'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell text={record.name} description={[record.contactPerson]} />
        </CellLink>
      ),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('name'),
      }),
    },
    {
      title: t('hatchery'),
      render: (_, record) => (
        <CellLink record={record}>
          <Text>{record.hatcheries.map((hatchery) => hatchery.name).join(', ')}</Text>
        </CellLink>
      ),
    },
    {
      title: t('last_active_batch'),
      render: (_, record) => (
        <CellLink record={record}>
          <MultilineTableCell
            text={formatDate(record.lastActiveBatchAt)}
            description={[formatDate(record.lastActiveBatchAt, DateFormat.Time)]}
          />
        </CellLink>
      ),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => handleSort('lastActiveBatchAt'),
      }),
    },
  ];

  const columnsMobile: TableColumnsType<DataType> = [
    {
      render: (_, record) => (
        <CellLink record={record}>
          <AntSpace direction="vertical" size={0}>
            <MultilineTableCell text={record.name} description={[record.contactPerson]} />
            <MultilineTableCell
              text={record.hatcheries.map((hatchery) => hatchery.name).join(', ')}
              description={
                record.lastActiveBatchAt
                  ? [
                      `${t('last_active_batch')}: ${formatDate(record.lastActiveBatchAt, DateFormat.DateTime)}`,
                    ]
                  : []
              }
            />
          </AntSpace>
        </CellLink>
      ),
    },
  ];

  const pagination = usePagination(query, setQuery, data?.count);

  return (
    <AntLayout>
      <CustomersListHeader />
      <Content className={styles.content}>
        <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
          <CustomersListFilters query={query} setQuery={setQuery} />
          <AntTable
            showHeader={!xs}
            columns={xs ? columnsMobile : columns}
            dataSource={dataSource}
            rowKey={(record) => record.id}
            pagination={pagination}
            style={{ marginBottom: xs ? 100 : 0 }}
            className={styles.tableWithCellLinks}
          />
        </AntSpace>
      </Content>
    </AntLayout>
  );
};
