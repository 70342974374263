import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AuthData = { jwtToken: string; refreshToken: string };

type AuthState = {
  auth: AuthData | undefined;
  setAuth: (auth: AuthData | undefined) => void;
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      auth: undefined,
      setAuth: (auth: AuthData | undefined) => set(() => ({ auth })),
    }),
    {
      name: 'auth',
    },
  ),
);
