import { Layout, PublicLayout } from '@app/components';
import { routes } from '@app/constants';
import { BatchDetailed } from '@app/pages/Batches/BatchDetailed';
import { BatchesList } from '@app/pages/Batches/BatchesList.tsx';
import { BatchReport } from '@app/pages/Batches/Report/BatchReport';
import { DeliveryDocument } from '@app/pages/Batches/Report/DeliveryDocument';
import { CustomerDetailed } from '@app/pages/Customers/CustomerDetailed.tsx';
import { CustomersList } from '@app/pages/Customers/CustomersList';
import { Dashboard } from '@app/pages/Dashboard/Dashboard';
import { HatcheriesList } from '@app/pages/Hatcheries/HatcheriesList';
import { HatcheryDetailed } from '@app/pages/Hatcheries/HatcheryDetailed';
import { Ovoscans } from '@app/pages/Ovoscans/Ovoscans';
import { ExpiredInvitation } from '@app/pages/public/ExpiredInvitation';
import { ForgotPassword } from '@app/pages/public/ForgotPassword';
import { ForgotPasswordConfirmation } from '@app/pages/public/ForgotPasswordConfirmation';
import { ResetPassword } from '@app/pages/public/ResetPassword';
import { SignIn } from '@app/pages/public/SignIn';
import { SignUp } from '@app/pages/public/SignUp';
import { Settings } from '@app/pages/Settings/Settings';
import { PrivateRoute } from '@app/utils';
import * as Sentry from '@sentry/react';
import { ErrorPage } from '@ui/components';
import { createBrowserRouter, Navigate } from 'react-router-dom';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const publicPages = [
  {
    index: true,
    element: <Navigate to={routes.signIn} />,
  },
  {
    path: routes.signIn,
    element: <SignIn />,
  },
  {
    path: routes.signUp,
    element: <SignUp />,
  },
  {
    path: routes.resetPassword,
    element: <ResetPassword />,
  },
  {
    path: routes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: routes.expiredInvitation,
    element: <ExpiredInvitation />,
  },
  {
    path: routes.forgotPasswordConfirmation,
    element: <ForgotPasswordConfirmation />,
  },
];

const batchPages = [
  {
    index: true,
    element: <BatchesList />,
  },
  {
    path: ':batchId',
    element: <BatchDetailed />,
  },
];

const hatcheryPages = [
  {
    index: true,
    element: <HatcheriesList />,
  },
  {
    path: ':hatcheryId',
    element: <HatcheryDetailed />,
  },
];

const customerPages = [
  {
    index: true,
    element: <CustomersList />,
  },
  {
    path: ':customerId',
    element: <CustomerDetailed />,
  },
];

const appPages = [
  {
    index: true,
    element: <Navigate to={routes.dashboard} />,
  },
  {
    path: routes.dashboard,
    element: <Dashboard />,
  },
  {
    path: routes.batches.root,
    children: batchPages,
  },
  {
    path: routes.customers,
    children: customerPages,
  },
  {
    path: routes.hatcheries,
    children: hatcheryPages,
  },
  {
    path: routes.ovoscans,
    element: <Ovoscans />,
  },
  {
    path: routes.settings,
    element: <Settings />,
  },
];

export const router = (isLoggedIn: boolean) =>
  sentryCreateBrowserRouter([
    {
      path: routes.root,
      errorElement: <ErrorPage />,
      element: isLoggedIn ? <Navigate to={routes.app} /> : <PublicLayout />,
      children: publicPages,
    },

    {
      path: routes.app,
      element: <PrivateRoute />,
      children: [
        {
          element: <Layout />,
          children: appPages,
        },
      ],
    },

    {
      path: routes.batchReport,
      children: [
        {
          path: ':batchId',
          element: <BatchReport />,
        },
      ],
    },

    {
      path: routes.deliveryDocument,
      children: [
        {
          path: ':batchId',
          element: <DeliveryDocument />,
        },
      ],
    },
  ]);
