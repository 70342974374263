import { useGetSensorSets } from '@data/hooks/sensor';
import type { AddBatchValidator } from '@data/utils/validation';
import { FormItem, Input, InputNumber, Select } from '@ui/components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const AddBatchStep2 = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<AddBatchValidator>();

  const { data: sensorSets } = useGetSensorSets({ limit: 5000, offset: 0 });

  return (
    <>
      <FormItem
        required
        name="numberPlaced"
        label={t('batch.number_of_eggs_placed')}
        error={errors.numberPlaced?.message}
      >
        <Controller
          control={control}
          name="numberPlaced"
          render={({ field }) => <InputNumber style={{ width: '100%' }} {...field} />}
        />
      </FormItem>

      <FormItem
        name="expectedNonHatched"
        label={t('batch.expected_non_hatched_eggs')}
        error={errors.expectedNonHatched?.message}
      >
        <Controller
          control={control}
          name="expectedNonHatched"
          render={({ field }) => (
            <InputNumber style={{ width: '100%' }} addonAfter="%" {...field} />
          )}
        />
      </FormItem>

      <FormItem
        name="averageParentAge"
        label={t('batch.average_age_parent_stock')}
        error={errors.averageParentAge?.message}
      >
        <Controller
          control={control}
          name="averageParentAge"
          render={({ field }) => (
            <InputNumber style={{ width: '100%' }} addonAfter={t('weeks')} {...field} />
          )}
        />
      </FormItem>

      <FormItem
        name="parentFlockId"
        label={t('batch.parent_stock_flock_id')}
        error={errors.parentFlockId?.message}
      >
        <Controller
          control={control}
          name="parentFlockId"
          render={({ field }) => <Input {...field} />}
        />
      </FormItem>

      <FormItem
        required
        name="sensorSetId"
        label={t('batch.ovoscan_set')}
        error={errors.sensorSetId?.message}
      >
        <Controller
          control={control}
          name="sensorSetId"
          render={({ field }) => (
            <Select
              options={sensorSets?.items.map((set) => ({ value: set.id, label: set.name }))}
              {...field}
            />
          )}
        />
      </FormItem>
    </>
  );
};
