import {
  AuthApi,
  BatchApi,
  CustomerApi,
  HatcheryApi,
  InviteApi,
  RoleApi,
  SensorApi,
  SubscriptionApi,
  UserApi,
} from '@sdk/api';
import axios, { type AxiosResponse } from 'axios';

import axiosInterceptors from './axios-interceptors';

export const apiInstance = axiosInterceptors(
  axios.create({
    baseURL: `${import.meta.env.VITE_API_BASE_URL}`,
  }),
);

const userApi = new UserApi({ isJsonMime: () => true }, undefined, apiInstance);
const authApi = new AuthApi({ isJsonMime: () => true }, undefined, apiInstance);
const inviteApi = new InviteApi({ isJsonMime: () => true }, undefined, apiInstance);
const hatcheryApi = new HatcheryApi({ isJsonMime: () => true }, undefined, apiInstance);
const roleApi = new RoleApi({ isJsonMime: () => true }, undefined, apiInstance);
const customerApi = new CustomerApi({ isJsonMime: () => true }, undefined, apiInstance);
const batchApi = new BatchApi({ isJsonMime: () => true }, undefined, apiInstance);
const sensorApi = new SensorApi({ isJsonMime: () => true }, undefined, apiInstance);
const subscriptionApi = new SubscriptionApi({ isJsonMime: () => true }, undefined, apiInstance);

const getData = async <T>(apiCall: Promise<AxiosResponse<T>>) => {
  const response = await apiCall;
  return response.data;
};

export {
  authApi,
  batchApi,
  customerApi,
  getData,
  hatcheryApi,
  inviteApi,
  roleApi,
  sensorApi,
  subscriptionApi,
  userApi,
};
