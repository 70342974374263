import { countries, routes } from '@app/constants';
import { getLanguageOptions } from '@app/utils/helpers';
import { useCreateHatchery, useInvite } from '@data/hooks';
import { CreateHatcherySchema, type CreateHatcheryValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Language, RoleType } from '@sdk/api';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

export const AddHatcheryDrawer = ({ toggleIsOpen, ...props }: DrawerProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateHatcheryValidator>({
    mode: 'onBlur',
    resolver: zodResolver(CreateHatcherySchema),
  });

  const { mutateAsync: inviteUser, isPending: isInvitePending } = useInvite();
  const { mutateAsync: createHatchery, isPending: isCreateHatcheryPending } = useCreateHatchery();

  const onSubmit = async (values: CreateHatcheryValidator) => {
    const { email, name, country, city, address, postalCode, language } = values;
    const hatchery = await createHatchery({
      name,
      location: {
        country,
        city,
        address,
        postalCode,
      },
    });
    await inviteUser({
      email,
      hatcheryId: hatchery.id,
      type: RoleType.HatcheryAdmin,
      isContactPerson: true,
      language,
    });
    navigate(`/${routes.app}/${routes.hatcheries}/${hatchery.id}`);
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('new_hatchery')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              loading={isCreateHatcheryPending || isInvitePending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.create')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="createHatchery" layout="vertical">
        <Text className={styles.drawerLabel}>{t('general')}</Text>

        <FormItem required name="name" label={t('hatchery_name')} error={errors.name?.message}>
          <Controller control={control} name="name" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem required name="country" label={t('country')} error={errors.country?.message}>
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <Select
                showSearch
                options={countries.map((c) => ({ value: c.name, label: c.name }))}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem required name="city" label={t('city')} error={errors.city?.message}>
          <Controller control={control} name="city" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem required name="address" label={t('address')} error={errors.address?.message}>
          <Controller
            control={control}
            name="address"
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <FormItem
          required
          name="postalCode"
          label={t('postal_code')}
          error={errors.postalCode?.message}
        >
          <Controller
            control={control}
            name="postalCode"
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <Text className={styles.drawerLabel}>{t('contact_person')}</Text>

        <FormItem
          required
          name="email"
          label={t('email_address')}
          error={errors.email?.message}
          help={t('invite.we_will_use_this_email_to_invite')}
        >
          <Controller
            control={control}
            name="email"
            render={({ field }) => <Input type="email" {...field} />}
          />
        </FormItem>

        <FormItem required name="language" label={t('language')} error={errors.language?.message}>
          <Controller
            control={control}
            name="language"
            defaultValue={i18n.language as Language}
            render={({ field }) => <Select options={getLanguageOptions()} {...field} />}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
