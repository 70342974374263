import { getData, inviteApi } from '@app/utils/api/api';
import type { ConfirmInviteBody, InviteBody } from '@sdk/api';
import { useMutation } from '@tanstack/react-query';

export const useInvite = ({ onSuccess }: { onSuccess?: () => Promise<void> } = {}) =>
  useMutation({
    mutationFn: (inviteBody: InviteBody) => getData(inviteApi.createInvite({ inviteBody })),
    onSuccess,
  });

export const useConfirmInvite = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation({
    mutationFn: (confirmInviteBody: ConfirmInviteBody) =>
      getData(inviteApi.confirmInvite({ confirmInviteBody })),
    onSuccess,
  });
