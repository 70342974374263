import { PlusOutlined } from '@ant-design/icons';
import { Tabs, type TabsProps } from '@app/components/Tabs';
import { usePermissions } from '@app/utils/hooks';
import { useDrawer } from '@app/utils/hooks/useDrawer';
import { BatchViewStatusEnum } from '@sdk/api';
import { AntFlex, AntLayout, AntTypography } from '@ui/components';
import { Button } from '@ui/components';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';
import { AddBatchDrawer } from './Drawers/AddBatch/AddBatchDrawer';

export const BatchesListHeader: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, toggleIsOpen } = useDrawer();
  const { getIsNestBornAdmin, getIsHatcheryAdmin } = usePermissions();
  const isAdmin = getIsNestBornAdmin() || getIsHatcheryAdmin();

  const { Header } = AntLayout;
  const { Title } = AntTypography;

  const tabItems: TabsProps[] = [
    {
      key: BatchViewStatusEnum.Active,
      label: t('active'),
    },
    {
      key: BatchViewStatusEnum.Inactive,
      label: t('inactive'),
    },
    {
      key: BatchViewStatusEnum.Closed,
      label: t('closed'),
    },
    {
      key: 'all',
      label: t('all'),
    },
  ];

  return (
    <Header className={styles.headerWithTabs}>
      <AntFlex vertical flex={1}>
        <AntFlex align="center" justify="space-between">
          <Title level={1} className={styles.headerTitle}>
            {t('batches')}
          </Title>
          {isAdmin && (
            <Button type="primary" icon={<PlusOutlined />} onClick={toggleIsOpen}>
              {t('batch.new_batch')}
            </Button>
          )}
        </AntFlex>
        <Tabs items={tabItems} />
      </AntFlex>
      <AddBatchDrawer open={isOpen} toggleIsOpen={toggleIsOpen} />
    </Header>
  );
};
