import type { UserView } from '@sdk/api';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type UserState = {
  user: UserView | undefined;
  setUser: (user: UserView | undefined) => void;
};

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      user: undefined,
      setUser: (user: UserView | undefined) => set(() => ({ user })),
    }),
    {
      name: 'user',
    },
  ),
);
