import { getLanguageOptions } from '@app/utils/helpers';
import { useInvite } from '@data/hooks';
import { InviteSchema, type InviteValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Language, RoleType } from '@sdk/api';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntSwitch,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface AddEmployeeDrawerProps extends DrawerProps {
  onSuccess?: () => Promise<void>;
}

export const AddEmployeeDrawer = ({
  toggleIsOpen,
  onSuccess,
  ...props
}: AddEmployeeDrawerProps) => {
  const { t, i18n } = useTranslation();
  const { customerId, hatcheryId } = useParams();
  const { Text } = AntTypography;

  const { mutate: invite, isPending } = useInvite({ onSuccess });

  const onSubmit = (values: InviteValidator) => {
    invite({ ...values, customerId, hatcheryId, isContactPerson: false });
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<InviteValidator>({
    mode: 'onBlur',
    resolver: zodResolver(InviteSchema),
  });

  const isHatchery = !!hatcheryId;

  const toggleSwitch = (value: boolean) => {
    const type = isHatchery
      ? value
        ? RoleType.HatcheryAdmin
        : RoleType.HatcheryUser
      : value
        ? RoleType.CustomerAdmin
        : RoleType.CustomerUser;
    setValue('type', type);
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('invite.new_employee')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              style={{ minWidth: 100 }}
              onClick={handleSubmit(onSubmit)}
              loading={isPending}
              disabled={!isValid}
            >
              {t('actions.invite')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="inviteEmployee" layout="vertical">
        <AntSpace direction="vertical" size="middle" style={{ width: '100%' }}>
          <FormItem
            required
            name="email"
            label={t('email_address')}
            error={errors.email?.message}
            help={t('invite.we_will_use_this_email_to_invite')}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => <Input type="email" {...field} />}
            />
          </FormItem>

          <FormItem required name="language" label={t('language')} error={errors.language?.message}>
            <Controller
              control={control}
              name="language"
              defaultValue={i18n.language as Language}
              render={({ field }) => <Select options={getLanguageOptions()} {...field} />}
            />
          </FormItem>

          <FormItem name="type" label={t('admin_permissions.admin')}>
            <Controller
              control={control}
              name="type"
              defaultValue={isHatchery ? RoleType.HatcheryUser : RoleType.CustomerUser}
              render={({ field }) => (
                <AntSwitch
                  {...field}
                  value={
                    isHatchery
                      ? field.value === RoleType.HatcheryAdmin
                      : field.value === RoleType.CustomerAdmin
                  }
                  onChange={toggleSwitch}
                />
              )}
            />
          </FormItem>
        </AntSpace>
      </AntForm>
    </Drawer>
  );
};
