import { AntConfigProvider } from '@ui/components';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import type { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface TabsProps extends MenuItemType {
  key: string;
  label: string;
}

export const Tabs = ({ items }: { items: TabsProps[] }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams({ tab: items[0].key }, { replace: true });
    }
  }, [items, searchParams, setSearchParams]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setSearchParams({ tab: key });
  };

  const selectedTab = searchParams.get('tab') || items[0].key;

  return (
    <AntConfigProvider
      theme={{
        components: {
          Menu: {
            colorSplit: 'none',
          },
        },
      }}
    >
      <Menu
        onClick={onClick}
        selectedKeys={[selectedTab]}
        mode="horizontal"
        items={items}
        style={{ lineHeight: 4 }}
      />
    </AntConfigProvider>
  );
};
