import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import {
  type AlarmPayloadView,
  AlarmPayloadViewTypeEnum,
  type BatchEventView,
  BatchEventViewTypeEnum,
} from '@sdk/api';
import { colors } from '@ui/constants';
import { Message, StatusCO2, StatusCold, StatusHot } from '@ui/icons';

export const getIcon = (event: BatchEventView) => {
  const { type, alarmPayload } = event;
  const size = { width: 18, height: 18 };
  const alarmIcons = {
    [AlarmPayloadViewTypeEnum.AirTempTooHot]: <StatusHot color={colors.red600} {...size} />,
    [AlarmPayloadViewTypeEnum.AirTempTooCold]: <StatusCold color={colors.blue500} {...size} />,
    [AlarmPayloadViewTypeEnum.Co2TooHigh]: <StatusCO2 color={colors.red600} {...size} />,
    [AlarmPayloadViewTypeEnum.NoData]: <StopOutlined style={{ color: colors.red600 }} />,
  };

  switch (type) {
    case BatchEventViewTypeEnum.Comment:
      return <Message color={colors.grey500} {...size} />;
    case BatchEventViewTypeEnum.Closure:
      return <CheckCircleOutlined style={{ color: colors.green600 }} />;
    case BatchEventViewTypeEnum.Alarm:
      return !!alarmPayload && alarmIcons[alarmPayload.type];
  }
};

export const getStyles = (type?: BatchEventViewTypeEnum, alarmPayload?: AlarmPayloadView) => {
  const getAlarmStyles = () => {
    if (!alarmPayload) return;
    switch (alarmPayload.type) {
      case AlarmPayloadViewTypeEnum.AirTempTooHot:
      case AlarmPayloadViewTypeEnum.Co2TooHigh:
      case AlarmPayloadViewTypeEnum.NoData:
        return {
          color: colors.red600,
          borderColor: colors.red300,
          backgroundColor: colors.red100,
        };
      case AlarmPayloadViewTypeEnum.AirTempTooCold:
        return {
          color: colors.blue500,
          borderColor: colors.blue300,
          backgroundColor: colors.blue100,
        };
    }
  };

  switch (type) {
    case BatchEventViewTypeEnum.Closure:
      return {
        color: colors.green600,
        borderColor: colors.green300,
        backgroundColor: colors.green100,
      };
    case BatchEventViewTypeEnum.Alarm:
      return getAlarmStyles();
  }
};
