import { AntLayout, AntTypography } from '@ui/components';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';

export const DashboardHeader = () => {
  const { t } = useTranslation();
  const { Header } = AntLayout;
  const { Title } = AntTypography;

  return (
    <Header className={styles.header}>
      <Title level={1} className={styles.headerTitle}>
        {t('dashboard')}
      </Title>
    </Header>
  );
};
