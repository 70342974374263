import { AntCard, AntFlex, AntTypography } from '@ui/components';
import { colors } from '@ui/constants';
import { LockClock } from '@ui/icons';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const ExpiredInvitation: React.FC = () => {
  const { t } = useTranslation();
  const { Title, Text } = AntTypography;

  return (
    <AntCard classNames={{ body: styles.card }}>
      <AntFlex vertical justify="center" align="center">
        <LockClock className={styles.expiredIcon} />
        <Title level={2}>{t('expired_invitation.title')}</Title>
        <Text style={{ color: colors.grey500 }}>
          {t('expired_invitation.please_request_a_new_link')}
        </Text>
      </AntFlex>
    </AntCard>
  );
};
