import 'react-international-phone/style.css';

import { SearchOutlined } from '@ant-design/icons';
import { colors } from '@ui/constants';
import { Lock, Person } from '@ui/icons';
import {
  Input as AntInput,
  InputNumber as AntInputNumber,
  type InputNumberProps as AntInputNumberProps,
  type InputProps as AntInputProps,
} from 'antd';
import { getCountryForTimezone } from 'countries-and-timezones';
import { useTranslation } from 'react-i18next';
import { PhoneInput as IntPhoneInput, type PhoneInputProps } from 'react-international-phone';

import styles from './styles.module.css';

interface InputProps extends AntInputProps {
  type?: 'text' | 'password' | 'email' | 'search';
}

export const Input = (props: InputProps) => {
  const { t } = useTranslation();
  const size = 'large';

  switch (props.type) {
    case 'password':
      return <AntInput.Password size={size} prefix={<Lock />} {...props} />;
    case 'email':
      return <AntInput size={size} prefix={<Person />} {...props} />;
    case 'search':
      return (
        <AntInput
          size={size}
          suffix={<SearchOutlined style={{ color: colors.grey300 }} />}
          placeholder={t('search')}
          {...props}
        />
      );
    default:
      return <AntInput size={size} {...props} />;
  }
};

export const InputNumber = (props: AntInputNumberProps) => (
  <AntInputNumber
    size="large"
    controls={false}
    {...props}
    formatter={(value) => (value as string).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  />
);

export const PhoneInput = (props: AntInputProps & PhoneInputProps) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultCountry = getCountryForTimezone(timeZone)?.id.toLocaleLowerCase() || 'be';

  return (
    <IntPhoneInput
      className={styles.phoneContainer}
      inputClassName={styles.phoneInput}
      defaultCountry={defaultCountry}
      disableDialCodePrefill
      preferredCountries={['be', 'nl', 'de', 'fr', 'gb']}
      forceDialCode
      {...props}
    />
  );
};
