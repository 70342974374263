import { type BatchEventView, BatchEventViewTypeEnum, type ListBatchEventView } from '@sdk/api';
import type { TFunction } from 'i18next';

const sortByCreationTime = (a: BatchEventView, b: BatchEventView) =>
  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

export const getCommentsBlocks = (t: TFunction, events?: ListBatchEventView) => {
  const comments = events?.items
    .filter(
      (event) =>
        event.type === BatchEventViewTypeEnum.Comment ||
        event.type === BatchEventViewTypeEnum.Closure,
    )
    .sort(sortByCreationTime)
    .map((event) => ({
      key: event.id,
      date: event.createdAt,
      user: event.user?.fullName,
      text:
        event.type === BatchEventViewTypeEnum.Closure
          ? `${t('toast.batch_closed')}: ${event.text}`
          : event.text,
    }));

  return comments ?? [];
};

export const getNotifications = (events?: ListBatchEventView) => {
  const notifications = events?.items
    .filter((e) => e.type !== BatchEventViewTypeEnum.Comment)
    .sort(sortByCreationTime);

  return notifications ?? [];
};
