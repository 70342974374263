import type { BatchApiGetBatchesExportRequest } from '@sdk/api';

import { apiInstance } from '../api/api';

export const saveBlob = async (
  url: string,
  filename: string,
  params?: BatchApiGetBatchesExportRequest,
): Promise<void> => {
  if (!url) return;

  try {
    const { data: blob } = await apiInstance.get<Blob>(url, {
      responseType: 'blob',
      params,
    });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.setAttribute('download', filename);
    anchor.click();
  } catch (error) {
    console.log(error);
  }
};
