import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type QueryType = { limit: number; offset: number };

export const usePagination = (
  query: QueryType,
  setQuery: Dispatch<SetStateAction<QueryType>>,
  total: number = 0,
) => {
  const { t } = useTranslation();
  return {
    total,
    showTotal: (total: number) => t('total_results', { total }),
    pageSize: query.limit,
    current: query.offset / query.limit + 1,
    onChange: (page: number, size: number) =>
      setQuery({ ...query, limit: size, offset: size * (page - 1) }),
    hideOnSinglePage: true,
  };
};
