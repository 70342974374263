const COLOR_YELLOW_GREEN_200 = '#B4D92E';
const COLOR_YELLOW_GREEN_500 = '#A0C51A';
const COLOR_YELLOW_GREEN_700 = '#8CB106';

const COLOR_GREEN_100 = '#F6FFED';
const COLOR_GREEN_200 = '#D9F7BE';
const COLOR_GREEN_300 = '#B7EB8F';
const COLOR_GREEN_600 = '#52C41A';
const COLOR_GREEN_700 = '#389E0D';

const COLOR_TEAL_200 = '#F0F7F6';
const COLOR_TEAL_500 = '#194B41';
const COLOR_TEAL_700 = '#023229';

const COLOR_GREY_100 = '#0000000F';
const COLOR_GREY_200 = '#00000040';
const COLOR_GREY_300 = '#00000073';
const COLOR_GREY_500 = '#000000A6';
const COLOR_GREY_700 = '#000000E0';

const COLOR_BLUE_100 = '#E6F4FF';
const COLOR_BLUE_200 = '#BAE0FF';
const COLOR_BLUE_300 = '#91CAFF';
const COLOR_BLUE_400 = '#69B1FF';
const COLOR_BLUE_500 = '#4096FF';
const COLOR_BLUE_600 = '#1677FF';

const COLOR_RED_100 = '#FFF1F0';
const COLOR_RED_200 = '#FFCCC7';
const COLOR_RED_300 = '#FFA39E';
const COLOR_RED_400 = '#FF7875';
const COLOR_RED_500 = '#FF4D4F';
const COLOR_RED_600 = '#F5222D';

const COLOR_WHITE = '#FFFFFF';

const COLOR_YELLOW_100 = '#FEFFE6';
const COLOR_YELLOW_200 = '#FFFFB8';
const COLOR_YELLOW_400 = '#FFF566';
const COLOR_YELLOW_600 = '#FADB14';

const COLOR_PURPLE_200 = '#EFDBFF';
const COLOR_PURPLE_400 = '#B37FEB';

export const colors = {
  yellowGreen200: COLOR_YELLOW_GREEN_200,
  yellowGreen500: COLOR_YELLOW_GREEN_500,
  yellowGreen700: COLOR_YELLOW_GREEN_700,

  green100: COLOR_GREEN_100,
  green200: COLOR_GREEN_200,
  green300: COLOR_GREEN_300,
  green600: COLOR_GREEN_600,
  green700: COLOR_GREEN_700,

  teal200: COLOR_TEAL_200,
  teal500: COLOR_TEAL_500,
  teal700: COLOR_TEAL_700,

  grey100: COLOR_GREY_100,
  grey200: COLOR_GREY_200,
  grey300: COLOR_GREY_300,
  grey500: COLOR_GREY_500,
  grey700: COLOR_GREY_700,

  blue100: COLOR_BLUE_100,
  blue200: COLOR_BLUE_200,
  blue300: COLOR_BLUE_300,
  blue400: COLOR_BLUE_400,
  blue500: COLOR_BLUE_500,
  blue600: COLOR_BLUE_600,

  red100: COLOR_RED_100,
  red200: COLOR_RED_200,
  red300: COLOR_RED_300,
  red400: COLOR_RED_400,
  red500: COLOR_RED_500,
  red600: COLOR_RED_600,

  white: COLOR_WHITE,

  yellow100: COLOR_YELLOW_100,
  yellow200: COLOR_YELLOW_200,
  yellow400: COLOR_YELLOW_400,
  yellow600: COLOR_YELLOW_600,

  purple200: COLOR_PURPLE_200,
  purple400: COLOR_PURPLE_400,
};
