import { routes } from '@app/constants';
import { useLogin } from '@data/hooks';
import { LoginSchema, type LoginValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AntCard,
  AntCheckbox,
  AntFlex,
  AntForm,
  AntTypography,
  Button,
  FormItem,
  Input,
} from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const { Title } = AntTypography;
  const navigate = useNavigate();

  const { mutate: login, isPending } = useLogin({
    onSuccess: () => navigate(`/${routes.app}/${routes.dashboard}`),
  });

  const onSubmit = (values: LoginValidator) => login(values);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValidator>({
    mode: 'onBlur',
    resolver: zodResolver(LoginSchema),
  });

  return (
    <AntCard classNames={{ body: styles.card }}>
      <Title level={2}>{t('login')}</Title>
      <AntForm
        name="signIn"
        initialValues={{ remember: true }}
        onFinish={handleSubmit(onSubmit)}
        autoComplete="off"
        layout="vertical"
        data-testid="signInForm"
      >
        <FormItem required name="email" label={t('email_address')} error={errors.email?.message}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => <Input type="email" data-testid="emailInput" {...field} />}
          />
        </FormItem>

        <FormItem required name="password" label={t('password')} error={errors.password?.message}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => <Input type="password" data-testid="passwordInput" {...field} />}
          />
        </FormItem>

        <AntFlex justify="space-between" style={{ marginBottom: 15 }}>
          <AntCheckbox>{t('remember_me')}</AntCheckbox>
          <Link to={`/${routes.forgotPassword}`} className={styles.link}>
            {t('forgot_your_password.title')}
          </Link>
        </AntFlex>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.button}
          loading={isPending}
          data-testid="logInButton"
        >
          {t('actions.log_in')}
        </Button>
      </AntForm>
    </AntCard>
  );
};
