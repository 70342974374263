import { filterOption } from '@app/utils/helpers';
import { sensorKeys } from '@data/constants';
import { useGetHatcheries } from '@data/hooks';
import { useUpdateSensorSet } from '@data/hooks/sensor';
import { UpdateSensorSetSchema, type UpdateSensorSetValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SensorApiGetSensorSetsRequest } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Drawer,
  type DrawerProps,
  FormItem,
  Input,
  Select,
} from '@ui/components';
import { Button } from '@ui/components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { SensorSetDataType } from './SetsList';

interface EditSetDrawerProps extends DrawerProps {
  set: SensorSetDataType;
  query: SensorApiGetSensorSetsRequest;
}

export const EditSetDrawer = ({ toggleIsOpen, set, query, ...props }: EditSetDrawerProps) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateSensorSetValidator>({
    mode: 'onBlur',
    resolver: zodResolver(UpdateSensorSetSchema),
    values: {
      name: set?.name ?? '',
      hatcheryIds: set?.hatcheries.map((h) => h.id),
    },
  });

  const { data: hatcheries } = useGetHatcheries({ limit: 1000, offset: 0 });

  const { mutateAsync: updateSensorSet, isPending } = useUpdateSensorSet();
  const queryClient = useQueryClient();

  const onSubmit = async (values: UpdateSensorSetValidator) => {
    if (!set) return;
    const { name, hatcheryIds } = values;
    await updateSensorSet({
      id: set.id,
      updateSensorSetBody: { name, hatcheryIds },
    });
    await queryClient.invalidateQueries({ queryKey: sensorKeys.set(query) });
    toggleIsOpen();
    toast(t('toast.item_updated', { item: t('set') }), { type: 'success' });
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('edit_set')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid}
              loading={isPending}
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="updateSensorSet" layout="vertical">
        <FormItem required name="name" label={t('name')} error={errors.name?.message}>
          <Controller control={control} name="name" render={({ field }) => <Input {...field} />} />
        </FormItem>

        <FormItem
          required
          name="hatcheryIds"
          label={t('select_hatchery')}
          error={errors.hatcheryIds?.message}
        >
          <Controller
            control={control}
            name="hatcheryIds"
            render={({ field }) => (
              <Select
                showSearch
                mode="multiple"
                filterOption={filterOption}
                options={hatcheries?.items.map((h) => ({ value: h.id, label: h.name }))}
                {...field}
              />
            )}
          />
        </FormItem>
      </AntForm>
    </Drawer>
  );
};
