import { changeLanguage, getLanguageOptions } from '@app/utils/helpers';
import { useDrawer } from '@app/utils/hooks/useDrawer';
import { useUpdateUser } from '@data/hooks/user';
import { useUserStore } from '@data/storage';
import { SettingsSchema, type SettingsValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { MeasurementSystem, TemperatureScale, type UserView } from '@sdk/api';
import {
  AntCard,
  AntCol,
  AntForm,
  AntLayout,
  AntRadio,
  AntSpace,
  AntTypography,
  Button,
  FormItem,
  Input,
  PhoneInput,
  Select,
} from '@ui/components';
import { fontSize } from '@ui/constants';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import styles from '../styles.module.css';
import { SettingsDrawer } from './SettingsDrawer';
import { SettingsHeader } from './SettingsHeader';

export const Settings = () => {
  const { t, i18n } = useTranslation();
  const { Title, Text } = AntTypography;
  const { isOpen, toggleIsOpen } = useDrawer();
  const { user, setUser } = useUserStore();

  const { Content } = AntLayout;

  const temperatureOptions = [
    {
      value: TemperatureScale.Celsius,
      label: (
        <Text>
          {t('temp_scale.celsius')} <sup>°C</sup>
        </Text>
      ),
    },
    {
      value: TemperatureScale.Fahrenheit,
      label: (
        <Text>
          {t('temp_scale.fahrenheit')} <sup>°F</sup>
        </Text>
      ),
    },
  ];

  const measurementOptions = [
    {
      value: MeasurementSystem.Metric,
      label: (
        <Text>
          {t('measurement_system.metric')} <sup>m</sup>
        </Text>
      ),
    },
    {
      value: MeasurementSystem.Imperial,
      label: (
        <Text>
          {t('measurement_system.imperial')} <sup>ft</sup>
        </Text>
      ),
    },
  ];

  const { mutate: updateUser, isPending } = useUpdateUser({
    onSuccess: (user: UserView) => {
      setUser(user);
      if (user.language) changeLanguage(user.language, i18n);
      toast(t('toast.settings_updated'), { type: 'success' });
    },
  });

  const onSubmit = (values: SettingsValidator) => {
    if (user) {
      updateUser({
        id: user.id,
        body: {
          ...values,
          eggTempScale: values.eggTempScale as TemperatureScale,
          airTempScale: values.airTempScale as TemperatureScale,
          measurementSystem: values.measurementSystem as MeasurementSystem,
        },
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<SettingsValidator>({
    mode: 'onBlur',
    resolver: zodResolver(SettingsSchema),
  });

  return (
    user && (
      <AntLayout>
        <SettingsHeader
          isLoading={isPending}
          isDisabled={!isDirty}
          handleSubmit={handleSubmit(onSubmit)}
        />

        <Content className={styles.content}>
          <AntForm name="userSettings" layout="vertical" style={{ width: '100%' }}>
            <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
              <AntCard bordered={false}>
                <AntCol xs={24} md={16} lg={12} xl={8} xxl={6}>
                  <Title level={2} style={{ fontSize: fontSize.xl }}>
                    {t('general')}
                  </Title>
                  <FormItem
                    name="firstName"
                    label={t('first_name')}
                    error={errors.firstName?.message}
                  >
                    <Controller
                      control={control}
                      name="firstName"
                      defaultValue={user.firstName}
                      render={({ field }) => <Input type="text" {...field} />}
                    />
                  </FormItem>

                  <FormItem name="lastName" label={t('last_name')} error={errors.lastName?.message}>
                    <Controller
                      control={control}
                      name="lastName"
                      defaultValue={user.lastName}
                      render={({ field }) => <Input type="text" {...field} />}
                    />
                  </FormItem>

                  <FormItem name="email" label={t('email_address')} error={errors.email?.message}>
                    <Controller
                      control={control}
                      name="email"
                      defaultValue={user.email}
                      render={({ field }) => <Input type="text" disabled {...field} />}
                    />
                  </FormItem>

                  <FormItem
                    name="mobilePhone"
                    label={t('mobile_phone')}
                    error={errors.mobilePhone?.message}
                  >
                    <Controller
                      control={control}
                      name="mobilePhone"
                      defaultValue={user.mobilePhone ?? ''}
                      render={({ field }) => <PhoneInput {...field} />}
                    />
                  </FormItem>
                  <FormItem
                    name="fixedPhone"
                    label={t('fixed_phone')}
                    error={errors.fixedPhone?.message}
                  >
                    <Controller
                      control={control}
                      name="fixedPhone"
                      defaultValue={user.fixedPhone ?? ''}
                      render={({ field }) => <PhoneInput {...field} />}
                    />
                  </FormItem>

                  <Text style={{ fontSize: fontSize.md, marginBottom: 6, display: 'block' }}>
                    {t('password')}
                  </Text>
                  <Button type="primary" onClick={toggleIsOpen}>
                    {t('actions.change_password')}
                  </Button>
                </AntCol>
              </AntCard>

              <AntCard bordered={false}>
                <AntCol xs={24} md={16} lg={12} xl={8} xxl={6}>
                  <Title level={2} style={{ fontSize: fontSize.xl }}>
                    {t('preferences')}
                  </Title>

                  <FormItem name="language" label={t('language')} error={errors.language?.message}>
                    <Controller
                      control={control}
                      name="language"
                      defaultValue={user.language}
                      render={({ field }) => <Select options={getLanguageOptions()} {...field} />}
                    />
                  </FormItem>

                  <FormItem name="eggTempScale" label={t('temp_scale.egg')}>
                    <Controller
                      control={control}
                      name="eggTempScale"
                      defaultValue={user.eggTempScale}
                      render={({ field }) => (
                        <AntRadio.Group {...field}>
                          {temperatureOptions.map((option) => (
                            <AntRadio key={option.value} value={option.value}>
                              {option.label}
                            </AntRadio>
                          ))}
                        </AntRadio.Group>
                      )}
                    />
                  </FormItem>

                  <FormItem name="airTempScale" label={t('temp_scale.air')}>
                    <Controller
                      control={control}
                      name="airTempScale"
                      defaultValue={user.airTempScale}
                      render={({ field }) => (
                        <AntRadio.Group {...field}>
                          {temperatureOptions.map((option) => (
                            <AntRadio key={option.value} value={option.value}>
                              {option.label}
                            </AntRadio>
                          ))}
                        </AntRadio.Group>
                      )}
                    />
                  </FormItem>

                  <FormItem name="measurementSystem" label={t('measurement_system.label')}>
                    <Controller
                      control={control}
                      name="measurementSystem"
                      defaultValue={user.measurementSystem}
                      render={({ field }) => (
                        <AntRadio.Group {...field}>
                          {measurementOptions.map((option) => (
                            <AntRadio key={option.value} value={option.value}>
                              {option.label}
                            </AntRadio>
                          ))}
                        </AntRadio.Group>
                      )}
                    />
                  </FormItem>
                </AntCol>
              </AntCard>
            </AntSpace>
          </AntForm>
        </Content>
        <SettingsDrawer open={isOpen} toggleIsOpen={toggleIsOpen} userId={user.id} />
      </AntLayout>
    )
  );
};
