import type { Token } from '@app/utils/types';
import { useAuthStore } from '@data/storage';
import { type CustomerDetailView, RoleType } from '@sdk/api';
import { jwtDecode } from 'jwt-decode';

export const usePermissions = () => {
  const { auth } = useAuthStore();
  const { roles } = jwtDecode<Token>(auth?.jwtToken as string);

  const getIsNestBornAdmin = () => roles.some((role) => role.type === RoleType.NestbornAdmin);

  const getIsCustomerAdmin = (customerId?: string) =>
    customerId
      ? roles.some((role) => role.type === RoleType.CustomerAdmin && role.customerId === customerId) // admin for a specific customer
      : roles.some((role) => role.type === RoleType.CustomerAdmin); // admin for any customer

  const getIsCustomerUser = (customerId?: string) =>
    customerId
      ? roles.some((role) => role.type === RoleType.CustomerUser && role.customerId === customerId)
      : roles.some((role) => role.type === RoleType.CustomerUser);

  const getIsHatcheryAdmin = (hatcheryId?: string) =>
    hatcheryId
      ? roles.some((role) => role.type === RoleType.HatcheryAdmin && role.hatcheryId === hatcheryId)
      : roles.some((role) => role.type === RoleType.HatcheryAdmin);

  const getIsHatcheryUser = (hatcheryId?: string) =>
    hatcheryId
      ? roles.some((role) => role.type === RoleType.HatcheryUser && role.hatcheryId === hatcheryId)
      : roles.some((role) => role.type === RoleType.HatcheryUser);

  const getIsCustomerHatcheryAdmin = (customer?: CustomerDetailView) =>
    !!customer &&
    roles.some(
      (role) =>
        role.type === RoleType.HatcheryAdmin &&
        role.hatcheryId &&
        customer.hatcheries.map((h) => h.id).includes(role.hatcheryId),
    );

  const isNestBornAdmin = getIsNestBornAdmin();
  const isAnyHatcheryAdmin = getIsHatcheryAdmin();
  const isAnyCustomerAdmin = getIsCustomerAdmin();
  const isAnyCustomerUser = getIsCustomerUser();
  const isAnyHatcheryUser = getIsHatcheryUser();

  return {
    roles,
    getIsNestBornAdmin,
    getIsCustomerAdmin,
    getIsHatcheryAdmin,
    getIsCustomerHatcheryAdmin,
    getIsCustomerUser,
    getIsHatcheryUser,
    isNestBornAdmin,
    isAnyHatcheryAdmin,
    isAnyCustomerAdmin,
    isAnyCustomerUser,
    isAnyHatcheryUser,
  };
};
