import { customerApi, getData } from '@app/utils/api/api';
import { customerKeys } from '@data/constants';
import type {
  CustomerApiCreateCustomerLocationRequest,
  CustomerApiCreateHouseRequest,
  CustomerApiDeleteCustomerLocationRequest,
  CustomerApiDeleteCustomerRequest,
  CustomerApiDeleteHouseRequest,
  CustomerApiGetCustomerByIdRequest,
  CustomerApiGetCustomersRequest,
  CustomerApiGetCustomerSubscriptionsRequest,
  CustomerApiUpdateCustomerLocationRequest,
  CustomerApiUpdateCustomerRequest,
  CustomerApiUpdateHouseRequest,
  CustomerBody,
  ListCustomerView,
} from '@sdk/api';
import {
  type UndefinedInitialDataOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

export const useGetCustomers = (
  params: CustomerApiGetCustomersRequest,
  queryOptions: Partial<UndefinedInitialDataOptions<ListCustomerView>> | undefined = {},
) =>
  useQuery({
    queryKey: customerKeys.list(params),
    queryFn: () => getData(customerApi.getCustomers(params)),
    ...queryOptions,
  });

export const useCreateCustomer = () =>
  useMutation({
    mutationFn: (customerBody: CustomerBody) =>
      getData(customerApi.createCustomer({ customerBody })),
  });

export const useGetCustomerById = ({ id }: CustomerApiGetCustomerByIdRequest) =>
  useQuery({
    queryKey: customerKeys.detail(id),
    queryFn: () => getData(customerApi.getCustomerById({ id })),
  });

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, updateCustomerBody }: CustomerApiUpdateCustomerRequest) =>
      getData(customerApi.updateCustomer({ id, updateCustomerBody })),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(customerKeys.detail(variables.id), data);
    },
  });
};
export const useDeleteCustomer = () =>
  useMutation({
    mutationFn: ({ id }: CustomerApiDeleteCustomerRequest) =>
      getData(customerApi.deleteCustomer({ id })),
  });

export const useCreateCustomerLocation = () =>
  useMutation({
    mutationFn: ({ id, customerLocationBody }: CustomerApiCreateCustomerLocationRequest) =>
      getData(customerApi.createCustomerLocation({ id, customerLocationBody })),
  });

export const useUpdateCustomerLocation = () =>
  useMutation({
    mutationFn: ({
      id,
      customerLocationId,
      updateCustomerLocationBody,
    }: CustomerApiUpdateCustomerLocationRequest) =>
      getData(
        customerApi.updateCustomerLocation({ id, customerLocationId, updateCustomerLocationBody }),
      ),
  });

export const useDeleteCustomerLocation = () =>
  useMutation({
    mutationFn: ({ id, customerLocationId }: CustomerApiDeleteCustomerLocationRequest) =>
      getData(customerApi.deleteCustomerLocation({ id, customerLocationId })),
  });

export const useCreateHouse = () =>
  useMutation({
    mutationFn: ({ id, customerLocationId, houseBody }: CustomerApiCreateHouseRequest) =>
      getData(customerApi.createHouse({ id, customerLocationId, houseBody })),
  });

export const useUpdateHouse = () =>
  useMutation({
    mutationFn: ({
      id,
      customerLocationId,
      houseId,
      updateHouseBody,
    }: CustomerApiUpdateHouseRequest) =>
      getData(customerApi.updateHouse({ id, customerLocationId, houseId, updateHouseBody })),
  });

export const useDeleteHouse = () =>
  useMutation({
    mutationFn: ({ id, customerLocationId, houseId }: CustomerApiDeleteHouseRequest) =>
      getData(customerApi.deleteHouse({ id, customerLocationId, houseId })),
  });

export const useGetCustomerSubscriptions = ({ id }: CustomerApiGetCustomerSubscriptionsRequest) =>
  useQuery({
    queryKey: customerKeys.subscription(id),
    queryFn: () => getData(customerApi.getCustomerSubscriptions({ id })),
  });
