import { BatchTabs } from '@app/constants/enums';
import { useDrawer } from '@app/utils/hooks';
import { useCloseBatch } from '@data/hooks';
import { CloseBatchSchema, type CloseBatchValidator } from '@data/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import type { BatchDetailView } from '@sdk/api';
import {
  AntFlex,
  AntForm,
  AntSpace,
  AntTypography,
  Button,
  Drawer,
  type DrawerProps,
  Modal,
} from '@ui/components';
import { FormItem, InputNumber } from '@ui/components';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface CloseBatchDrawerProps extends DrawerProps {
  batch?: BatchDetailView;
}
export const CloseBatchDrawer = ({ batch, toggleIsOpen, ...props }: CloseBatchDrawerProps) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<CloseBatchValidator>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen: isModalOpen, toggleIsOpen: toggleModal } = useDrawer();

  const { Text } = AntTypography;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CloseBatchValidator>({
    mode: 'onBlur',
    resolver: zodResolver(CloseBatchSchema),
    values: {
      numberPlaced: batch?.numberPlaced,
      numberNonHatched: batch?.numberNonHatched,
      selectionDay1: batch?.selectionDay1,
      selectionDay7: batch?.selectionDay7,
      mortalityDay7: batch?.mortalityDay7,
    },
  });

  const { mutateAsync: closeBatch } = useCloseBatch();

  const onSubmit = (closeBatchBody: CloseBatchValidator) => {
    setValues(closeBatchBody);
    toggleModal();
  };

  const handleCloseBatch = async () => {
    if (!batch) return;
    await closeBatch({ id: batch.id, closeBatchBody: values });
    toggleModal();
    toggleIsOpen();
    if (searchParams.get('tab') !== BatchTabs.Status) {
      setSearchParams({ tab: BatchTabs.Status });
    }
    toast(t('toast.batch_closed'), { type: 'success' });
  };

  const numberPlaced = watch('numberPlaced');
  const numberNonHatched = watch('numberNonHatched');
  const selectionDay1 = watch('selectionDay1');
  const selectionDay7 = watch('selectionDay7');
  const mortalityDay7 = watch('mortalityDay7');

  const getPercentage = (value: number | null | undefined, denominator = numberPlaced) => {
    if (!value || !denominator) return ' ';
    return ((value / denominator) * 100).toFixed(2) + '%';
  };

  const getChicksDay1 = () =>
    numberPlaced && numberPlaced - (numberNonHatched ?? 0) - (selectionDay1 ?? 0);

  const getChicksDay7 = () => {
    const chicksDay1 = getChicksDay1();
    return chicksDay1 && chicksDay1 - (selectionDay7 ?? 0) - (mortalityDay7 ?? 0);
  };

  return (
    <Drawer
      title={
        <AntFlex align="center" justify="space-between">
          <Text>{t('actions.close_batch')}</Text>
          <AntSpace>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              style={{ minWidth: 100 }}
            >
              {t('actions.save_and_close')}
            </Button>
          </AntSpace>
        </AntFlex>
      }
      onClose={toggleIsOpen}
      {...props}
    >
      <AntForm name="closeBatch" layout="vertical">
        <FormItem
          name="numberPlaced"
          label={t('batch.number_of_eggs_placed')}
          error={errors.numberPlaced?.message}
        >
          <Controller
            control={control}
            name="numberPlaced"
            render={({ field }) => <InputNumber style={{ width: '100%' }} {...field} />}
          />
        </FormItem>

        <FormItem
          name="numberNonHatched"
          label={t('batch.non_hatched_eggs')}
          error={errors.numberNonHatched?.message}
        >
          <Controller
            control={control}
            name="numberNonHatched"
            render={({ field }) => (
              <InputNumber
                style={{ width: '100%' }}
                suffix={getPercentage(numberNonHatched)}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem
          name="selectionDay1"
          label={t('batch.selection_day_1')}
          error={errors.selectionDay1?.message}
        >
          <Controller
            control={control}
            name="selectionDay1"
            render={({ field }) => (
              <InputNumber
                style={{ width: '100%' }}
                suffix={getPercentage(selectionDay1)}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem label={t('batch.number_of_chicks_day_1')}>
          <InputNumber
            style={{ width: '100%' }}
            suffix={getPercentage(getChicksDay1())}
            value={getChicksDay1()}
            disabled
          />
        </FormItem>

        <FormItem
          name="mortalityDay7"
          label={t('batch.mortality_day_7')}
          error={errors.mortalityDay7?.message}
        >
          <Controller
            control={control}
            name="mortalityDay7"
            render={({ field }) => (
              <InputNumber
                style={{ width: '100%' }}
                suffix={getPercentage(mortalityDay7, getChicksDay1())}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem
          name="selectionDay7"
          label={t('batch.selection_day_7')}
          error={errors.selectionDay7?.message}
        >
          <Controller
            control={control}
            name="selectionDay7"
            render={({ field }) => (
              <InputNumber
                style={{ width: '100%' }}
                suffix={getPercentage(selectionDay7, getChicksDay1())}
                {...field}
              />
            )}
          />
        </FormItem>

        <FormItem label={t('batch.number_of_chicks_day_7')}>
          <InputNumber
            style={{ width: '100%' }}
            suffix={getPercentage(getChicksDay7(), getChicksDay1())}
            value={getChicksDay7()}
            disabled
          />
        </FormItem>

        <FormItem
          name="conclusion"
          label={t('batch.conclusion')}
          error={errors.conclusion?.message}
        >
          <Controller
            control={control}
            name="conclusion"
            render={({ field }) => <TextArea showCount maxLength={250} {...field} />}
          />
        </FormItem>
      </AntForm>

      <Modal
        title={t('close_batch_modal.title')}
        cancelText={t('close_batch_modal.no')}
        okText={t('close_batch_modal.yes')}
        open={isModalOpen}
        onOk={handleCloseBatch}
        onCancel={toggleModal}
        isDanger
      >
        <Text>{t('close_batch_modal.description')}</Text>
      </Modal>
    </Drawer>
  );
};
