import {
  CountryFilters,
  CountrySelector,
  HatcheryFilters,
  HatcherySelector,
} from '@app/components/Filters';
import { useTable } from '@app/utils/hooks';
import { useGetHatcheries } from '@data/hooks';
import type { CustomerApiGetCustomersRequest } from '@sdk/api';
import { AntCard, AntFlex, AntSpace, Button, Input } from '@ui/components';
import { fontSize } from '@ui/constants';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const CustomersListFilters = ({
  query,
  setQuery,
}: {
  query: CustomerApiGetCustomersRequest;
  setQuery: (q: CustomerApiGetCustomersRequest) => void;
}) => {
  const { t } = useTranslation();
  const { handleSearch, clearQueryParams } = useTable(query, setQuery);

  const { data: hatcheries } = useGetHatcheries({
    limit: 1000, // aligned with stakeholders
    offset: 0,
  });

  return (
    <AntCard bordered={false} style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }}>
      <AntSpace direction="vertical" className={styles.container}>
        <AntFlex justify="space-between" wrap="wrap">
          <Input type="search" className={styles.input} onChange={handleSearch()} />

          <AntSpace>
            <HatcherySelector items={hatcheries?.items} query={query} setQuery={setQuery} />
            <CountrySelector query={query} setQuery={setQuery} />
          </AntSpace>
        </AntFlex>

        {(query.hatcheryIds?.length || query.country) && (
          <AntSpace className={styles.filters} size={[0, 6]} wrap>
            <HatcheryFilters items={hatcheries?.items} query={query} setQuery={setQuery} />
            <CountryFilters query={query} setQuery={setQuery} />

            <Button
              size="small"
              onClick={() => clearQueryParams(['hatcheryIds', 'country'])}
              style={{ fontSize: fontSize.sm, height: 22 }}
            >
              {t('actions.clear_filters')}
            </Button>
          </AntSpace>
        )}
      </AntSpace>
    </AntCard>
  );
};
