import type { SensorApiGetSensorsRequest } from '@sdk/api';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

export const SensorTypeFilters = ({
  query,
  setQuery,
}: {
  query: SensorApiGetSensorsRequest;
  setQuery: (q: SensorApiGetSensorsRequest) => void;
}) => {
  console.log(query);
  const { t } = useTranslation();

  return (
    query.type && (
      <>
        <Tag
          key={query.type}
          bordered={false}
          closable
          onClose={() =>
            setQuery({
              ...query,
              type: undefined,
            })
          }
        >
          {t(query.type)}
        </Tag>
      </>
    )
  );
};
