import { PlusOutlined } from '@ant-design/icons';
import { HatcheryFilters, HatcherySelector } from '@app/components/Filters';
import { useTable } from '@app/utils/hooks';
import { useGetHatcheries } from '@data/hooks';
import type { SensorApiGetSensorSetsRequest } from '@sdk/api';
import { AntCard, AntFlex, AntGrid, AntSpace, Button, Input } from '@ui/components';
import { fontSize } from '@ui/constants';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.css';

export const SetsListFilters = ({
  query,
  setQuery,
  toggleIsOpen,
}: {
  query: SensorApiGetSensorSetsRequest;
  setQuery: (q: SensorApiGetSensorSetsRequest) => void;
  toggleIsOpen: () => void;
}) => {
  const { t } = useTranslation();
  const { handleSearch, clearQueryParams } = useTable(query, setQuery);

  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();

  const { data: hatcheries } = useGetHatcheries({ limit: 1000, offset: 0 });

  const NewSetButton = ({ ...props }) => (
    <Button type="primary" icon={<PlusOutlined />} onClick={toggleIsOpen} {...props}>
      {t('new_set')}
    </Button>
  );

  return (
    <AntCard bordered={false} style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }}>
      <AntSpace direction="vertical" className={styles.container}>
        <AntFlex justify="space-between" wrap="wrap">
          <Input type="search" className={styles.input} onChange={handleSearch()} />
          {xs && <NewSetButton style={{ width: '100%', marginBottom: 10 }} />}
          <AntSpace>
            <HatcherySelector items={hatcheries?.items} query={query} setQuery={setQuery} />
            {!xs && <NewSetButton />}
          </AntSpace>
        </AntFlex>

        {!!query.hatcheryIds?.length && (
          <AntSpace className={styles.filters} size={[0, 6]} wrap>
            <HatcheryFilters items={hatcheries?.items} query={query} setQuery={setQuery} />
            <Button
              size="small"
              onClick={() => clearQueryParams(['hatcheryIds'])}
              style={{ fontSize: fontSize.sm, height: 22 }}
            >
              {t('actions.clear_filters')}
            </Button>
          </AntSpace>
        )}
      </AntSpace>
    </AntCard>
  );
};
