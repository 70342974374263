import { Timeline } from '@app/components/Timeline';
import { usePermissions } from '@app/utils/hooks';
import { batchKeys } from '@data/constants';
import { useCreateBatchComment, useGetBatchEvents } from '@data/hooks';
import { type BatchDetailView, BatchViewStatusEnum } from '@sdk/api';
import { useQueryClient } from '@tanstack/react-query';
import { AntCard, AntFlex, AntSpace, Button, Input, Spinner } from '@ui/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const BatchActivity = ({ batch }: { batch: BatchDetailView }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [comment, setComment] = useState<string>();

  const { data: events } = useGetBatchEvents(batch.id);
  const { mutateAsync: addComment } = useCreateBatchComment();
  const { getIsCustomerUser } = usePermissions();

  const handleAddComment = async () => {
    if (!comment) return;
    await addComment({ id: batch.id, commentBody: { text: comment } });
    await queryClient.invalidateQueries({ queryKey: batchKeys.event(batch.id) });
    setComment(undefined);
  };

  const isClosed = batch.status === BatchViewStatusEnum.Closed;
  const isCustomerUser = getIsCustomerUser(batch.house?.customerLocation?.customer?.id);

  return (
    <AntSpace direction="vertical" size="large" style={{ width: '100%' }}>
      {!isClosed && !isCustomerUser && (
        <AntCard bordered={false}>
          <AntFlex justify="space-between">
            <Input
              placeholder={t('comment')}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleAddComment}
              style={{ marginLeft: 8, minWidth: 150 }}
              disabled={!comment}
            >
              {t('post')}
            </Button>
          </AntFlex>
        </AntCard>
      )}

      {!!events?.count && (
        <AntCard bordered={false}>
          <Timeline events={events} />
        </AntCard>
      )}
      <Spinner />
    </AntSpace>
  );
};
