import { CalendarOutlined } from '@ant-design/icons';
import type { BatchApiGetBatchesRequest } from '@sdk/api';
import { Button } from '@ui/components';
import { colors, fontSize } from '@ui/constants';
import { DatePicker, Flex } from 'antd';
import { useState } from 'react';

import styles from './styles.module.css';

export const DateSelector = ({
  query,
  setQuery,
  label,
}: {
  query: BatchApiGetBatchesRequest;
  setQuery: (q: BatchApiGetBatchesRequest) => void;
  label: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { RangePicker } = DatePicker;

  return (
    <Flex className={styles.dateSelectorContainer}>
      <Button
        type="text"
        iconRight={<CalendarOutlined style={{ color: colors.grey300 }} />}
        style={{
          color: query.start ? colors.yellowGreen700 : colors.grey700,
          fontSize: fontSize.md,
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {label}
      </Button>

      <RangePicker
        open={isOpen}
        showNow={false}
        allowClear={false}
        separator={null}
        suffixIcon={null}
        variant="borderless"
        className={styles.rangePicker}
        onOpenChange={(isOpen) => setIsOpen(isOpen)}
        onCalendarChange={([start, end]) => {
          setQuery({ ...query, start: start?.toISOString(), end: end?.toISOString() });
        }}
      />
    </Flex>
  );
};
