import { useUserStore } from '@data/storage';
import { MeasurementSystem } from '@sdk/api';
import { useSearchParams } from 'react-router-dom';

export const useMeasurementConversion = () => {
  const { user } = useUserStore();
  const [searchParams] = useSearchParams();

  const squareFootToMeterRatio = 10.763914692;

  const measurementSystem =
    (searchParams.get('meas') as MeasurementSystem) ?? user?.measurementSystem;

  const isImperial = measurementSystem === MeasurementSystem.Imperial;

  const squareMeterToFeet = (meter: number) => meter * squareFootToMeterRatio;
  const squareFeetToMeter = (feet: number) => feet / squareFootToMeterRatio;

  const toSquareMeters = (value: number | string) => {
    if (typeof value === 'string' && !value.length) return '';
    const converted = isImperial ? squareFeetToMeter(Number(value)) : value;
    return converted?.toString();
  };

  const squareMetersToUserUnits = (value: number | string) => {
    if (typeof value === 'string' && !value.length) return '';
    const converted = isImperial ? squareMeterToFeet(Number(value)) : Number(value);
    return converted?.toFixed(0);
  };

  const areaMeasurementUnit = isImperial ? 'ft²' : 'm²';

  return { areaMeasurementUnit, toSquareMeters, squareMetersToUserUnits };
};
