import type {
  BatchApiGetBatchesRequest,
  BatchApiGetBreedsRequest,
  CustomerApiGetCustomersRequest,
  HatcheryApiGetHatcheriesRequest,
  RoleApiGetRolesRequest,
  SensorApiGetGatewaysRequest,
  SensorApiGetSensorSetsRequest,
  SensorApiGetSensorsRequest,
} from '@sdk/api';

export const userKeys = {
  all: () => ['users'],
  kpi: () => ['user', 'kpi'],
};

export const hatcheryKeys = {
  all: () => ['hatcheries'],
  lists: () => [...hatcheryKeys.all(), 'list'],
  list: (params: HatcheryApiGetHatcheriesRequest) => [...hatcheryKeys.lists(), params],
  details: () => [...hatcheryKeys.all(), 'detail'],
  detail: (id: string) => [...hatcheryKeys.details(), id],
  subscriptions: () => [...hatcheryKeys.all(), 'subscriptions'],
  subscription: (id: string) => [...hatcheryKeys.subscriptions(), id],
};

export const roleKeys = {
  all: () => ['roles'],
  lists: () => [...roleKeys.all(), 'list'],
  list: (params: RoleApiGetRolesRequest) => [...roleKeys.lists(), params],
};

export const customerKeys = {
  all: () => ['customers'],
  lists: () => [...customerKeys.all(), 'list'],
  list: (params: CustomerApiGetCustomersRequest) => [...customerKeys.lists(), params],
  details: () => [...customerKeys.all(), 'detail'],
  detail: (id: string) => [...customerKeys.details(), id],
  subscriptions: () => [...customerKeys.all(), 'subscriptions'],
  subscription: (id: string) => [...customerKeys.subscriptions(), id],
};

export const batchKeys = {
  all: () => ['batches'],
  lists: () => [...batchKeys.all(), 'list'],
  list: (params: BatchApiGetBatchesRequest) => [...batchKeys.lists(), params],
  details: () => [...batchKeys.all(), 'detail'],
  detail: (id: string) => [...batchKeys.details(), id],
  breeds: () => [...batchKeys.all(), 'breeds'],
  breed: (params: BatchApiGetBreedsRequest) => [...batchKeys.breeds(), params],
  events: () => [...batchKeys.all(), 'events'],
  event: (id: string) => [...batchKeys.events(), id],
  latestEvents: () => [...batchKeys.events(), 'latest'],
  stats: (id: string) => [...batchKeys.detail(id), 'stats'],
};

export const sensorKeys = {
  all: () => ['sensors'],
  lists: () => [...sensorKeys.all(), 'list'],
  list: (params: SensorApiGetSensorsRequest) => [...sensorKeys.lists(), params],
  sets: () => [...sensorKeys.all(), 'set'],
  set: (params: SensorApiGetSensorSetsRequest) => [...sensorKeys.sets(), params],
  gatewaysAll: () => ['gateways'],
  gatewaysLists: () => [...sensorKeys.gatewaysAll(), 'list'],
  gatewaysList: (params: SensorApiGetGatewaysRequest) => [...sensorKeys.gatewaysLists(), params],
};
