import { MenuOutlined } from '@ant-design/icons';
import { useMobile } from '@app/utils/hooks';
import { AntConfigProvider, AntFloatButton, AntLayout } from '@ui/components';
import { theme } from '@ui/constants';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Sidebar } from './Sidebar';
import styles from './styles.module.css';

export const Layout = () => {
  const { isMobile } = useMobile();

  const [isSiderOpen, setIsSiderOpen] = useState(false);
  const toggleSider = () => setIsSiderOpen((isSiderOpen) => !isSiderOpen);

  return (
    <AntConfigProvider
      theme={{
        components: {
          Layout: theme.Layout,
        },
      }}
    >
      <AntLayout className={styles.layout}>
        <Sidebar isOpen={isSiderOpen} toggleIsOpen={toggleSider} />
        <Outlet />
        {isMobile && (
          <AntFloatButton
            icon={<MenuOutlined />}
            type="primary"
            onClick={toggleSider}
            className={styles.floatButton}
          />
        )}
      </AntLayout>
    </AntConfigProvider>
  );
};
