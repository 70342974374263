import { FileExcelOutlined } from '@ant-design/icons';
import {
  CustomerFilters,
  CustomerSelector,
  DateFilters,
  DateSelector,
  HatcheryFilters,
  HatcherySelector,
} from '@app/components/Filters';
import { saveBlob } from '@app/utils/helpers';
import { useTable } from '@app/utils/hooks';
import { useGetCustomers, useGetHatcheries } from '@data/hooks';
import type { BatchApiGetBatchesRequest } from '@sdk/api';
import { AntCard, AntFlex, AntSpace, AntSwitch, Button, Input } from '@ui/components';
import { colors, fontSize } from '@ui/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const BatchesListFilters = ({
  query,
  setQuery,
}: {
  query: BatchApiGetBatchesRequest;
  setQuery: (q: BatchApiGetBatchesRequest) => void;
}) => {
  const { t } = useTranslation();
  const { handleSearch, clearQueryParams } = useTable(query, setQuery);

  const filtersQuery = { limit: 1000, offset: 0 };
  const { data: hatcheries } = useGetHatcheries(filtersQuery);
  const { data: customers } = useGetCustomers(filtersQuery);

  const [isXlsxLoading, setXlsxLoading] = useState(false);

  const downloadExcel = async () => {
    setXlsxLoading(true);
    await saveBlob(`/batches/export`, `batches.xlsx`, query);
    setXlsxLoading(false);
  };

  return (
    <AntCard bordered={false} style={{ borderRadius: 8 }} styles={{ body: { padding: 16 } }}>
      <AntSpace direction="vertical" className={styles.container}>
        <AntFlex justify="space-between" wrap="wrap">
          <Input type="search" className={styles.input} onChange={handleSearch()} />

          <AntSpace wrap size={[6, 0]}>
            <HatcherySelector items={hatcheries?.items} query={query} setQuery={setQuery} />
            <CustomerSelector
              items={customers?.items?.filter((item) => item.lastActiveBatchAt)}
              query={query}
              setQuery={setQuery}
            />
            <DateSelector query={query} setQuery={setQuery} label={t('batch.batch_started_on')} />
            <AntSpace className={styles.switch}>
              {t('with_data')}
              <AntSwitch
                value={query.withData}
                onChange={() => setQuery({ ...query, withData: !query.withData })}
              />
            </AntSpace>
            <Button
              type="text"
              iconRight={<FileExcelOutlined style={{ color: colors.grey300 }} />}
              style={{ color: colors.grey700, fontSize: fontSize.md }}
              loading={isXlsxLoading}
              onClick={downloadExcel}
            >
              {t('actions.download')}
            </Button>
          </AntSpace>
        </AntFlex>

        {(query.hatcheryIds || query.customerIds || query.start) && (
          <AntSpace className={styles.filters} size={[0, 6]} wrap>
            <HatcheryFilters items={hatcheries?.items} query={query} setQuery={setQuery} />
            <CustomerFilters items={customers?.items} query={query} setQuery={setQuery} />
            <DateFilters query={query} setQuery={setQuery} />
            <Button
              size="small"
              onClick={() => clearQueryParams(['hatcheryIds', 'customerIds', 'start'])}
              style={{ fontSize: fontSize.sm, height: 22 }}
            >
              {t('actions.clear_filters')}
            </Button>
          </AntSpace>
        )}
      </AntSpace>
    </AntCard>
  );
};
