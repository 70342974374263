import type { AddBatchValidator } from '@data/utils/validation';
import { AntSpace, FormItem } from '@ui/components';
import { colors } from '@ui/constants';
import { Image } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { getFloorPlans } from '../../../utils';

const plans = getFloorPlans();

export const AddBatchStep3 = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddBatchValidator>();

  return (
    <FormItem required name="floorPlan" error={errors.floorPlan?.message}>
      <Controller
        control={control}
        name="floorPlan"
        render={({ field }) => (
          <AntSpace direction="vertical">
            {plans.map((plan) => (
              <Image
                key={plan.id}
                src={plan.src}
                preview={false}
                role="button"
                style={{
                  cursor: 'pointer',
                  border: '3px solid',
                  borderColor: field.value === plan.id ? colors.yellowGreen500 : 'transparent',
                  borderRadius: 8,
                }}
                onClick={() => field.onChange(plan.id)}
              />
            ))}
          </AntSpace>
        )}
      />
    </FormItem>
  );
};
