import { BatchIndicator } from '@app/constants';
import { formatNumber, getFormattedTemperature } from '@app/utils/helpers';
import { type BatchDetailView, TemperatureScale } from '@sdk/api';
import { AntSpace, AntTable, AntTypography } from '@ui/components';
import { fontWeight } from '@ui/constants';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { formatBatchValues, getBatchValues, getChicksDay1, getChicksDay7 } from '../utils';

type DataSourceType = {
  key: string;
  value?: string;
  percentage?: string;
};

export const BatchDetails = ({ batch }: { batch?: BatchDetailView }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { Text } = AntTypography;

  if (!batch) return null;

  const eggTempScale =
    (searchParams.get('eggtempscale') as TemperatureScale) ?? TemperatureScale.Fahrenheit;

  const eggTemp = batch.avgEggShellTemperature;

  const data = {
    [t('batch.number_of_eggs_placed')]: batch.numberPlaced,
    [t('batch.non_hatched_eggs')]: getBatchValues('numberNonHatched', batch),
    [t('batch.selection_day_1')]: getBatchValues('selectionDay1', batch),
    [t('batch.number_of_chicks_day_1')]: getChicksDay1(batch),
    [t('batch.selection_day_7')]: getBatchValues('selectionDay7', batch),
    [t('batch.mortality_day_7')]: getBatchValues('mortalityDay7', batch),
    [t('batch.number_of_chicks_day_7')]: getChicksDay7(batch),
    [t('indicators.avg_egg_shell_temp')]: eggTemp
      ? getFormattedTemperature(eggTemp, eggTempScale)
      : '',
    [t('indicators.relative_humidity')]: formatBatchValues(
      BatchIndicator.Humidity,
      batch.avgHumidity,
    ),
    [t('indicators.avg_co2_phase_x', { phase: 1 })]: formatBatchValues(
      BatchIndicator.CO2,
      batch.avgCo2Phase1,
    ),
    [t('indicators.avg_co2_phase_x', { phase: 2 })]: formatBatchValues(
      BatchIndicator.CO2,
      batch.avgCo2Phase2,
    ),
  };

  const dataSource: DataSourceType[] = Object.entries(data).map(([key, value]) => ({
    key,
    value: Array.isArray(value)
      ? formatNumber(value[0])
      : typeof value === 'number'
        ? formatNumber(value)
        : value,
    percentage: Array.isArray(value) ? `(${value[1].toFixed(2)}%)` : undefined,
  }));

  const columns: TableColumnsType<DataSourceType> = [
    { render: (_, record) => record.key, width: 250 },
    {
      render: (_, record) => (
        <AntSpace>
          <Text style={{ fontWeight: fontWeight.semibold }}>{record.value}</Text>
          <Text>{record.percentage}</Text>
        </AntSpace>
      ),
    },
  ];

  return (
    <AntTable
      size="small"
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.key}
      pagination={false}
      showHeader={false}
    />
  );
};
