import { routes } from '@app/constants';
import { DateFormat, formatDate, getFormattedTemperature } from '@app/utils/helpers';
import { getIcon, getStyles } from '@app/utils/helpers/timelineHelpers';
import { useUserStore } from '@data/storage';
import {
  type AlarmEntityView,
  AlarmPayloadViewStatusEnum,
  AlarmPayloadViewTypeEnum,
  type BatchEventView,
  BatchEventViewTypeEnum,
  type LatestBatchEventView,
  type ListBatchEventView,
  type ListLatestBatchEventView,
} from '@sdk/api';
import { AntCard, AntFlex, AntGrid, AntSpace, AntTypography } from '@ui/components';
import { colors, fontSize, fontWeight } from '@ui/constants';
import { Divider, Timeline as AntTimeline, type TimelineItemProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const timelineWidth = 100;
const timeLabelWithDotWidth = (timelineWidth / 4) * 3;
const timelineCardMobileWidth = `calc(100vw - 20px - 48px - ${timeLabelWithDotWidth}px )`;

const TimelineCard = ({
  user,
  type,
  text,
  alarmPayload,
}: BatchEventView | LatestBatchEventView) => {
  const { t } = useTranslation();
  const { Text } = AntTypography;
  const { useBreakpoint } = AntGrid;
  const { xs } = useBreakpoint();
  const { user: userStore } = useUserStore();

  const getAlarmEntityDescription = (entity: AlarmEntityView) => {
    const { name, value, createdAt, closedAt } = entity;
    const tempScale = userStore?.airTempScale;
    const alarm = alarmPayload!.type;

    const formattedValue = {
      [AlarmPayloadViewTypeEnum.AirTempTooHot]: getFormattedTemperature(Number(value), tempScale),
      [AlarmPayloadViewTypeEnum.AirTempTooCold]: getFormattedTemperature(Number(value), tempScale),
      [AlarmPayloadViewTypeEnum.Co2TooHigh]: `${value}ppm`,
      [AlarmPayloadViewTypeEnum.NoData]: value,
    }[alarm];

    const data = {
      name,
      value: formattedValue,
      start: formatDate(createdAt, DateFormat.Time),
      end: formatDate(closedAt, DateFormat.Time),
    };

    return closedAt
      ? t('alarms.closed_entity_alarm_details', { ...data })
      : t('alarms.open_entity_alarm_details', { ...data });
  };

  return (
    <AntCard
      style={{
        borderRadius: 8,
        width: xs ? timelineCardMobileWidth : '30vw',
        ...getStyles(type, alarmPayload),
      }}
      styles={{ body: { padding: 8 } }}
    >
      {type === BatchEventViewTypeEnum.Comment && (
        <AntSpace direction="vertical">
          <Text
            type="secondary"
            style={{
              fontSize: fontSize.sm,
              fontWeight: fontWeight.medium,
            }}
          >
            {user?.fullName}
          </Text>
          {text}
        </AntSpace>
      )}

      {type === BatchEventViewTypeEnum.Closure && (
        <AntSpace direction="vertical">
          <Text
            type="secondary"
            style={{
              fontSize: fontSize.sm,
              fontWeight: fontWeight.medium,
            }}
          >
            {t('system')}
          </Text>
          {t('toast.batch_closed')}
        </AntSpace>
      )}

      {alarmPayload && (
        <AntSpace direction="vertical" style={{ width: '100%' }}>
          <AntFlex align="center" justify="space-between">
            <Text
              type="secondary"
              style={{
                fontSize: fontSize.sm,
                fontWeight: fontWeight.medium,
              }}
            >
              {t('system')}
            </Text>

            <Text
              type="secondary"
              style={{
                fontSize: fontSize.sm,
                fontWeight: fontWeight.medium,
              }}
            >
              {alarmPayload.status === AlarmPayloadViewStatusEnum.Open && t('open')}
              {alarmPayload.status === AlarmPayloadViewStatusEnum.Closed &&
                t('resolved_at', {
                  time: formatDate(alarmPayload.closedAt, DateFormat.ShortDateTime),
                })}
            </Text>
          </AntFlex>

          {t(`alarms.${alarmPayload.type}`)}

          <AntSpace direction="vertical" size={0}>
            {alarmPayload.entities
              ?.sort((a, b) => Number(a.name) - Number(b.name))
              ?.map((entity) => (
                <Text key={entity.name} type="secondary">
                  {getAlarmEntityDescription(entity)}
                </Text>
              ))}
          </AntSpace>
        </AntSpace>
      )}
    </AntCard>
  );
};

const transformData = (event: BatchEventView | LatestBatchEventView): TimelineItemProps => {
  const { Text } = AntTypography;

  const children =
    'batch' in event ? (
      <Link
        to={`/${routes.app}/${routes.batches.root}/${event.batch?.id}`}
        style={{ cursor: 'pointer' }}
      >
        <AntSpace direction="vertical" size="small">
          <AntSpace direction="vertical" size={0}>
            <Text style={{ fontSize: fontSize.md, fontWeight: fontWeight.semibold }}>
              {event.batch?.hatchery?.name}
            </Text>
            <Text
              style={{
                color: colors.grey500,
                fontSize: fontSize.sm,
                fontWeight: fontWeight.medium,
              }}
            >
              {`${event.batch?.house?.customerLocation?.customer?.name}, ${event.batch?.house?.customerLocation?.name}, ${event.batch?.house?.name}`}
            </Text>
          </AntSpace>
          <TimelineCard {...event} />
        </AntSpace>
      </Link>
    ) : (
      <TimelineCard {...event} />
    );

  return {
    label: (
      <Text style={{ color: colors.grey500 }}>{formatDate(event.createdAt, DateFormat.Time)}</Text>
    ),
    children,
    dot: getIcon(event),
  };
};

export const Timeline = ({
  events,
}: {
  events?: ListBatchEventView | ListLatestBatchEventView;
}) => {
  const dailyTimelineData = events?.items
    ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    ?.reduce((result: { [key: string]: TimelineItemProps[] }, event) => {
      const date = formatDate(event.createdAt, DateFormat.FullDate);
      const datum = transformData(event);
      result[date] ? result[date].push(datum) : (result[date] = [datum]);
      return result;
    }, {});

  return (
    dailyTimelineData &&
    Object.entries(dailyTimelineData).map((timeline) => (
      <div key={timeline[0]}>
        <Divider
          orientation="left"
          orientationMargin={0}
          plain
          style={{ fontSize: fontSize.lg, color: colors.grey500 }}
        >
          {timeline[0]}
        </Divider>
        <AntTimeline mode="left" items={timeline[1]} style={{ width: timelineWidth }}></AntTimeline>
      </div>
    ))
  );
};
